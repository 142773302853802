import React, { useEffect, useRef } from 'react';
import Prism from 'prismjs';
import 'prismjs/components/prism-markup-templating';
import 'prismjs/components/prism-twig'; // Twig is similar to Nunjucks
import 'prismjs/themes/prism.css';

interface NunjucksSyntaxHighlighterProps {
  code: string;
  onChange: (value: string) => void;
  className?: string;
  rows?: number;
  placeholder?: string;
  disabled?: boolean;
}

const NunjucksSyntaxHighlighter: React.FC<NunjucksSyntaxHighlighterProps> = ({
  code,
  onChange,
  className = '',
  rows = 10,
  placeholder = 'Enter template code',
  disabled = false,
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const previewRef = useRef<HTMLPreElement>(null);

  useEffect(() => {
    // Highlight the code whenever it changes
    if (previewRef.current) {
      previewRef.current.innerHTML = Prism.highlight(
        code || '',
        Prism.languages.twig, // Use Twig grammar (very similar to Nunjucks)
        'twig'
      );
    }
  }, [code]);

  const handleScroll = (e: React.UIEvent<HTMLTextAreaElement>) => {
    if (previewRef.current && textareaRef.current) {
      // Sync scroll position
      previewRef.current.scrollTop = textareaRef.current.scrollTop;
      previewRef.current.scrollLeft = textareaRef.current.scrollLeft;
    }
  };

  // Calculate a rough height based on rows (1.5em per row + padding)
  const containerHeight = `${rows * 1.5 + 1}em`;

  return (
    <div
      className={`syntax-highlighter-container relative font-mono text-sm ${className}`}
      style={{ position: 'relative', height: containerHeight }}
    >
      <textarea
        ref={textareaRef}
        value={code}
        onChange={e => onChange(e.target.value)}
        onScroll={handleScroll}
        className="absolute inset-0 w-full h-full p-2.5 border rounded bg-transparent resize-y outline-none"
        style={{
          color: 'transparent',
          caretColor: 'black',
          whiteSpace: 'pre',
          overflowWrap: 'normal',
          overflow: 'auto',
          zIndex: 2,
        }}
        spellCheck="false"
        autoComplete="off"
        autoCapitalize="off"
        rows={rows}
        placeholder={placeholder}
        disabled={disabled}
      />
      <pre
        ref={previewRef}
        className="absolute inset-0 w-full h-full p-2.5 border rounded overflow-auto pointer-events-none"
        style={{
          margin: 0,
          whiteSpace: 'pre',
          overflowWrap: 'normal',
          background: 'transparent',
          zIndex: 1,
        }}
        aria-hidden="true"
      ></pre>
    </div>
  );
};

export default NunjucksSyntaxHighlighter;
