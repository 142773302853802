import { animate } from 'motion';
import { motion } from 'motion/react';
import { useEffect, useState, ReactNode } from 'react';

const delimiter = ' ';

export function useAnimatedText(text: string): ReactNode {
  const [cursor, setCursor] = useState(0);
  const [startingCursor, setStartingCursor] = useState(0);
  const [prevText, setPrevText] = useState(text);

  if (prevText !== text) {
    setPrevText(text);
    setStartingCursor(text.startsWith(prevText) ? cursor : 0);
  }

  useEffect(() => {
    const totalWords = text.split(delimiter).length;
    const controls = animate(startingCursor, totalWords, {
      duration: 1,
      ease: 'easeOut',
      onUpdate(latest) {
        setCursor(Math.floor(latest));
      },
    });
    return () => controls.stop();
  }, [startingCursor, text]);

  const visibleWords = text.split(delimiter).slice(0, cursor);

  return (
    <>
      {visibleWords.map((word, index) => (
        <motion.span
          key={index}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, ease: 'easeOut' }}
          style={{ marginRight: '0.1rem' }}
        >
          {word}
          {index < visibleWords.length - 1 && delimiter}
        </motion.span>
      ))}
    </>
  );
}
