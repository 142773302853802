// src/components/authorEdit/AuthorDashboard.tsx
import { FC, Suspense, lazy } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import styles from './AuthorDashboard.module.css';

const AuthorProfile = lazy(() => import('./AuthorProfile'));
const AuthorEvents = lazy(() => import('./AuthorEvents'));
const AuthorOfferings = lazy(() => import('./AuthorOfferings'));
const DocumentList = lazy(() => import('../common/documents/DocumentList'));
const BookUpload = lazy(() => import('./BookUpload'));
const AuthorQnA = lazy(() => import('./AuthorQnA'));
const ResponseGuidelines = lazy(() => import('./ResponseGuidelines'));
const VoiceManagement = lazy(() => import('./VoiceManagement'));
const BookDetails = lazy(() => import('./BookDetails'));
const AuthorStyle = lazy(() => import('./AuthorStyle'));
const Header = lazy(() => import('./../common/Header'));

const AuthorDashboard: FC = () => {
  const backButton = {
    label: 'Back to Author',
    onClick: () => {
      window.location.href = window.location.href.replace('/settings', '/chat');
    },
  };
  return (
    <div>
      <Header buttons={[backButton]} />

      <div className={styles.dashboard}>
        <nav className={styles.nav}>
          <NavLink to="." end className={({ isActive }) => (isActive ? styles.active : '')}>
            Profile
          </NavLink>
          <NavLink to="events" end className={({ isActive }) => (isActive ? styles.active : '')}>
            Events
          </NavLink>
          <NavLink to="offerings" end className={({ isActive }) => (isActive ? styles.active : '')}>
            Offerings
          </NavLink>
          <NavLink to="documents" className={({ isActive }) => (isActive ? styles.active : '')}>
            Documents
          </NavLink>
          <NavLink to="upload-book" className={({ isActive }) => (isActive ? styles.active : '')}>
            Upload Book
          </NavLink>
          <NavLink to="qna" className={({ isActive }) => (isActive ? styles.active : '')}>
            Q&A
          </NavLink>
          <NavLink to="guidelines" className={({ isActive }) => (isActive ? styles.active : '')}>
            Response Guidelines
          </NavLink>
          <NavLink
            to="voice-management"
            className={({ isActive }) => (isActive ? styles.active : '')}
          >
            Voice Management
          </NavLink>
          <NavLink to="book-details" className={({ isActive }) => (isActive ? styles.active : '')}>
            Books and Details
          </NavLink>
          <NavLink to="style" className={({ isActive }) => (isActive ? styles.active : '')}>
            Author Style
          </NavLink>
        </nav>
        <div className={styles.content}>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route index element={<AuthorProfile />} />
              <Route path="events" element={<AuthorEvents />} />
              <Route path="offerings" element={<AuthorOfferings />} />
              <Route path="documents" element={<DocumentList isAdmin={false} />} />
              <Route path="upload-book" element={<BookUpload />} />
              <Route path="book-details" element={<BookDetails />} />
              <Route path="qna" element={<AuthorQnA />} />
              <Route path="guidelines" element={<ResponseGuidelines />} />
              <Route path="voice-management" element={<VoiceManagement />} />
              <Route path="style" element={<AuthorStyle />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default AuthorDashboard;
