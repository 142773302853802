import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminService';
import { Book, Document } from '../../interfaces/interfaces';
import BookModal from './BookModal';
import socket from './../../socket';
import styles from './BookList.module.css';

interface ProgressData {
  bookId: string; // New: indicates which book this progress update belongs to
  documentId: string;
  progress: number; // Percentage (0-100)
  chunksProcessed: number;
  status: string; // "indexing", "ready", or "error"
}

const BookList: React.FC = () => {
  const [books, setBooks] = useState<Book[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedBook, setSelectedBook] = useState<Book | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [socketError, setSocketError] = useState<string | null>(null);
  const [documentsMap, setDocumentsMap] = useState<{ [id: string]: Document }>({});
  const [documentProgressMap, setDocumentProgressMap] = useState<{ [id: string]: string }>({});

  useEffect(() => {
    // Join the common room for book index updates
    socket.emit('joinRoom', 'bookIndexUpdate');

    // Listen for progress update events
    socket.on('documentProgress', (data: ProgressData) => {
      console.log('Received progress update:', data);
      setSocketError(null);
      // Update the document progress map:
      setDocumentProgressMap(prev => ({
        ...prev,
        [data.documentId]:
          data.status === 'indexing' ? `${data.status}, ${data.progress.toFixed(2)}%` : data.status,
      }));
      // If the book doesn't yet have a documentId, update it based on the progress update.
      setBooks(prevBooks =>
        prevBooks.map(book => {
          if (book.id === data.bookId && !book.documentId) {
            return { ...book, documentId: data.documentId };
          }
          return book;
        })
      );
    });

    socket.on('error', (error: any) => {
      console.error('Received socket error:', error);
      setSocketError(typeof error === 'string' ? error : 'An unknown error occurred.');
    });

    return () => {
      socket.off('documentProgress');
      socket.off('error');
    };
  }, []);

  useEffect(() => {
    const fetchBooks = async () => {
      const data = await adminService.getBooksWithAuthor(currentPage, pageSize);
      setBooks(data.books);
      setTotalCount(data.totalCount);
    };

    fetchBooks();
  }, [currentPage]);

  useEffect(() => {
    const fetchDocuments = async () => {
      const documentIds = books
        .map(book => book.documentId)
        .filter((id): id is string => Boolean(id));
      if (documentIds.length > 0) {
        try {
          const documents: Document[] = await adminService.getDocumentsByIds(documentIds);
          const map = documents.reduce((acc: { [id: string]: Document }, doc) => {
            acc[doc.id] = doc;
            return acc;
          }, {});
          setDocumentsMap(map);
        } catch (error) {
          console.error('Error fetching documents by IDs:', error);
        }
      }
    };

    fetchDocuments();
  }, [books]);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleDelete = async (bookId: string) => {
    if (window.confirm('Are you sure you want to delete this book?')) {
      await adminService.deleteBook(bookId);
      setBooks(prev => prev.filter(book => book.id !== bookId));
    }
  };

  const handleEdit = (book: Book) => {
    setSelectedBook(book);
    setIsModalOpen(true);
  };

  const handleAddBook = () => {
    setSelectedBook(null); // Clear selection for creating a new book
    setIsModalOpen(true);
  };

  const totalPages = Math.ceil(totalCount / pageSize);

  return (
    <div className={styles.bookListContainer}>
      <div className={styles.header}>
        <h2>Books</h2>
        <button className={styles.addButton} onClick={handleAddBook}>
          Add Book
        </button>
      </div>
      <table className={styles.bookTable}>
        <thead>
          <tr>
            <th>Cover</th>
            <th>Title</th>
            <th>Author</th>
            <th>Status</th>
            <th>Created at</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {books.map(book => (
            <tr key={book.id}>
              <td>
                {book.coverUrl ? (
                  <img
                    src={book.coverUrl}
                    alt={book.title}
                    className={styles.bookCover}
                    onClick={() => (book.bookUrl ? window.open(book.bookUrl, '_blank') : null)}
                  />
                ) : (
                  'No Cover'
                )}
              </td>
              <td>{book.title}</td>
              <td>{book.author?.name}</td>
              <td>
                {book.documentId
                  ? documentProgressMap[book.documentId] ||
                    (documentsMap[book.documentId]?.status ?? 'Indexing...')
                  : 'Legacy Indexing'}
              </td>
              <td>{new Date(book.createdAt).toLocaleString('en-GB', { hour12: false })}</td>
              <td>
                <button className={styles.editButton} onClick={() => handleEdit(book)}>
                  Edit
                </button>
                <button className={styles.deleteButton} onClick={() => handleDelete(book.id)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        <span>{`Page ${currentPage} of ${totalPages}`}</span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      {isModalOpen && (
        <BookModal
          book={selectedBook}
          onClose={() => setIsModalOpen(false)}
          onSave={(updatedBook: any) => {
            setBooks(prev =>
              selectedBook
                ? prev.map(book => (book.id === updatedBook.id ? updatedBook : book))
                : [...prev, updatedBook]
            );
            setIsModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default BookList;
