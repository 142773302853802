import React from 'react';
import { Prompt, PromptState } from '../../../interfaces/interfaces';
import {
  useGetPromptVersions,
  useRestorePrompt,
  useUpdatePromptState,
} from '../../../services/prompts/usePrompts';
import { RefreshCw } from 'lucide-react';
import { getStateColor, buttonStyles, confirmationMessages, errorMessages } from './promptUtils';
import PromptStateActions from './PromptStateActions';

interface PromptVersionHistoryProps {
  promptName: string;
  onClose: () => void;
  onVersionSelect?: (prompt: Prompt) => void;
  onRestore?: () => void;
  onStateChange?: () => void;
}

const PromptVersionHistory: React.FC<PromptVersionHistoryProps> = ({
  promptName,
  onClose,
  onVersionSelect,
  onRestore,
  onStateChange = onRestore, // Default to using onRestore if onStateChange not provided
}) => {
  const { data: versions = [], isLoading, error } = useGetPromptVersions(promptName);
  const restorePromptMutation = useRestorePrompt();
  const updatePromptStateMutation = useUpdatePromptState();

  const handleRestorePrompt = async (promptId: string) => {
    if (window.confirm(confirmationMessages.restore)) {
      try {
        await restorePromptMutation.mutateAsync({ id: promptId, promptName });

        if (onRestore) {
          setTimeout(() => {
            onRestore();
          }, 500);
        }
      } catch (error) {
        alert(errorMessages.restore);
      }
    }
  };

  const handleUpdatePromptState = async (promptId: string, state: PromptState) => {
    try {
      await updatePromptStateMutation.mutateAsync({ id: promptId, state });

      if (onStateChange) {
        setTimeout(() => {
          onStateChange();
        }, 500);
      }
    } catch (error) {
      alert(errorMessages.updateState);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-5 rounded-lg shadow-lg w-11/12 max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4 pb-2.5 border-b border-gray-200">
          <h2 className="text-xl font-semibold m-0">Version History for "{promptName}"</h2>
          <button
            className="bg-transparent border-none text-2xl cursor-pointer text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            &times;
          </button>
        </div>

        {isLoading ? (
          <div className="flex justify-center items-center py-10">
            <div className="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
          </div>
        ) : error ? (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4">
            Failed to load prompt versions
          </div>
        ) : (
          <div className="overflow-x-auto shadow-md rounded-lg">
            <table className="w-full border-collapse min-w-[600px]">
              <thead>
                <tr className="bg-gray-50">
                  <th className="py-3 px-4 text-left border-b border-gray-200 font-semibold">
                    Version
                  </th>
                  <th className="py-3 px-4 text-left border-b border-gray-200 font-semibold">
                    State
                  </th>
                  <th className="py-3 px-4 text-left border-b border-gray-200 font-semibold">
                    Last Updated
                  </th>
                  <th className="py-3 px-4 text-left border-b border-gray-200 font-semibold">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {versions.length > 0 ? (
                  versions.map((version: Prompt) => (
                    <tr key={version.id} className="hover:bg-gray-50">
                      <td className="py-3 px-4 border-b border-gray-200 font-medium">
                        {`${version.majorVersion}.${version.minorVersion}`}
                      </td>
                      <td className="py-3 px-4 border-b border-gray-200">
                        <span
                          className={`inline-block py-1 px-2 rounded text-xs font-medium ${getStateColor(
                            version.state
                          )}`}
                        >
                          {version.state}
                        </span>
                      </td>
                      <td className="py-3 px-4 border-b border-gray-200">
                        {new Date(version.updatedAt).toLocaleString()}
                      </td>
                      <td className="py-3 px-4 border-b border-gray-200">
                        <div className="flex gap-2">
                          {onVersionSelect && (
                            <button
                              onClick={() => onVersionSelect(version)}
                              className={`${buttonStyles.primary} ${buttonStyles.view}`}
                            >
                              View
                            </button>
                          )}
                          {version.state === PromptState.ARCHIVED && (
                            <button
                              onClick={() => handleRestorePrompt(version.id)}
                              className={`${buttonStyles.primary} ${buttonStyles.restore}`}
                            >
                              <RefreshCw size={12} /> Restore
                            </button>
                          )}
                          <PromptStateActions
                            prompt={version}
                            onUpdateState={handleUpdatePromptState}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="py-5 px-4 text-center text-gray-500 italic">
                      No versions found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="py-2 px-4 bg-gray-200 text-gray-800 rounded hover:bg-gray-300"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromptVersionHistory;
