// client/src/interfaces/interfaces.ts
export interface Book {
  id: string;
  title: string;
  author?: Author;
  authorId: string;
  coverUrl: string | null;
  description: string | null;
  documentId: string | null;
  fileType: string | null;
  indexName: string | null;
  bookUrl: string | null;
  createdAt: string;
  updatedAt: string;
}

export type DocumentType =
  | 'book'
  | 'article'
  | 'blog'
  | 'tweet'
  | 'interview'
  | 'lecture'
  | 'q&a'
  | 'other';
export type DocumentStatus = 'pending' | 'indexing' | 'ready' | 'error';

export interface Document {
  id: string;
  title: string;
  type: DocumentType;
  authorId: string;
  coverUrl: string | null;
  sourceUrl: string | null;
  description: string | null;
  status: DocumentStatus;
  createdAt: string;
  updatedAt: string;
  author?: Author;
}

export interface Practice {
  title: string;
  prompt: string;
  image: string;
}

export interface VoiceSample {
  id: string;
  authorId: string;
  fileUrl: string;
  status: 'pending' | 'ready';
  createdAt: string;
  updatedAt: string;
}

export interface BookQuestion {
  id: string;
  question: string;
}

export interface UserFact {
  id: string;
  userId: string;
  fact: string;
}

export interface Author {
  id: string;
  userId: string | null;
  user?: User; // can be null if it's wasn't fetched
  name: string;
  welcomeMessage: string | null;
  biography: string;
  photo: string;
  photoEcho: string;
  socialLinks?: {
    linkedIn?: string;
    twitter?: string;
    blog?: string;
  };
  title: string | null;
  location: string | null;
  numberOfReaders: number;
  books?: { id: string; name: string }[];
  offerings: {
    id: string;
    name: string;
    action: string | null;
  }[];
  events: {
    id: string;
    eventName: string;
    eventDate: string;
    place: string;
  }[];
  toneOfVoice: string | null;
  voiceId: string | null;
  voiceSettings: string | null;
  voiceStatus: string | null;
  voiceProvider: string | null;
  status: 'draft' | 'published';
  createdAt: string;
  updatedAt: string;
}

export interface Learning {
  id: string;
  learning: string;
  likes: number;
}

export interface Insight {
  id: string;
  insight: string;
  likes: number;
}

export interface Tool {
  id: string;
  name: string;
}

export interface User {
  id: string;
  name: string;
  lastName: string;
  userType: 'author' | 'admin' | 'regular';
  authorId?: string | null; // Make it nullable to match the backend
  email: string;
  jwtToken: string;
  createdAt: string; // Added createdAt
  updatedAt: string; // Added updatedAt
  [key: string]: any; // For any other properties that might exist on user
}

export enum PromptState {
  DRAFT = 'draft',
  RC = 'rc', // Release Candidate - replacing ACTIVE
  PUBLISHED = 'published',
  ARCHIVED = 'archived',
}

export interface Prompt {
  id: string;
  name: string;
  type: string;
  content: string;
  majorVersion: number;
  minorVersion: number;
  state: PromptState;
  createdAt: string;
  updatedAt: string;
  createdById: string | null;
  createdBy?: User;
  authorId?: string | null;
  author?: Author;
  metadata: any;
  description?: string | null;
}

export interface Message {
  id: string;
  userId: string;
  authorId: string;
  bookId: string;
  conversationId: string;
  content: string;
  role: 'user' | 'assistant';
  createdAt: Date;
  updatedAt: Date;
  deletedAt?: Date;
  isStreaming?: boolean;
}

export interface Conversation {
  id: string;
  title: string;
  userId: string;
  authorId: string;
  summary: string | null;
  summaryMessageCount: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date;
}
