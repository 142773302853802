import { Drawer, DrawerContent, DrawerHeader } from 'src/components/ui/drawer';
import AuthorProfileContent from 'src/components/authorChat/AuthorProfileContent';
import { Dispatch, FC, SetStateAction } from 'react';

interface Props {
  authorId: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const AuthorProfileDrawer: FC<Props> = ({ authorId, isOpen, setIsOpen }) => {
  return (
    <Drawer open={isOpen} onOpenChange={setIsOpen}>
      <DrawerContent className="bg-background-light data-[vaul-drawer-direction=bottom]:max-h-[calc(100vh-48px)] h-[calc(100vh-48px)] mt-0 py-4 px-5">
        <DrawerHeader className="overflow-auto px-0 py-4">
          <AuthorProfileContent authorId={authorId} />
        </DrawerHeader>
      </DrawerContent>
    </Drawer>
  );
};

export default AuthorProfileDrawer;
