import { useRef, useState } from 'react';
import { toast } from 'react-toastify';
import removeMd from 'remove-markdown';
import { useGetAudio } from 'src/services/chat/useGetAudio';

export const useVoiceMessage = (authorId: string) => {
  const [loadingAudio, setLoadingAudio] = useState<number | null>(null);
  const [playingAudio, setPlayingAudio] = useState<number | null>(null);
  const [audioProgress, setAudioProgress] = useState<{ [key: number]: number }>({});

  const audioCache = useRef<{ [key: number]: string }>({});
  const audioElements = useRef<{ [key: number]: HTMLAudioElement }>({});
  const intervals = useRef<{ [key: number]: number }>({});

  const { mutateAsync: getAudio } = useGetAudio(authorId);

  const handleVoiceClick = async (message: string, index: number) => {
    // If clicking the currently playing message, just pause it
    if (playingAudio === index && audioElements.current[index]) {
      audioElements.current[index].pause();
      if (intervals.current[index]) {
        clearInterval(intervals.current[index]);
        delete intervals.current[index];
      }
      setPlayingAudio(null);
      return;
    }

    // If another audio is playing, just pause it
    if (playingAudio !== null && audioElements.current[playingAudio]) {
      audioElements.current[playingAudio].pause();
      if (intervals.current[playingAudio]) {
        clearInterval(intervals.current[playingAudio]);
        delete intervals.current[playingAudio];
      }
      setPlayingAudio(null);
    }

    setLoadingAudio(index);

    try {
      // Check if we have a cached URL
      let audioUrl = audioCache.current[index];

      // If no cached URL, fetch new audio
      if (!audioUrl) {
        const strippedContent = removeMd(message, {
          stripListLeaders: false,
          useImgAltText: false,
        });
        audioUrl = await getAudio(strippedContent);
        audioCache.current[index] = audioUrl;
      }

      // Create new audio element if it doesn't exist
      if (!audioElements.current[index]) {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

        // Create new audio element
        const audio = new Audio();

        // Set attributes for iOS compatibility
        if (isIOS) {
          audio.preload = 'auto';
          audio.setAttribute('playsinline', 'true');
          audio.setAttribute('webkit-playsinline', 'true');
        }

        audio.addEventListener('loadedmetadata', () => {
          const progress = (audio.currentTime / audio.duration) * 100;
          setAudioProgress(prev => ({ ...prev, [index]: progress }));
        });

        audio.addEventListener('canplay', () => {
          const progress = (audio.currentTime / audio.duration) * 100;
          setAudioProgress(prev => ({ ...prev, [index]: progress }));
        });

        // Add event handlers
        audio.addEventListener('ended', () => {
          setPlayingAudio(null);
          setLoadingAudio(null);
          setAudioProgress(prev => ({ ...prev, [index]: 0 }));
          if (intervals.current[index]) {
            clearInterval(intervals.current[index]);
            delete intervals.current[index];
          }
        });

        audio.addEventListener('error', () => {
          setPlayingAudio(null);
          setLoadingAudio(null);
          setAudioProgress(prev => ({ ...prev, [index]: 0 }));
          delete audioCache.current[index];
          delete audioElements.current[index];
          if (intervals.current[index]) {
            clearInterval(intervals.current[index]);
            delete intervals.current[index];
          }
        });

        // Set source after event handlers are attached
        audio.src = audioUrl;
        audioElements.current[index] = audio;

        // Start loading the audio
        audio.load();
      }

      await audioElements.current[index].play();
      setPlayingAudio(index);
      setLoadingAudio(null);

      if (intervals.current[index]) {
        clearInterval(intervals.current[index]);
      }
      intervals.current[index] = window.setInterval(() => {
        const audio = audioElements.current[index];
        if (audio && audio.duration) {
          const progress = (audio.currentTime / audio.duration) * 100;
          setAudioProgress(prev => ({ ...prev, [index]: progress }));
        }
      }, 100);
    } catch (error) {
      console.error('Failed to play audio:', error);
      toast.error('Failed to play audio!');
      setPlayingAudio(null);
      setLoadingAudio(null);
      setAudioProgress(prev => ({ ...prev, [index]: 0 }));
    }
  };

  return { handleVoiceClick, loadingAudio, playingAudio, audioProgress };
};
