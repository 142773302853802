import React from 'react';
import styles from './AudioProgressBar.module.css';

interface AudioProgressBarProps {
  progress: number;
}

const AudioProgressBar: React.FC<AudioProgressBarProps> = ({ progress }) => {
  return (
    <div className={styles.progressBarContainer}>
      <div className={styles.progressBar} style={{ width: `${progress}%` }} />
    </div>
  );
};

export default AudioProgressBar;
