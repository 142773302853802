import * as React from 'react';
import { LogIn, PanelLeftClose, SquarePen } from 'lucide-react';
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuItem,
  SidebarTrigger,
  useSidebar,
} from 'src/components/ui/sidebar';
import wisdomLogo from 'src/assets/icons/wisdom-logo.svg';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'src/components/ui/button';
import { ChatList } from './ChatList';
import { Badge } from '../ui/badge';
import { cn } from 'src/lib/utils';

interface ChatSidebarProps extends React.ComponentProps<typeof Sidebar> {
  authorId: string;
}

export function ChatSidebar({ className, authorId, ...props }: ChatSidebarProps) {
  const navigate = useNavigate();
  const { setOpenMobile, isMobile } = useSidebar();

  const handleNewConversation = () => {
    navigate(`/author/${authorId}/chat/v2`);
    if (isMobile) {
      setOpenMobile(false);
    }
  };

  return (
    <>
      <Sidebar
        className={cn('bg-card-light pt-6 pb-4 px-3 md:pr-0', className)}
        variant="sidebar"
        {...props}
      >
        <SidebarHeader>
          <SidebarMenu>
            <SidebarMenuItem>
              <div className="flex justify-between items-center px-2">
                <div className="flex flex-row gap-2">
                  <Link to="/">
                    <img src={wisdomLogo} alt="Wisdom Logo" className="h-6" />
                  </Link>
                  <Badge className="p-1 h-5 bg-orange-accent-50 text-orange-accent-500 font-semibold rounded-md">
                    Pre-Launch
                  </Badge>
                </div>
                <div className="flex items-center">
                  <Button variant="secondary" size="icon" onClick={handleNewConversation}>
                    <SquarePen className="size-6 stroke-[1.5]" />
                  </Button>
                  <SidebarTrigger className="hidden md:block size-10 pl-2">
                    <PanelLeftClose className="size-6 stroke-[1.5]" />
                  </SidebarTrigger>
                </div>
              </div>
            </SidebarMenuItem>
          </SidebarMenu>
        </SidebarHeader>
        <SidebarContent>
          <ChatList authorId={authorId} />
        </SidebarContent>
        <SidebarFooter className="p-0">
          <div className="flex flex-col gap-4 md:px-0">
            <Button
              onClick={handleNewConversation}
              className="flex bg-sidebar-background-light hover:bg-border-light border-input-light"
              variant="outline"
            >
              <SquarePen /> New Chat
            </Button>
            <div className="border-t border-t-border h-1 w-full" />

            <Button
              className="flex bg-foreground-light hover:bg-card-foreground-light"
              variant="default"
            >
              <LogIn /> Sign in
            </Button>
          </div>
        </SidebarFooter>
      </Sidebar>
    </>
  );
}
