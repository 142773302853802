// server/src/services/authTokenService.ts
class AuthTokenService {
  getToken(): string {
    const token = localStorage.getItem('token');
    if (!token) return '';
    return token;
  }
}

export default new AuthTokenService();
