import { FC, useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import { ChatInput } from 'src/components/authorChat/ChatInput';
import { ChatMessagesList } from 'src/components/authorChat/ChatMessagesList';

import { useGetAuthorProfile } from 'src/services/author/useGetAuthorProfile';
import { useGetAuthorDocuments } from 'src/services/author/useGetAuthorDocuments';
import {
  useGetConversationHistory,
  getConversationHistoryQueryKey,
} from 'src/services/chat/useGetConversationHistory';

import { Message } from 'src/interfaces/interfaces';
import chatService from 'src/services/chat/chatService';

export const Chat: FC = () => {
  const { authorId, conversationId } = useParams();
  if (!authorId || !conversationId) throw new Error('Author or conversation ID is missing.');

  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [input, setInput] = useState('');
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [streamMessage, setStreamMessage] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);

  const { data: author, isLoading: isAuthorProfileLoading } = useGetAuthorProfile(authorId);
  const { data: documents, isLoading: areDocumentsLoading } = useGetAuthorDocuments(authorId);
  const { data: messages, isLoading: isHistoryLoading } = useGetConversationHistory(conversationId);

  const isLoading = isAuthorProfileLoading || areDocumentsLoading || isHistoryLoading;
  const isOverGraph = (documents?.length || 0) > 1;

  const conversationKey = useMemo(
    () => getConversationHistoryQueryKey(conversationId),
    [conversationId]
  );

  const addOrAppendAssistantMessage = useCallback(
    (text: string, isFinalChunk = false) => {
      queryClient.setQueryData(conversationKey, (old: Message[] = []) => {
        const last = old[old.length - 1];

        if (last?.isStreaming) {
          return [
            ...old.slice(0, -1),
            {
              ...last,
              content: last.content + text,
              isStreaming: !isFinalChunk,
              updatedAt: new Date(),
            },
          ];
        }

        return [
          ...old,
          {
            id: new Date().toISOString(),
            content: text,
            role: 'assistant',
            isStreaming: !isFinalChunk,
            createdAt: new Date(),
            updatedAt: new Date(),
          },
        ];
      });
    },
    [conversationKey, queryClient]
  );

  const sendMessage = useCallback(
    async (textToSend: string) => {
      await queryClient.cancelQueries({ queryKey: conversationKey });

      queryClient.setQueryData(conversationKey, (old: Message[] = []) => [
        ...old,
        {
          id: new Date().toISOString(),
          content: textToSend,
          role: 'user',
          createdAt: new Date(),
          updatedAt: new Date(),
        },
      ]);

      setStreamMessage('');
      setIsStreaming(true);

      try {
        await chatService.sendMessageStream(
          conversationId,
          textToSend,
          isOverGraph,
          (chunk, isFinal) => {
            setStreamMessage(prev => prev + chunk);
            addOrAppendAssistantMessage(chunk, isFinal);
            if (isFinal) {
              setIsStreaming(false);
            }
          }
        );
      } catch (err) {
        toast.error('Failed to send message!');
        setIsStreaming(false);
      }
    },
    [addOrAppendAssistantMessage, conversationKey, conversationId, isOverGraph, queryClient]
  );

  const handleSend = useCallback(
    async (messageOverride?: string) => {
      const message = messageOverride ?? input.trim();
      if (!message) return;

      if (!messageOverride) setInput('');
      setIsFirstLoad(false);

      await sendMessage(message);
    },
    [input, sendMessage]
  );

  useEffect(() => {
    // TODO: remove this logic once the conversation functionality is implemented
    const pendingMessage = location.state?.pendingMessage as string | undefined;
    if (!pendingMessage || isLoading) return;

    navigate(location.pathname, { replace: true, state: {} });
    handleSend(pendingMessage);
  }, [location.state, location.pathname, isLoading, navigate, handleSend]);

  const showSkeleton = isStreaming && !streamMessage;

  return (
    <div className="relative flex flex-col h-full pt-3">
      {!isLoading && author && (
        <>
          <div className="pointer-events-none absolute top-3 left-0 right-0 h-4 bg-gradient-to-b from-background-light to-transparent z-10" />
          <ChatMessagesList
            messages={messages ?? []}
            authorName={author.name}
            authorPhotoEcho={author.photoEcho}
            isLoading={showSkeleton}
            authorId={authorId}
            isFirstLoad={isFirstLoad}
          />
          <ChatInput
            authorName={author.name.split(' ')[0]}
            message={input}
            setMessage={setInput}
            onSend={() => handleSend()}
            isLoading={isStreaming}
          />
        </>
      )}
    </div>
  );
};

export default Chat;
