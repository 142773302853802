// client/src/context/AuthorContext.tsx
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import authorService from '../services/author/authorService';
import { Author, Book, Document } from '../interfaces/interfaces';
import { shallowEqual } from '../utils/comparison';

interface AuthorContextProps {
  author: Author | null;
  books: Book[] | null;
  documents: Document[] | null;
  loading: boolean;
  error: string | null;
}

const AuthorContext = createContext<AuthorContextProps | undefined>(undefined);

export const AuthorProvider: React.FC<{ authorId: string; children: React.ReactNode }> = ({
  authorId,
  children,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // Define fetchData outside of useMemo
  const fetchData = async (authorId: string) => {
    try {
      setLoading(true);
      const [authorProfile, authorsBooks, authorsDocuments] = await Promise.all([
        authorService.getAuthorProfile(authorId),
        authorService.getBooks(authorId),
        authorService.getDocuments(authorId),
      ]);

      return { author: authorProfile, books: authorsBooks, documents: authorsDocuments.documents };
    } catch (err) {
      console.error('Error fetching author profile and books:', err);
      setError('Failed to load author data and books.');
      return { author: null, books: null };
    } finally {
      setLoading(false);
    }
  };

  // Use useMemo to cache author and books data
  const {
    author,
    books,
    documents,
    fetchData: memoizedFetchData,
  } = useMemo(() => {
    let cachedAuthor: Author | null = null;
    let cachedBooks: Book[] | null = null;
    let cachedDocuments: Document[] | null = null;

    return {
      get author() {
        return cachedAuthor;
      },
      get books() {
        return cachedBooks;
      },
      get documents() {
        return cachedDocuments;
      },
      fetchData: async (authorId: string) => {
        const { author: newAuthor, books: newBooks, documents } = await fetchData(authorId);

        // Only update if data has changed
        if (!shallowEqual(cachedAuthor, newAuthor)) {
          cachedAuthor = newAuthor;
        }
        if (!shallowEqual(cachedBooks, newBooks)) {
          cachedBooks = newBooks;
        }

        if (!shallowEqual(cachedDocuments, documents)) {
          cachedDocuments = documents ?? null;
        }

        return { author: cachedAuthor, books: cachedBooks, documents };
      },
    };
  }, []);

  useEffect(() => {
    memoizedFetchData(authorId);
  }, [authorId, memoizedFetchData]);

  return (
    <AuthorContext.Provider value={{ author, books, documents, loading, error }}>
      {children}
    </AuthorContext.Provider>
  );
};

export const useAuthor = (): AuthorContextProps => {
  const context = useContext(AuthorContext);
  if (!context) {
    throw new Error('useAuthor must be used within an AuthorProvider');
  }
  return context;
};
