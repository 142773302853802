import { Dialog, DialogContent, DialogHeader, DialogTitle } from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import React, { Dispatch, FC } from 'react';
import { useDeleteConversation } from 'src/services/chat/useDeleteConversation';
import { useNavigate, useParams } from 'react-router-dom';

interface Props {
  authorId: string;
  conversationId: string;
  isOpen: boolean;
  setIsOpen: Dispatch<React.SetStateAction<boolean>>;
}

const DeleteConversationDialog: FC<Props> = ({ isOpen, authorId, conversationId, setIsOpen }) => {
  const { conversationId: currentConversationId } = useParams();
  const navigate = useNavigate();

  const { mutate: deleteConversation, isPending } = useDeleteConversation(authorId, {
    onSuccess: () => {
      if (currentConversationId === conversationId) {
        navigate(`/author/${authorId}/chat/v2`);
      }
      setIsOpen(false);
    },
  });

  const onConfirmClick = () => {
    deleteConversation(conversationId);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="max-w-[500px] bg-background-light">
        <DialogHeader className="mt-6">
          <DialogTitle className="text-center font-normal">
            Are you sure you want to delete this conversation?
          </DialogTitle>
          <div className="mt-4 flex gap-4 justify-center">
            <Button
              onClick={() => setIsOpen(false)}
              className="font-[600] md:inline-flex"
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              disabled={isPending}
              onClick={onConfirmClick}
              className="font-[600] md:inline-flex"
            >
              Confirm
            </Button>
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConversationDialog;
