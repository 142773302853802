import React from 'react';

interface NunjucksHelpModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const NunjucksHelpModal: React.FC<NunjucksHelpModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-5 rounded-lg shadow-lg w-11/12 max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4 pb-2.5 border-b border-gray-200">
          <h2 className="text-xl font-semibold m-0">Nunjucks Template Syntax</h2>
          <button
            className="bg-transparent border-none text-2xl cursor-pointer text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            &times;
          </button>
        </div>

        <div className="flex flex-col gap-6">
          <section>
            <h3 className="text-lg font-medium mb-2">Variables</h3>
            <div className="bg-gray-50 p-3 rounded font-mono text-sm mb-2">
              Hello, {'{{ name }}'} is {'{{ age }}'} years old.
            </div>
            <p className="text-sm text-gray-700">
              Variables are surrounded by double curly braces {'{{'} and {'}}'}. You can access
              object properties using dot notation (e.g., {'{{ user.name }}'}).
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium mb-2">Conditionals</h3>
            <div className="bg-gray-50 p-3 rounded font-mono text-sm mb-2">
              {'{% if age >= 18 %}'}
              <br />
              You are an adult.
              <br />
              {'{% else %}'}
              <br />
              You are a minor.
              <br />
              {'{% endif %}'}
            </div>
            <p className="text-sm text-gray-700">
              Use {'{% '} and {' %}'} for logic blocks like if-else statements. You can use
              comparisons (==, !=, &lt;, &gt;, &lt;=, &gt;=) and logical operators (and, or, not).
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium mb-2">Loops</h3>
            <div className="bg-gray-50 p-3 rounded font-mono text-sm mb-2">
              {'{% for book in books %}'}
              <br />- {'{{ book.title }}'} by {'{{ book.author }}'}
              <br />
              {'{% endfor %}'}
            </div>
            <p className="text-sm text-gray-700">
              Loop through arrays with for loops. Inside the loop, you can access a special 'loop'
              variable with properties like loop.index, loop.first, and loop.last.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium mb-2">Filters</h3>
            <div className="bg-gray-50 p-3 rounded font-mono text-sm mb-2">
              {'{{ name | upper }}'} <br />
              {'{{ items | join(", ") }}'} <br />
              {'{{ text | truncate(100) }}'}
            </div>
            <p className="text-sm text-gray-700">
              Filters transform variable values. Common filters include: upper, lower, capitalize,
              trim, replace, truncate, join, and many more.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium mb-2">Comments</h3>
            <div className="bg-gray-50 p-3 rounded font-mono text-sm mb-2">
              {'{# This is a comment that will not be rendered in the output #}'}
            </div>
            <p className="text-sm text-gray-700">
              Add comments that won't appear in the output using {'{#'} and {'#}'}.
            </p>
          </section>

          <section>
            <h3 className="text-lg font-medium mb-2">Examples</h3>

            <h4 className="font-medium mt-3 mb-1">Conditional book recommendation:</h4>
            <div className="bg-gray-50 p-3 rounded font-mono text-sm mb-2">
              {'{% if user.preferences.genre == "mystery" %}'}
              <br />
              I recommend "The Hound of the Baskervilles" by Arthur Conan Doyle.
              <br />
              {'{% elif user.preferences.genre == "science fiction" %}'}
              <br />
              I recommend "Dune" by Frank Herbert.
              <br />
              {'{% else %}'}
              <br />
              Based on your preferences, I recommend exploring our bestseller list.
              <br />
              {'{% endif %}'}
            </div>

            <h4 className="font-medium mt-3 mb-1">Listing book recommendations:</h4>
            <div className="bg-gray-50 p-3 rounded font-mono text-sm mb-2">
              Here are some books you might enjoy:
              <br />
              <br />
              {'{% for book in recommendations %}'}
              <br />
              {'{{ loop.index }}'}. {'{{ book.title }}'} by {'{{ book.author }}'} -{' '}
              {'{{ book.description | truncate(100) }}'}
              <br />
              {'{% endfor %}'}
            </div>
          </section>

          <div className="mt-4 text-sm text-gray-500">
            For complete documentation, visit the{' '}
            <a
              href="https://mozilla.github.io/nunjucks/templating.html"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline"
            >
              Nunjucks templating guide
            </a>
            .
          </div>
        </div>

        <div className="flex justify-end mt-6">
          <button
            type="button"
            onClick={onClose}
            className="py-2.5 px-4 border-none rounded bg-blue-500 text-white cursor-pointer hover:bg-blue-600"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default NunjucksHelpModal;
