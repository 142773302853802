import { FC, useState, useRef, useEffect } from 'react';
import { ArrowRight, Square } from 'lucide-react';

import { Button } from '../ui/button';
import { Textarea } from '../ui/textarea';
import { cn } from 'src/lib/utils';

interface ChatInputProps {
  authorName: string;
  message: string;
  setMessage: (message: string) => void;
  onSend: (message: string) => void;
  disabled?: boolean;
  isLoading?: boolean;
}

export const ChatInput: FC<ChatInputProps> = ({
  authorName,
  message,
  setMessage,
  onSend,
  disabled = false,
  isLoading = false,
}) => {
  const [isMultiline, setIsMultiline] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = '5px';
      textareaRef.current.style.height = textareaRef.current.scrollHeight + 'px';
      setIsMultiline(textareaRef.current.scrollHeight > 40);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendClick();
    }
  };

  const handleSendClick = () => {
    if (message.trim().length > 0) {
      onSend(message);
      setMessage('');
    }
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [message]);

  return (
    <div className="px-5 md:px-3">
      <div
        className={cn(
          'max-w-[750px] mx-auto w-full flex items-end bg-background-light from-white/70 to-white/70 bg-gradient-to-r border border-border-light px-2.5 py-2',
          isMultiline ? 'rounded-[20px]' : 'rounded-full'
        )}
      >
        <div className="w-full pb-1.5">
          <Textarea
            ref={textareaRef}
            className="resize-none text-md border-none focus-visible:ring-0 py-0 max-h-48 w-full bg-transparent"
            placeholder={`Message ${authorName}'s Echo`}
            value={message}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            rows={1}
          />
        </div>

        <Button
          variant={!disabled && (message.length > 0 || isLoading) ? 'default' : 'secondary'}
          size="icon"
          onClick={isLoading ? () => {} : handleSendClick}
          className={cn(
            'size-9 transition-all duration-300 disabled:opacity-1',
            !disabled && message.length > 0 ? 'bg-foreground-light' : 'text-muted-foreground-light'
          )}
        >
          {isLoading ? <Square className="text-primary-foreground-light" /> : <ArrowRight />}
        </Button>
      </div>
    </div>
  );
};
