export const toPossessive = (name: string) => {
  if (!name) return '';

  // Check if the name already ends with 's'
  if (name.endsWith('s')) {
    return `${name}'`;
  } else {
    return `${name}'s`;
  }
};
