import axios from 'axios';
import { UserFact } from '../interfaces/interfaces';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('REACT_APP_API_URL is not defined in .env file');
}

const getToken = () => {
  const token = localStorage.getItem('token');
  if (!token) throw new Error('No token found');
  return token;
};

const config = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

const getFacts = async (): Promise<UserFact[]> => {
  const response = await axios.get(`${API_URL}/users/facts`, config());
  return response.data;
};

const saveFact = async (fact: string): Promise<UserFact> => {
  const response = await axios.post(`${API_URL}/users/facts`, { fact }, config());
  return response.data;
};
const deleteFact = async (factId: string): Promise<{ deleted: number }> => {
  const response = await axios.delete(`${API_URL}/users/facts/${factId}`, config());
  return response.data;
};

const userFactsService = {
  getFacts,
  saveFact,
  deleteFact,
};

export default userFactsService;
