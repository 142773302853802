// client/src/components/auth/LoginPage.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import { useAuth } from '../../context/AuthContext';
import styles from './LoginPage.module.css';
import { useLogEvent, useSetUserProperties } from '../../utils/analytics';
import { EventAction, EventLabel } from '../../constants/analytics';

const LoginPage: React.FC = () => {
  const logEvent = useLogEvent();
  const setUserProperties = useSetUserProperties();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const user = await authService.login(email, password);
      setUser(user);
      setUserProperties(user.id, { email, name: user.name, lastName: user.lastName });
      logEvent(EventAction.LOGIN_CLICK, EventLabel.SUCCESS, { email });
      navigate('/');
    } catch (err: any) {
      logEvent(EventAction.LOGIN_CLICK, EventLabel.FAIL, { email });
      setError(err.message);
    }
  };

  const navigateToRegister = () => {
    navigate('/register');
  };

  return (
    <div className={styles.authPage} tabIndex={-1}>
      <div className={styles.logoContainer}>
        <img
          src={require('../../assets/icons/wisdom-logo.svg').default}
          alt="Wisdom Logo"
          className={styles.logo}
        />
      </div>
      <form onSubmit={handleSubmit} className={styles.authForm}>
        <h2 className={styles.title}>Welcome Back</h2>
        <p className={styles.subtitle}>Log in to continue your journey</p>
        <label className={styles.label}>
          Email
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            className={styles.input}
          />
        </label>
        <label className={styles.label}>
          Password
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
            className={styles.input}
          />
        </label>
        <button type="submit" className={styles.button}>
          Log In
        </button>
        <button type="button" className={styles.secondaryButton} onClick={navigateToRegister}>
          Don't have an account? Register
        </button>
        {error && <p className={styles.errorMessage}>{error}</p>}
      </form>
    </div>
  );
};

export default LoginPage;
