import * as React from 'react';
import { cn } from 'src/lib/utils';
import { Label } from './label';
import { Typography } from './typography';

interface InputProps extends React.ComponentProps<'input'> {
  label?: string;
  description?: string;
  horizontal?: boolean;
}

function Input({ label, description, horizontal, className, type, ...props }: InputProps) {
  return (
    <div className={cn(`flex gap-[6px] w-full`, horizontal ? 'flex-row' : 'flex-col')}>
      {label && <Label className={horizontal ? 'h-fit pt-3' : ''}>{label}</Label>}
      <div className="flex flex-col gap-1.5">
        <input
          type={type}
          data-slot="input"
          className={cn(
            'flex h-10 w-full min-w-0 rounded-md border bg-background-light px-3 py-2 border-border-light file:text-foreground-light placeholder:text-muted-foreground-light selection:bg-primary selection:text-primary-foreground text-sm text-foreground-light leading-normal transition-[color,box-shadow] outline-none file:inline-flex file:border-0 file:bg-transparent file:font-medium disabled:pointer-events-none disabled:cursor-not-allowed disabled:opacity-50 md:text-sm',
            'focus-visible:ring-ring-light focus-visible:ring-[2px]',
            className
          )}
          {...props}
        />
        {description && (
          <Typography variant="muted" size="sm">
            {description}
          </Typography>
        )}
      </div>
    </div>
  );
}

export { Input };
