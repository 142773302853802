// client/src/components/authorPage/ChatComponent.tsx

import React from 'react';
import { useChat } from 'src/context/ChatContext';
import styles from './ChatComponent.module.css';
import { ReactComponent as VoiceIcon } from '../../assets/icons/voice.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/pause.svg';
import { ReactComponent as ThreeDotsIcon } from '../../assets/icons/three-dots.svg';
import Markdown from 'markdown-to-jsx';
import { useAuthor } from 'src/context/AuthorContext';
import AudioProgressBar from './AudioProgressBar';

const ChatComponent: React.FC = () => {
  const {
    messages,
    handleVoiceClick,
    loadingAudio,
    playingAudio,
    audioProgress,
    loading: onResponseLoading,
  } = useChat();
  const { author, loading, error } = useAuthor();

  if (loading) {
    return <div>Loading author data...</div>;
  }

  if (error || !author) {
    return <div>Failed to load author details.</div>;
  }

  return (
    <div className={styles.chatContainer}>
      <div className={styles.messageList}>
        {[...messages].map((message, index) => (
          <div
            key={index}
            className={message.role === 'user' ? styles.userMessage : styles.assistantMessage}
          >
            {message.role === 'user' ? (
              <>
                <div className={styles.userMessageBubble}>{message.content}</div>
                <img
                  src={require('../../assets/pictures/user-avatar.png')}
                  alt="User Avatar"
                  className={styles.userAvatar}
                />
              </>
            ) : (
              <>
                <div className={styles.assistantMessageAndPhoto}>
                  <img
                    src={author.photo}
                    alt="Assistant Avatar"
                    className={styles.assistantAvatar}
                  />
                  <div className={styles.assistantMessageBubble}>
                    <Markdown>{message.content}</Markdown>
                  </div>
                </div>
                {!message.isLoading && (
                  <div className={styles.assistantActions}>
                    {loadingAudio === index ? (
                      <div className={styles.voiceLoader}></div>
                    ) : (
                      <>
                        <span
                          className={styles.voiceIcon}
                          onClick={() => handleVoiceClick(message, index)}
                        >
                          {playingAudio === index ? (
                            <PauseIcon className={styles.pauseIcon} />
                          ) : (
                            <VoiceIcon className={styles.voiceIcon} />
                          )}
                        </span>
                      </>
                    )}
                    <AudioProgressBar progress={audioProgress[index] || 0} />
                    <span className={styles.dotsIcon}>
                      <ThreeDotsIcon />
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ChatComponent;
