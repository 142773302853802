import { FC, useEffect, useState } from 'react';
import { Document, DocumentStatus } from '../../../interfaces/interfaces';
import styles from './DocumentList.module.css';
import DocumentModal from './DocumentModal';
import socket from '../../../socket';
import adminService from '../../../services/adminService';
import authorService from '../../../services/author/authorService';

import { useParams } from 'react-router-dom';

interface DocumentListProps {
  isAdmin: boolean;
}

const DocumentList: FC<DocumentListProps> = ({ isAdmin }) => {
  const { authorId } = useParams<{ authorId: string }>();
  if (!isAdmin && !authorId) {
    throw new Error('Author ID is missing.');
  }

  const [documents, setDocuments] = useState<Document[]>([]);
  const [progressMap, setProgressMap] = useState<{ [key: string]: number }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(20);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState<Document | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadDocuments = async () => {
      setIsLoading(true);
      try {
        const data = isAdmin
          ? await adminService.getDocuments(currentPage, pageSize)
          : await authorService.getDocuments(authorId || '', currentPage, pageSize);
        setDocuments(data.documents);
        setTotalCount(data.totalCount);
        setError(null);
      } catch (err) {
        console.error('Failed to fetch documents:', err);
        setError('Failed to load documents. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    loadDocuments();
  }, [currentPage, pageSize, isAdmin, authorId]);

  useEffect(() => {
    const handleProgressUpdate = (update: { id: string; progress: number; status: string }) => {
      console.log('Received progress update:', update);
      setProgressMap(prev => {
        if (prev[update.id] !== update.progress) {
          return { ...prev, [update.id]: update.progress };
        }
        return prev;
      });

      setDocuments(prev => {
        return prev.map(doc => {
          if (doc.id === update.id) {
            if (doc.status !== update.status) {
              console.log(
                `Updating status for document ID ${doc.id} from ${doc.status} to ${update.status}`
              );
              return { ...doc, status: update.status as DocumentStatus };
            } else {
              console.log(`No status change for document ID ${doc.id}`);
            }
          } else {
            console.log(`No update for document ID ${doc.id}`);
          }
          return doc;
        });
      });
    };

    socket.on('documentProgress', handleProgressUpdate);

    return () => {
      socket.off('documentProgress', handleProgressUpdate);
    };
  }, []);

  useEffect(() => {
    // Join room when the component mounts
    socket.emit('joinRoom', 'documentIndexUpdate');

    return () => {
      // Leave room when the component unmounts (cleanup)
      socket.emit('leaveRoom', 'documentIndexUpdate');
    };
  }, []);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const handleDelete = async (documentId: string) => {
    if (window.confirm('Are you sure you want to delete this document?')) {
      try {
        if (isAdmin) {
          await adminService.deleteDocument(documentId);
        } else {
          await authorService.deleteDocument(authorId || '', documentId);
        }
        setDocuments(prev => prev.filter(doc => doc.id !== documentId));
        if (documents.length === 1 && currentPage > 1) {
          setCurrentPage(currentPage - 1);
        }
      } catch (err) {
        console.error('Failed to delete document:', err);
        setError('Failed to delete document. Please try again.');
      }
    }
  };

  const handleEdit = (document: Document) => {
    setSelectedDocument(document);
    setIsModalOpen(true);
  };

  const handleAddDocument = () => {
    setSelectedDocument(null); // Clear selection for creating a new document
    setIsModalOpen(true);
  };

  const totalPages = Math.ceil(totalCount / pageSize);

  const getDocumentTypeLabel = (type: string) => {
    const typeMap: { [key: string]: string } = {
      book: 'Book',
      article: 'Article',
      blog: 'Blog Post',
      tweet: 'Tweet',
      interview: 'Interview',
      'q&a': 'Q&A',
      other: 'Other',
    };
    return typeMap[type] || type;
  };

  const getStatusClass = (status: string) => {
    const statusMap: { [key: string]: string } = {
      pending: styles.statusPending,
      indexing: styles.statusIndexing,
      ready: styles.statusReady,
      error: styles.statusError,
    };
    return `${styles.statusBadge} ${statusMap[status] || ''}`;
  };

  const getStatusLabel = (status: string, documentId: string) => {
    if (status === 'indexing') {
      const progress = progressMap[documentId] ?? 0;
      return `Indexing, ${progress.toFixed(2)}%`;
    }
    const statusMap: { [key: string]: string } = {
      pending: 'Pending',
      indexing: 'Indexing',
      ready: 'Ready',
      error: 'Error',
    };
    return statusMap[status] || status;
  };

  if (isLoading) {
    return (
      <div className={styles.documentListContainer}>
        <div className={styles.loadingState}>
          <div className={styles.loadingSpinner}></div>
          <p>Loading documents...</p>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.documentListContainer}>
      <div className={styles.header}>
        <h2>Documents</h2>
        <button className={styles.addButton} onClick={handleAddDocument}>
          <span>Add Document</span>
        </button>
      </div>

      {error && <div className={styles.errorMessage}>{error}</div>}

      {documents.length === 0 ? (
        <div className={styles.emptyState}>
          <h3>No documents found</h3>
          <p>Get started by adding your first document</p>
        </div>
      ) : (
        <table className={styles.documentTable}>
          <thead>
            <tr>
              <th>Title</th>
              {isAdmin && <th>Author</th>}
              <th>Type</th>
              <th>Status</th>
              <th>Source URL</th>
              <th>Created at</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {documents.map(document => (
              <tr key={document.id}>
                <td>{document.title}</td>
                {isAdmin && <td>{document.author?.name || 'Unknown'}</td>}
                <td>{getDocumentTypeLabel(document.type)}</td>
                <td>
                  <span className={getStatusClass(document.status)}>
                    {getStatusLabel(document.status, document.id)}
                  </span>
                </td>
                <td>
                  {document.sourceUrl ? (
                    <a
                      href={document.sourceUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.documentLink}
                    >
                      View Source
                    </a>
                  ) : (
                    'N/A'
                  )}
                </td>
                <td>{new Date(document.createdAt).toLocaleString('en-GB', { hour12: false })}</td>
                <td>
                  <div className={styles.actionButtons}>
                    <button className={styles.editButton} onClick={() => handleEdit(document)}>
                      Edit
                    </button>
                    <button
                      className={styles.deleteButton}
                      onClick={() => handleDelete(document.id)}
                    >
                      Delete
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {documents.length > 0 && (
        <div className={styles.pagination}>
          <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
            Previous
          </button>
          <span>{`Page ${currentPage} of ${totalPages}`}</span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
      )}

      {isModalOpen && (
        <DocumentModal
          isAdmin={isAdmin}
          authorId={authorId}
          document={selectedDocument}
          onClose={() => setIsModalOpen(false)}
          onSave={(updatedDocument: Document) => {
            setDocuments(prev => {
              return selectedDocument
                ? prev.map(doc =>
                    doc.id === updatedDocument.id ? { ...doc, ...updatedDocument } : doc
                  )
                : [...prev, updatedDocument];
            });
            setIsModalOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default DocumentList;
