import React, { useState, useEffect } from 'react';
import adminService from '../../../services/adminService';
import { Document, Author, DocumentType } from '../../../interfaces/interfaces';
import styles from './DocumentModal.module.css';
import authorService from '../../../services/author/authorService';

interface DocumentModalProps {
  isAdmin: boolean;
  authorId?: string;
  document: Document | null;
  onClose: () => void;
  onSave: (document: Document) => void;
}

const DocumentModal: React.FC<DocumentModalProps> = ({
  isAdmin,
  authorId,
  document,
  onClose,
  onSave,
}) => {
  if (!isAdmin && !authorId) {
    throw new Error('Author ID is missing.');
  }

  const [title, setTitle] = useState('');
  const [documentAuthorId, setDocumentAuthorId] = useState('');
  const [type, setType] = useState<DocumentType>('book');
  const [sourceUrl, setSourceUrl] = useState('');
  const [description, setDescription] = useState('');
  const [content, setContent] = useState('');
  const [contentFile, setContentFile] = useState<File | null>(null);
  const [coverFile, setCoverFile] = useState<File | null>(null);
  const [coverPreview, setCoverPreview] = useState<string | null>(null);
  const [authors, setAuthors] = useState<Author[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [extractStyle, setExtractStyle] = useState<string>('');
  const [contentInputMethod, setContentInputMethod] = useState<'text' | 'file' | ''>('');

  const documentTypes: DocumentType[] = [
    'book',
    'article',
    'blog',
    'tweet',
    'interview',
    'lecture',
    'q&a',
    'other',
  ];

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        const response = await adminService.getAuthors(1, 100);
        setAuthors(response.authors);
      } catch (err) {
        setError('Failed to load authors');
        console.error(err);
      }
    };

    fetchAuthors();

    if (document) {
      setTitle(document.title);
      setDocumentAuthorId(document.authorId);
      setType(document.type);
      setSourceUrl(document.sourceUrl || '');
      setDescription(document.description || '');
      if (document.coverUrl) {
        setCoverPreview(document.coverUrl);
      }
    }
  }, [isAdmin, document]);

  const handleCoverChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setCoverFile(file);
      setCoverPreview(URL.createObjectURL(file));
    }
  };

  const handleContentFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setContentFile(file);
    }
  };

  const handleInputMethodChange = (method: 'text' | 'file') => {
    setContentInputMethod(method);
    // Clear the other input type when switching
    if (method === 'text') {
      setContentFile(null);
    } else {
      setContent('');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    // Validate that a content input method is selected
    if (!document && !contentInputMethod) {
      setError('Please select a content input method');
      setLoading(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('title', title);
      formData.append('authorId', authorId || documentAuthorId);
      formData.append('type', type);
      formData.append('extractStyle', extractStyle === 'yes' ? 'true' : 'false');

      if (coverFile) formData.append('cover', coverFile);

      // Handle content based on input method
      if (contentInputMethod === 'text' && content) {
        formData.append('content', content);
      }
      if (contentInputMethod === 'file' && contentFile) {
        formData.append('contentFile', contentFile);
      }

      let savedDocument;
      if (document) {
        // Update existing document
        if (isAdmin) {
          savedDocument = await adminService.updateDocument(document.id, formData);
        } else {
          savedDocument = await authorService.updateDocument(authorId || '', document.id, formData);
        }
      } else {
        // Create new document
        if (isAdmin) {
          savedDocument = await adminService.createDocument(formData);
        } else {
          savedDocument = await authorService.createDocument(authorId || '', formData);
        }
      }

      onSave(savedDocument);
    } catch (err: any) {
      setError(err.message || 'Failed to save document');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const getDocumentTypeLabel = (type: string) => {
    const typeMap: { [key: string]: string } = {
      book: 'Book',
      article: 'Article',
      blog: 'Blog Post',
      tweet: 'Tweet',
      interview: 'Interview',
      lecture: 'Lecture',
      'q&a': 'Q&A',
      other: 'Other',
    };
    return typeMap[type] || type;
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>{document ? 'Edit Document' : 'Add New Document'}</h2>
          <button className={styles.closeButton} onClick={onClose}>
            &times;
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          {error && <div className={styles.error}>{error}</div>}

          <div className={styles.modalBody}>
            <div className={styles.formGroup}>
              <label htmlFor="title">Title *</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={e => setTitle(e.target.value)}
                placeholder="Enter document title"
                required
              />
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="author">Author *</label>
              <select
                id="author"
                value={documentAuthorId || authorId}
                onChange={e => setDocumentAuthorId(e.target.value)}
                required
                disabled={!isAdmin}
              >
                <option value="">Select an author</option>
                {authors.map(author => (
                  <option key={author.id} value={author.id}>
                    {author.name}
                  </option>
                ))}
              </select>
            </div>

            <div className={styles.formGroup}>
              <label htmlFor="type">Document Type *</label>
              <select
                id="type"
                value={type}
                onChange={e => setType(e.target.value as DocumentType)}
                required
              >
                {documentTypes.map(docType => (
                  <option key={docType} value={docType}>
                    {getDocumentTypeLabel(docType)}
                  </option>
                ))}
              </select>
            </div>

            {!document && (
              <div className={styles.formGroup}>
                <label htmlFor="extractStyle">
                  Should this document be used to extract author's style? *
                </label>
                <select
                  id="extractStyle"
                  value={extractStyle}
                  onChange={e => setExtractStyle(e.target.value)}
                  required
                >
                  <option value="">Select an option</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            )}

            {/* Only show source URL and description in edit mode (read-only) */}
            {document && (
              <>
                <div className={styles.formGroup}>
                  <label htmlFor="sourceUrl">Source URL</label>
                  {sourceUrl ? (
                    <a
                      href={sourceUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={styles.sourceLink}
                    >
                      View Document Source
                    </a>
                  ) : (
                    <span className={styles.readOnlyField}>No source URL available</span>
                  )}
                </div>

                <div className={styles.formGroup}>
                  <label htmlFor="description">Description</label>
                  <textarea
                    id="description"
                    value={description}
                    readOnly
                    rows={3}
                    className={styles.readOnlyField}
                  />
                </div>
              </>
            )}
            {!document && (
              <>
                <div className={styles.formGroup}>
                  <label>Content Input Method *</label>
                  <div className={styles.radioGroup}>
                    <label>
                      <input
                        type="radio"
                        name="contentInputMethod"
                        checked={contentInputMethod === 'text'}
                        onChange={() => handleInputMethodChange('text')}
                        required
                      />
                      Raw Text Input
                    </label>
                    <label>
                      <input
                        type="radio"
                        name="contentInputMethod"
                        checked={contentInputMethod === 'file'}
                        onChange={() => handleInputMethodChange('file')}
                        required
                      />
                      File Upload
                    </label>
                  </div>
                </div>

                {contentInputMethod === 'text' && (
                  <div className={styles.formGroup}>
                    <label htmlFor="content">Document Content *</label>
                    <textarea
                      id="content"
                      value={content}
                      onChange={e => setContent(e.target.value)}
                      rows={10}
                      placeholder="Enter the document content here. This will be saved as a text file."
                      required
                    />
                  </div>
                )}

                {contentInputMethod === 'file' && (
                  <div className={styles.formGroup}>
                    <label htmlFor="contentFile">Document File *</label>
                    <input
                      type="file"
                      id="contentFile"
                      onChange={handleContentFileChange}
                      accept=".txt,.doc,.docx,.pdf"
                      required
                    />
                  </div>
                )}
              </>
            )}

            <div className={styles.formGroup}>
              <label htmlFor="cover">Cover Image</label>
              {coverPreview ? (
                <>
                  <div className={styles.imagePreview}>
                    <img src={coverPreview} alt="Cover preview" />
                  </div>
                  <button
                    type="button"
                    className={styles.removeButton}
                    onClick={() => {
                      setCoverPreview(null);
                      setCoverFile(null);
                    }}
                  >
                    Remove Cover Image
                  </button>
                </>
              ) : (
                <input type="file" id="cover" accept="image/*" onChange={handleCoverChange} />
              )}
            </div>
          </div>
          <div className={styles.formActions}>
            <button type="button" className={styles.cancelButton} onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className={styles.saveButton} disabled={loading}>
              {loading ? 'Saving...' : 'Save Document'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DocumentModal;
