import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminService';
import { Line } from 'react-chartjs-2';
import { ChartOptions } from 'chart.js';
import 'chart.js/auto';
import styles from './Graph.module.css';

const AuthorGraph: React.FC = () => {
  const [totalAuthors, setTotalAuthors] = useState(0);
  const [authorsPerDay, setAuthorsPerDay] = useState<{ date: string; count: number }[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const { totalAuthors, authorsPerDay } = await adminService.getAuthorStats();
      setTotalAuthors(totalAuthors);
      setAuthorsPerDay(authorsPerDay);
    };

    fetchData();
  }, []);

  const maxYValue = Math.max(...authorsPerDay.map(item => item.count)) * 1.2;

  const data = {
    labels: authorsPerDay.map(item => item.date),
    datasets: [
      {
        label: 'Authors per Day',
        data: authorsPerDay.map(item => item.count),
        fill: false,
        backgroundColor: 'rgb(153, 102, 255)',
        borderColor: 'rgba(153, 102, 255, 0.2)',
        tension: 0.4,
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#1E3742',
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: maxYValue,
        ticks: {
          color: '#1E3742',
        },
        grid: {
          color: '#E3E5DC',
        },
      },
      x: {
        ticks: {
          color: '#1E3742',
        },
        grid: {
          color: '#E3E5DC',
        },
      },
    },
  };

  return (
    <div className={styles.graphContainer}>
      <h3 className={styles.graphTitle}>Total Authors: {totalAuthors}</h3>
      <div className={styles.chartWrapper}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default AuthorGraph;
