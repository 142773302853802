import { FC } from 'react';
import { Typography } from '../ui/typography';

interface ChatUserMessageProps {
  message: string;
}

export const ChatUserMessage: FC<ChatUserMessageProps> = ({ message }) => {
  return (
    <div className="max-w-[592px] w-fit flex flex-row ml-auto gap-3 bg-card-light rounded-2xl px-4 py-3">
      <Typography className="text-accent-foreground leading-normal">{message}</Typography>
    </div>
  );
};
