import { FC } from 'react';
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from 'src/components/ui/carousel';
import { Card, CardHeader, CardTitle } from 'src/components/ui/card';
import { cn } from 'src/lib/utils';
import { useGetAuthorPractices } from 'src/services/author/useGetAuthorPractices';

interface PracticesListProps {
  authorId: string;
  setMessage: (message: string) => void;
}

const PracticesList: FC<PracticesListProps> = ({ authorId, setMessage }) => {
  const { data: practices, isLoading } = useGetAuthorPractices(authorId);

  return (
    <div className="mt-16">
      {isLoading && (
        <div className="flex justify-center items-center py-10">
          <div className="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}
      {!isLoading && practices && (
        <Carousel className="overflow-hidden" opts={{ dragFree: true }}>
          <CarouselContent className="duration-10 -ml-3">
            {practices.map((practice, index) => (
              <CarouselItem
                key={practice.title}
                className={cn(
                  'w-1 basis-[47%] md:basis-1/3 pl-3',
                  index === practices.length - 1 ? 'pr-5' : ''
                )}
              >
                <Card
                  className="w-full p-0 pb-8 h-full border border-none cursor-pointer"
                  onClick={() => setMessage(practice.prompt)}
                >
                  <CardHeader className="p-0 flex flex-col gap-3">
                    <img
                      src={practice.image}
                      alt={practice.title}
                      className="rounded-t-md h-[130px] md:h-[123px] w-full object-cover"
                    />
                    <CardTitle className="font-crimson text-lg px-4 font-[600]">
                      {practice.title}
                    </CardTitle>
                  </CardHeader>
                </Card>
              </CarouselItem>
            ))}
          </CarouselContent>
          <div className="md:flex justify-end items-center hidden">
            <CarouselPrevious variant="ghost" />
            <CarouselNext variant="ghost" />
          </div>
        </Carousel>
      )}
    </div>
  );
};

export default PracticesList;
