// client/src/socket.ts
import { io, Socket } from 'socket.io-client';
import authTokenService from './services/authTokenService';

const socketUrl = process.env.REACT_APP_SOCKET_URL;
if (!socketUrl) {
  throw new Error('REACT_APP_SOCKET_URL is not defined!');
}

const token = authTokenService.getToken();

console.log('[Socket] Initializing connection');
console.log('[Socket] Socket URL:', socketUrl);
console.log('[Socket] Token exists:', !!token);

const socket: Socket = io(socketUrl, {
  withCredentials: true, // Can be removed if not using cookie-based auth
  transports: ['websocket'], // Force websocket
  auth: {
    token,
  },
});

// Debug logging for connection events
socket.on('connect', () => {
  console.log('[Socket] Connected, socket ID:', socket.id);
});

socket.on('connect_error', (error: any) => {
  console.error('[Socket] Connection error:', error);
  if (error?.message) console.error('[Socket] Error message:', error.message);
  if (error?.message) console.error('[Socket] Error description:', error.description);
  if (error?.message) console.error('[Socket] Error context:', error.context);
  if (error?.stack) console.error('[Socket] Error stack:', error.stack);
});

socket.on('disconnect', reason => {
  console.warn('[Socket] Disconnected. Reason:', reason);
});

socket.io.on('reconnect_attempt', attempt => {
  console.log('[Socket] Reconnect attempt #', attempt);
});

socket.io.on('reconnect_failed', () => {
  console.error('[Socket] Reconnect failed.');
});

socket.io.on('error', err => {
  console.error('[Socket] General error event:', err);
});

// Listen to low-level transport error (especially WebSocket)
socket.io.engine.on('upgradeError', err => {
  console.error('[Socket] Engine upgrade error (WebSocket fail):', err);
});

export default socket;
