import { FC, useEffect, useRef } from 'react';
import { Message } from 'src/interfaces/interfaces';
import { ChatUserMessage } from './ChatUserMessage';
import { ChatEchoMessage } from './ChatEchoMessage';
import { useVoiceMessage } from 'src/hooks/useVoiceMessage';
import { getInitials } from 'src/utils/initials';

interface ChatMessagesListProps {
  messages: Message[];
  authorName: string;
  authorPhotoEcho: string;
  isLoading?: boolean;
  authorId: string;
  isFirstLoad: boolean;
}

export const ChatMessagesList: FC<ChatMessagesListProps> = ({
  messages,
  authorName,
  authorPhotoEcho,
  isLoading = false,
  authorId,
  isFirstLoad,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { handleVoiceClick, loadingAudio, playingAudio, audioProgress } = useVoiceMessage(authorId);

  useEffect(() => {
    containerRef.current?.scrollTo({
      top: containerRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }, [messages.length, isLoading]);

  return (
    <div ref={containerRef} className="relative z-0 flex-1 overflow-y-auto pb-5 gap-4">
      <div className="flex flex-col flex-1 gap-4 max-w-[750px] mx-auto px-5 md:px-3">
        {messages.map((message, index) => (
          <div key={message.id} className={index === 0 ? 'mt-4' : ''}>
            {message.role === 'user' ? (
              <ChatUserMessage message={message.content} />
            ) : (
              <ChatEchoMessage
                message={message.content}
                animate={!isFirstLoad && index === messages.length - 1}
                onVoiceClick={
                  !message.isStreaming ? () => handleVoiceClick(message.content, index) : undefined
                }
                isLoading={loadingAudio === index}
                isPlaying={playingAudio === index}
                audioProgress={audioProgress[index] || 0}
                hideExtras={!!message.isStreaming}
                {...(index === messages.length - 1 && {
                  authorPhotoEcho,
                  authorInitials: getInitials(authorName),
                })}
              />
            )}
          </div>
        ))}
        {isLoading && (
          <ChatEchoMessage
            message=""
            isContentLoading={true}
            authorPhotoEcho={authorPhotoEcho}
            authorInitials={getInitials(authorName)}
          />
        )}
      </div>
    </div>
  );
};
