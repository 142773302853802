// File: client/components/MainContent.tsx
import React, { useRef, useEffect, useState, useCallback } from 'react';
import BookSection from './BookSection';
import ChatComponent from './ChatComponent';
import styles from './MainContent.module.css';
import { ChatProvider, useChat } from '../../context/ChatContext';
import { ReactComponent as SendArrowIcon } from '../../assets/icons/arrow-send.svg';
import { useAuthor } from '../../context/AuthorContext';
import { useLogEvent } from '../../utils/analytics';
import { EventAction } from '../../constants/analytics';
import { useLogRenders } from '../../utils/debugLogger';

const MainContent: React.FC = () => {
  const { author, books, documents, loading, error } = useAuthor();
  const logEvent = useLogEvent();
  useLogRenders('MainContent');

  const buildStorageKey = (authorId: string | undefined) => {
    return authorId ? `chosenBookId_${authorId}` : 'chosenBookId_unknown';
  };

  const [bookId, setBookId] = useState<string | null>(null);
  const [chatOverGraph, setChatOverGraph] = useState<boolean>(false); // State for the new checkbox

  useEffect(() => {
    if (loading || !author?.id) {
      setBookId(null);
      return;
    }
    if (!books || books.length === 0) {
      setBookId(null);
      return;
    }
    const storageKey = buildStorageKey(author.id);
    const storedBookId = localStorage.getItem(storageKey);
    if (storedBookId) {
      console.log(`[MainContent] Using storedBookId for author ${author.id}:`, storedBookId);
      setBookId(storedBookId);
    } else {
      console.log(
        `[MainContent] No storedBookId; using the first book for author ${author.id}:`,
        books[0].id
      );
      setBookId(books[0].id);
    }
  }, [loading, author, books]);

  useEffect(() => {
    if (!author?.id || !bookId) return;
    const storageKey = buildStorageKey(author.id);
    console.log('[MainContent] Storing bookId:', bookId, 'for author:', author.id);
    localStorage.setItem(storageKey, bookId);
  }, [bookId, author]);

  useEffect(() => {
    if (!documents?.length) return;

    setChatOverGraph(documents.length > 0);
  }, [documents?.length]);

  const scrollableContentRef = useRef<HTMLDivElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const memoizedSetBookId = useCallback((newBookId: string | null) => {
    console.log('[MainContent] memoizedSetBookId:', newBookId);
    setBookId(newBookId);
  }, []);

  // Child component that manages chat
  const ChatContent = () => {
    const { messages, input, setInput, sendMessage, sendMessageOverGraph, loading, clearChat } =
      useChat();
    const prevMessagesLength = useRef<number>(0);
    const prevLoading = useRef<boolean>(false);

    useEffect(() => {
      const diffMessages = messages.length - prevMessagesLength.current;
      const shouldScroll =
        (diffMessages > 1 && diffMessages <= 2) || prevLoading.current !== loading;
      if (scrollableContentRef.current && shouldScroll) {
        scrollableContentRef.current.scrollTo({
          top: scrollableContentRef.current.scrollHeight,
          behavior: 'smooth',
        });
        prevLoading.current = loading;
      }
      prevMessagesLength.current = messages.length;
    }, [messages, loading]);

    useEffect(() => {
      if (textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        const maxHeight = 20 * 4 + 24;
        textareaRef.current.style.height =
          Math.min(textareaRef.current.scrollHeight, maxHeight) + 'px';
      }
    }, [input]);

    const handleSendMessage = () => {
      if (input.trim() !== '' && bookId && author) {
        console.log('[ChatContent] Sending message. Input:', input);
        if (chatOverGraph) {
          sendMessageOverGraph(author);
        } else {
          sendMessage(author);
        }
        logEvent(EventAction.MESSAGE_SENT, `Message size: ${input.length} chars`, {
          messages: messages.length,
          author: author?.name,
          book: books?.find(book => book.id === bookId)?.title,
          authorId: author?.id,
          bookId,
        });
        if (textareaRef.current) {
          textareaRef.current.style.height = 'auto';
        }
      }
    };

    const handleInputKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        handleSendMessage();
      }
    };

    return (
      <>
        <div className={styles.scrollableContent} ref={scrollableContentRef}>
          <div className={styles.bookSection}>
            <BookSection bookId={bookId} setBookId={memoizedSetBookId} clearChat={clearChat} />
          </div>
          <div className={styles.chatComponent}>
            <ChatComponent />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <textarea
            ref={textareaRef}
            value={input}
            onChange={e => setInput(e.target.value)}
            onKeyDown={handleInputKeyDown}
            placeholder="Ask anything..."
            className={styles.inputField}
            rows={1}
          />
          <div className={styles.toggleContainer}>
            <input type="checkbox" id="confidentToggle" className={styles.toggleSwitch} />
            <label htmlFor="confidentToggle" className={styles.toggleLabel}>
              Private
            </label>
          </div>
          <button
            onClick={handleSendMessage}
            className={styles.sendButton}
            disabled={loading || !bookId}
          >
            <SendArrowIcon className={styles.sendArrowIcon} />
          </button>
        </div>
      </>
    );
  };

  return (
    <div className={styles.mainContent}>
      {/* Conditionally render "chat over graph" checkbox if the author has documents */}
      {documents && documents.length > 0 && (
        <div className={styles.checkboxContainer}>
          <input
            type="checkbox"
            id="chatOverGraph"
            checked={chatOverGraph}
            onChange={e => setChatOverGraph(e.target.checked)}
          />
          <label htmlFor="chatOverGraph" className={styles.checkboxLabel}>
            Chat over graph
          </label>
        </div>
      )}
      <ChatProvider authorId={author?.id} bookId={bookId}>
        <ChatContent />
      </ChatProvider>
    </div>
  );
};

export default MainContent;
