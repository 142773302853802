import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Conversation } from 'src/interfaces/interfaces';
import chatService, { ICreateConversation } from 'src/services/chat/chatService';
import { toast } from 'react-toastify';
import { getConversationsQueryKey } from './useGetConversations';

export const useCreateConversation = (
  authorId: string,
  {
    onSuccess,
  }: {
    onSuccess: (createdConversation: Conversation) => void;
  }
) => {
  const queryClient = useQueryClient();
  const queryKey = getConversationsQueryKey(authorId);

  return useMutation<Conversation, Error, ICreateConversation>({
    mutationFn: chatService.createConversation,
    onSuccess,
    onError: () => {
      toast.error('Failed to create chat!');
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });
};
