import React, { useState, useEffect, useCallback } from 'react';
import { Prompt, PromptState } from '../../../interfaces/interfaces';
import PromptModal from './PromptModal';
import PromptVersionHistory from './PromptVersionHistory';
import { Filter, Edit, Archive, History, ChevronDown, ChevronRight, RefreshCw } from 'lucide-react';
import {
  useGetPrompts,
  useUpdatePromptState,
  useRestorePrompt,
} from '../../../services/prompts/usePrompts';
import promptService from '../../../services/prompts/promptService';
import { getStateColor, buttonStyles, confirmationMessages, errorMessages } from './promptUtils';
import PromptStateActions from './PromptStateActions';
import PromptOverridePanel, { PromptOverrideProvider } from './PromptOverridePanel';
import { cn } from 'src/lib/utils';

const PromptList: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isVersionHistoryOpen, setIsVersionHistoryOpen] = useState(false);
  const [editingPrompt, setEditingPrompt] = useState<Prompt | null>(null);
  const [selectedPromptName, setSelectedPromptName] = useState<string>('');
  const [filterState, setFilterState] = useState<PromptState | ''>('');
  const [filterType, setFilterType] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [expandedPrompts, setExpandedPrompts] = useState<Record<string, boolean>>({});
  const [promptVersions, setPromptVersions] = useState<Record<string, Prompt[]>>({});

  // Create a ref to track if we need to refresh versions after a restore operation
  const [refreshTrigger, setRefreshTrigger] = useState(0);

  // Use React Query hooks
  const { data, isLoading, isError, error } = useGetPrompts(
    currentPage,
    pageSize,
    filterType || undefined,
    filterState || undefined,
    searchQuery || undefined
  );

  const prompts = data?.prompts || [];
  const totalPrompts = data?.totalCount || 0;

  const updatePromptStateMutation = useUpdatePromptState();
  const restorePromptMutation = useRestorePrompt();

  // Function to refresh a specific prompt's versions
  const refreshPromptVersions = useCallback(
    async (name: string) => {
      // Only proceed if this prompt is expanded
      if (!expandedPrompts[name]) return;

      try {
        const versions = await promptService.getPromptVersions(name);
        const mainPrompt = prompts.find((p: Prompt) => p.name === name);

        if (mainPrompt && versions) {
          // Filter out the version that matches the main prompt
          const olderVersions = versions.filter(
            (p: Prompt) =>
              !(
                p.majorVersion === mainPrompt.majorVersion &&
                p.minorVersion === mainPrompt.minorVersion
              )
          );

          // Update only this prompt's versions
          setPromptVersions(prev => ({
            ...prev,
            [name]: olderVersions,
          }));
        }
      } catch (error) {
        console.error(`Error refreshing versions for ${name}:`, error);
      }
    },
    [expandedPrompts, prompts]
  );

  // Function to refresh all expanded rows
  const refreshExpandedRows = useCallback(async () => {
    // For each expanded prompt name, fetch its versions
    for (const name of Object.keys(expandedPrompts)) {
      if (expandedPrompts[name]) {
        await refreshPromptVersions(name);
      }
    }
  }, [expandedPrompts, refreshPromptVersions]);

  // Add useEffect to handle refresh when prompt overrides change
  useEffect(() => {
    const handleOverrideChanges = () => {
      // Force refresh the component
      refreshExpandedRows();
    };

    // Listen for the custom event
    window.addEventListener('prompt-overrides-changed', handleOverrideChanges);

    return () => {
      window.removeEventListener('prompt-overrides-changed', handleOverrideChanges);
    };
  }, [refreshExpandedRows]);

  // Run refreshExpandedRows effect when data changes
  useEffect(() => {
    refreshExpandedRows();
  }, [refreshExpandedRows, prompts, expandedPrompts, refreshTrigger]);

  const handleCreatePrompt = () => {
    setEditingPrompt(null);
    setIsModalOpen(true);
  };

  const handleEditPrompt = (prompt: Prompt) => {
    setEditingPrompt(prompt);
    setIsModalOpen(true);
  };

  const handleArchivePrompt = async (promptId: string) => {
    if (window.confirm(confirmationMessages.archive)) {
      try {
        await updatePromptStateMutation.mutateAsync({ id: promptId, state: PromptState.ARCHIVED });
      } catch (error) {
        alert(errorMessages.archive);
      }
    }
  };

  const handleRestorePrompt = async (promptId: string, promptName: string) => {
    if (window.confirm(confirmationMessages.restore)) {
      try {
        await restorePromptMutation.mutateAsync({ id: promptId, promptName });

        // Dispatch an event to notify the override panel that prompt states have changed
        window.dispatchEvent(new Event('prompt-state-changed'));

        // Refresh both the main list and this specific prompt's versions
        setTimeout(() => {
          setRefreshTrigger(prev => prev + 1);
          refreshPromptVersions(promptName);
        }, 500);
      } catch (error) {
        alert(errorMessages.restore);
      }
    }
  };

  const handleUpdatePromptState = async (
    promptId: string,
    state: PromptState,
    promptName?: string
  ) => {
    try {
      await updatePromptStateMutation.mutateAsync({ id: promptId, state });

      // Dispatch an event to notify the override panel that prompt states have changed
      window.dispatchEvent(new Event('prompt-state-changed'));

      if (promptName) {
        setTimeout(() => {
          setRefreshTrigger(prev => prev + 1);
          refreshPromptVersions(promptName);
        }, 500);
      }
    } catch (error) {
      alert(errorMessages.updateState);
    }
  };

  const handleSavePrompt = async () => {
    // If we were editing a prompt, refresh its versions
    if (editingPrompt) {
      refreshPromptVersions(editingPrompt.name);
    }
    setIsModalOpen(false);
  };

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    setCurrentPage(1);
  };

  const handleViewVersions = (promptName: string) => {
    setSelectedPromptName(promptName);
    setIsVersionHistoryOpen(true);
  };

  const handleVersionSelect = (prompt: Prompt) => {
    setEditingPrompt(prompt);
    setIsVersionHistoryOpen(false);
    setIsModalOpen(true);
  };

  const toggleExpandPrompt = async (promptName: string) => {
    // If already expanded, just toggle
    if (expandedPrompts[promptName]) {
      setExpandedPrompts({
        ...expandedPrompts,
        [promptName]: false,
      });
      return;
    }

    // If not loaded yet, fetch versions
    if (!promptVersions[promptName]) {
      try {
        const versions = await promptService.getPromptVersions(promptName);
        // Skip the first version which is already shown
        const olderVersions = versions.filter(
          (p: Prompt) =>
            !(
              p.majorVersion ===
                prompts.find((main: Prompt) => main.name === promptName)?.majorVersion &&
              p.minorVersion ===
                prompts.find((main: Prompt) => main.name === promptName)?.minorVersion
            )
        );

        setPromptVersions({
          ...promptVersions,
          [promptName]: olderVersions,
        });
      } catch (error) {
        console.error('Error fetching prompt versions:', error);
      }
    }

    // Toggle expanded state
    setExpandedPrompts({
      ...expandedPrompts,
      [promptName]: true,
    });
  };

  const totalPages = Math.ceil(totalPrompts / pageSize);

  const promptTypes: string[] = Array.from(
    new Set(prompts.map((prompt: Prompt) => prompt.type as string))
  );

  const renderPromptRow = (prompt: Prompt, isChild = false) => {
    return (
      <tr
        key={prompt.id}
        className={cn({
          'bg-gray-50 border-t border-gray-200': isChild,
          'border-t border-gray-300': !isChild,
        })}
      >
        <td className="py-3 px-4 border-b border-gray-200">
          <div className="flex items-center">
            {!isChild && (
              <button
                onClick={() => toggleExpandPrompt(prompt.name)}
                className="mr-2 focus:outline-none"
                aria-label={expandedPrompts[prompt.name] ? 'Collapse versions' : 'Expand versions'}
              >
                {expandedPrompts[prompt.name] ? (
                  <ChevronDown size={16} />
                ) : (
                  <ChevronRight size={16} />
                )}
              </button>
            )}
            <span className={cn({ 'ml-6': isChild })}>{prompt.name}</span>
            {isChild && <span className="ml-2 text-xs text-gray-500">(older version)</span>}
          </div>
        </td>
        <td className="py-3 px-4 border-b border-gray-200">{prompt.type}</td>
        <td className="py-3 px-4 border-b border-gray-200">{`${prompt.majorVersion}.${prompt.minorVersion}`}</td>
        <td className="py-3 px-4 border-b border-gray-200">
          <span
            className={`inline-block py-1 px-2 rounded text-xs font-medium ${getStateColor(prompt.state)}`}
          >
            {prompt.state}
          </span>
        </td>
        <td className="py-3 px-4 border-b border-gray-200">{prompt.author?.name || 'N/A'}</td>
        <td className="py-3 px-4 border-b border-gray-200">
          {prompt.createdBy?.name || 'Unknown'}
        </td>
        <td className="py-3 px-4 border-b border-gray-200">
          {new Date(prompt.createdAt).toLocaleString()}
        </td>
        <td className="py-3 px-4 border-b border-gray-200">
          <div className="flex gap-2 items-center">
            <button
              onClick={() => handleEditPrompt(prompt)}
              className="flex items-center justify-center bg-transparent border-none p-1.5 cursor-pointer text-gray-600 rounded hover:bg-gray-200 hover:text-black disabled:opacity-50 disabled:cursor-not-allowed"
              title="Edit"
              disabled={prompt.state === PromptState.ARCHIVED}
            >
              <Edit size={18} />
            </button>

            {prompt.state !== PromptState.ARCHIVED && (
              <button
                onClick={() => handleArchivePrompt(prompt.id)}
                className="flex items-center justify-center bg-transparent border-none p-1.5 cursor-pointer text-gray-600 rounded hover:bg-gray-200 hover:text-black disabled:opacity-50 disabled:cursor-not-allowed"
                title="Archive"
                disabled={prompt.state === PromptState.PUBLISHED}
              >
                <Archive size={18} />
              </button>
            )}

            {prompt.state === PromptState.ARCHIVED && (
              <button
                onClick={() => handleRestorePrompt(prompt.id, prompt.name)}
                className="flex items-center justify-center bg-transparent border-none p-1.5 cursor-pointer text-gray-600 rounded hover:bg-gray-200 hover:text-black"
                title="Restore"
              >
                <RefreshCw size={18} />
              </button>
            )}

            <button
              onClick={() => handleViewVersions(prompt.name)}
              className="flex items-center justify-center bg-transparent border-none p-1.5 cursor-pointer text-gray-600 rounded hover:bg-gray-200 hover:text-black"
              title="Version History"
            >
              <History size={18} />
            </button>

            <PromptStateActions
              prompt={prompt}
              onUpdateState={(id, state) => handleUpdatePromptState(id, state, prompt.name)}
            />
          </div>
        </td>
      </tr>
    );
  };

  return (
    <div className="p-5 w-full">
      <div className="flex justify-between items-center mb-5">
        <h2 className="text-2xl font-semibold m-0">Prompt Management</h2>
        <button
          onClick={handleCreatePrompt}
          className="bg-green-600 text-white border-none py-2.5 px-4 rounded cursor-pointer text-sm hover:bg-green-700"
        >
          Create New Prompt
        </button>
      </div>

      {/* Add the prompt override panel at the top */}
      <PromptOverridePanel />

      <div className="flex justify-between mb-5 flex-wrap gap-4">
        <form onSubmit={handleSearch} className="flex gap-2.5">
          <input
            type="text"
            placeholder="Search prompts..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            className="py-2 px-3 border border-gray-300 rounded w-64"
          />
          <button
            type="submit"
            className="bg-blue-500 text-white border-none py-2 px-4 rounded cursor-pointer hover:bg-blue-600"
          >
            Search
          </button>
        </form>

        <div className="flex flex-wrap gap-3">
          <div className="flex items-center gap-2">
            <select
              value={filterState}
              onChange={e => setFilterState(e.target.value as PromptState | '')}
              className="border border-gray-300 rounded py-2 px-3"
            >
              <option value="">All States</option>
              {Object.values(PromptState).map(state => (
                <option key={state} value={state}>
                  {state}
                </option>
              ))}
            </select>

            <select
              value={filterType}
              onChange={e => setFilterType(e.target.value)}
              className="border border-gray-300 rounded py-2 px-3"
            >
              <option value="">All Types</option>
              {promptTypes.map(type => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>

            <button
              onClick={() => {
                setFilterState('');
                setFilterType('');
                setSearchQuery('');
              }}
              className="flex items-center gap-1.5 bg-gray-200 border-none py-2 px-3 rounded cursor-pointer hover:bg-gray-300"
            >
              <Filter size={16} />
              Reset Filters
            </button>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="text-center py-8">Loading prompts...</div>
      ) : isError ? (
        <div className="text-center py-8 text-red-500">Error: {error?.message}</div>
      ) : (
        <div className="overflow-hidden overflow-x-auto border border-gray-200 rounded">
          <table className="min-w-full bg-white">
            <thead className="bg-gray-50">
              <tr>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-1/5">
                  Name
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Version
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  State
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Author
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created By
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Created At
                </th>
                <th className="py-3 px-4 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {prompts.length === 0 ? (
                <tr>
                  <td colSpan={8} className="py-5 text-center text-gray-500">
                    No prompts found.
                  </td>
                </tr>
              ) : (
                prompts.map((prompt: Prompt) => {
                  // Render the main prompt row
                  const rows = [renderPromptRow(prompt)];

                  // Render child rows for versions if expanded
                  if (expandedPrompts[prompt.name] && promptVersions[prompt.name]) {
                    promptVersions[prompt.name].forEach(childPrompt => {
                      rows.push(renderPromptRow(childPrompt, true));
                    });
                  }

                  return rows;
                })
              )}
            </tbody>
          </table>
        </div>
      )}

      {totalPages > 1 && (
        <div className="flex justify-center mt-5">
          <div className="flex items-center gap-2">
            <button
              onClick={() => setCurrentPage(prev => Math.max(1, prev - 1))}
              disabled={currentPage === 1}
              className={cn('border border-gray-300 rounded px-3 py-1', {
                'opacity-50 cursor-not-allowed': currentPage === 1,
              })}
            >
              Previous
            </button>
            <span className="text-gray-600">
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={() => setCurrentPage(prev => Math.min(totalPages, prev + 1))}
              disabled={currentPage === totalPages}
              className={cn('border border-gray-300 rounded px-3 py-1', {
                'opacity-50 cursor-not-allowed': currentPage === totalPages,
              })}
            >
              Next
            </button>
          </div>
        </div>
      )}

      {isModalOpen && (
        <PromptModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSave={handleSavePrompt}
          prompt={editingPrompt}
        />
      )}

      {isVersionHistoryOpen && (
        <PromptVersionHistory
          promptName={selectedPromptName}
          onClose={() => setIsVersionHistoryOpen(false)}
          onVersionSelect={handleVersionSelect}
          onRestore={() => {
            // Trigger a refresh of all expanded rows
            setRefreshTrigger(prev => prev + 1);
            // Also explicitly refresh this prompt's versions if it's expanded
            refreshPromptVersions(selectedPromptName);
          }}
          onStateChange={() => {
            // Same behavior as onRestore - refresh all relevant data
            setRefreshTrigger(prev => prev + 1);
            refreshPromptVersions(selectedPromptName);
          }}
        />
      )}
    </div>
  );
};

export default () => (
  <PromptOverrideProvider>
    <PromptList />
  </PromptOverrideProvider>
);
