import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { isAuthorsechoHost } from './utils/getCurrentHost';
import AuthorsEchoRoutes from './routes/AuthorsEchoRoutes';
import FindWisdomRoutes from './routes/FindWisdomRoutes';
import { initializeAmplitude, trackPageView } from './utils/analytics';
import { logComponentLoad } from './utils/debugLogger';

const AppContent: React.FC = () => {
  const location = useLocation();
  console.log('AppContent!');
  initializeAmplitude();
  logComponentLoad('AppContent', { location: location.pathname }); // Log when AppContent loads

  useEffect(() => {
    logComponentLoad('Route Change', { location: location.pathname });
    trackPageView(location.pathname);
  }, [location]);

  return isAuthorsechoHost() || window.location.pathname === '/authors-landing' ? (
    <AuthorsEchoRoutes />
  ) : (
    <FindWisdomRoutes />
  );
};

const App: React.FC = () => {
  logComponentLoad('App');
  return <AppContent />;
};

export default App;
