import React, { useEffect, useState } from 'react';
import styles from './BookCarousel.module.css';
import { Book } from '../../interfaces/interfaces';
import { useLogRenders } from '../../utils/debugLogger';

interface BookCarouselProps {
  books: Book[] | undefined | null;
  currentBookId: string | null;
  setBookId: (bookId: string | null) => void;
}

const BookCarousel: React.FC<BookCarouselProps> = ({ books, currentBookId, setBookId }) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  useLogRenders('BookCarousel');

  useEffect(() => {
    if (books && books.length > 0) {
      const newIndex = books.findIndex(b => b.id === currentBookId);
      setActiveIndex(newIndex >= 0 ? newIndex : 0);
    }
  }, [books, currentBookId]);

  // Safeguard if no books
  if (books && books.length === 0) {
    // return <div>No books available</div>;
  }

  // Move carousel left or right
  function goLeft() {
    if (!books) return;
    const prevIndex = (activeIndex - 1 + books.length) % books.length;
    setActiveIndex(prevIndex);
    selectBook(books[prevIndex]);
  }

  function goRight() {
    if (!books) return;
    const nextIndex = (activeIndex + 1) % books.length;
    setActiveIndex(nextIndex);
    selectBook(books[nextIndex]);
  }

  const leftIndex = !books ? 0 : (activeIndex - 1 + books.length) % books.length;
  const rightIndex = !books ? 0 : (activeIndex + 1) % books.length;
  const centerBook = !books ? null : books[activeIndex];
  const leftBook = !books ? null : books[leftIndex];
  const rightBook = !books ? null : books[rightIndex];

  function selectBook(book: Book) {
    setBookId(book.id);
  }

  return (
    <div className={styles.carouselContainer}>
      {books && books.length > 2 && leftBook && (
        <div
          className={styles.leftBook}
          onClick={() => {
            selectBook(leftBook);
          }}
        >
          <img src={leftBook.coverUrl ?? ''} alt={leftBook.title} />
        </div>
      )}
      {books && centerBook && (
        <div className={styles.centerBook}>
          <img
            src={centerBook.coverUrl ?? ''}
            alt={centerBook.title}
            onClick={() => selectBook(centerBook!)}
          />
          <div className={styles.bookTitle}>
            {centerBook!.title.length > 48
              ? centerBook!.title.substring(0, 48) + '...'
              : centerBook!.title}
          </div>
        </div>
      )}

      {books && books.length >= 2 && rightBook && (
        <div
          className={styles.rightBook}
          onClick={() => {
            selectBook(rightBook);
          }}
        >
          <img src={rightBook.coverUrl ?? ''} alt={rightBook.title} />
        </div>
      )}

      {books && books.length > 1 && (
        <div className={styles.dotsContainer}>
          {books.map((book, i) => (
            <div
              key={book.id}
              className={i === activeIndex ? styles.activeDot : styles.dot}
              onClick={() => selectBook(book)}
            />
          ))}
        </div>
      )}

      {books && books.length > 1 && (
        <>
          <button className={styles.leftArrow} onClick={goLeft}>
            ‹
          </button>
          <button className={styles.rightArrow} onClick={goRight}>
            ›
          </button>
        </>
      )}
    </div>
  );
};

export default React.memo(BookCarousel);
