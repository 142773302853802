import React, { useMemo, useCallback } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import Stats from './Stats';
import UserList from './UserList';
import BookList from './BookList';
import AuthorList from './AuthorList';
import PromptList from './prompt/PromptList';
import Sidebar from './Sidebar';
import Header from '../common/Header';
import styles from './AdminDashboard.module.css';
import { useAuth } from '../../context/AuthContext';
import DocumentList from '../common/documents/DocumentList';

const AdminDashboard: React.FC = () => {
  const { logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = useCallback(() => {
    logout();
    navigate('/login');
  }, [logout, navigate]);

  const headerButtons = useMemo(() => [{ label: 'Logout', onClick: handleLogout }], [handleLogout]);

  return (
    <div className={styles.adminDashboard}>
      <Header buttons={headerButtons} />
      <div className={styles.dashboardLayout}>
        <Sidebar />
        <div className={styles.dashboardContent}>
          <Routes>
            <Route path="/" element={<Navigate to="stats" />} />
            <Route path="stats" element={<Stats />} />
            <Route path="users" element={<UserList />} />
            <Route path="authors" element={<AuthorList />} />
            <Route path="books" element={<BookList />} />
            <Route path="documents" element={<DocumentList isAdmin={true} />} />
            <Route path="prompts" element={<PromptList />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
