import { useMutation, useQueryClient } from '@tanstack/react-query';
import chatService from 'src/services/chat/chatService';
import { getConversationsQueryKey } from './useGetConversations';

export const useDeleteConversation = (
  authorId: string,
  { onSuccess }: { onSuccess: () => void }
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: chatService.deleteConversation,
    onSuccess,
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: getConversationsQueryKey(authorId),
      });
    },
  });
};
