import { useQuery } from '@tanstack/react-query';
import chatService from './chatService';

export const getConversationHistoryQueryKey = (conversationId: string) => [
  'conversations',
  'history',
  conversationId,
];

export const useGetConversationHistory = (conversationId: string) => {
  return useQuery({
    queryKey: getConversationHistoryQueryKey(conversationId),
    queryFn: () => chatService.getConversationHistory(conversationId),
  });
};
