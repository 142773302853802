// src/utils/debugLogger.ts
import { useEffect, useRef } from 'react';

export const logComponentLoad = (
  componentName: string,
  additionalInfo: Record<string, any> = {}
) => {
  if (process.env.REACT_APP_ENV === 'development' && process.env.LOG_RENDER) {
    console.log(
      `%c[LOAD]: ${componentName}`,
      'color: #4caf50; font-weight: bold;',
      JSON.stringify(additionalInfo)
    );
  }
};

export function useLogRenders(componentName: string) {
  const renderCount = useRef(1);

  useEffect(() => {
    // Log mount only in development
    if (process.env.NODE_ENV === 'development' && process.env.LOG_RENDER) {
      console.log(`${componentName} mounted`);
    }
  }, []);

  useEffect(() => {
    renderCount.current++;
    return () => {
      // Log re-renders only in development
      if (process.env.NODE_ENV === 'development' && process.env.LOG_RENDER) {
        console.log(`${componentName} re-rendered ${renderCount.current} times`);
      }
    };
  });
}

export const sleep = (ms: number): Promise<void> => new Promise(resolve => setTimeout(resolve, ms));
