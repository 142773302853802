/* /src/context/ChatContext.tsx */

import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import chatService, { ChatMessage } from '../services/chat/chatService';
import removeMd from 'remove-markdown';
import { Author, BookQuestion } from '../interfaces/interfaces';
import bookService from '../services/bookService';

interface ChatContextProps {
  messages: ChatMessage[];
  input: string;
  loading: boolean;
  setInput: (input: string) => void;
  sendMessage: (author: Author) => void;
  sendMessageOverGraph: (author: Author) => void;
  clearChat: () => void;
  loadingAudio: number | null;
  playingAudio: number | null;
  audioProgress: { [key: number]: number };
  handleVoiceClick: (message: ChatMessage, index: number) => Promise<void>;
  initialQuestions: BookQuestion[];
  handleQuestionClick: (author: Author, question: string) => void;
}

const ChatContext = createContext<ChatContextProps | undefined>(undefined);

interface ChatProviderProps {
  authorId: string | null | undefined;
  bookId: string | null | undefined;
  children: React.ReactNode;
}

export const ChatProvider: React.FC<ChatProviderProps> = ({ authorId, bookId, children }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingAudio, setLoadingAudio] = useState<number | null>(null);
  const [playingAudio, setPlayingAudio] = useState<number | null>(null);
  const [audioProgress, setAudioProgress] = useState<{ [key: number]: number }>({});
  const audioCache = useRef<{ [key: number]: string }>({});
  const audioElements = useRef<{ [key: number]: HTMLAudioElement }>({});
  const mediaSourceRef = useRef<{ [key: number]: MediaSource }>({});
  const [initialLoad, setInitialLoad] = useState(false);
  const [initialQuestions, setInitialQuestions] = useState<BookQuestion[]>([]);

  useEffect(() => {
    if (!bookId) return;
    const fetchInitialData = async () => {
      const storedMessages = localStorage.getItem(`chatHistory-${bookId}`);
      if (!storedMessages || storedMessages === '[]') {
        try {
          const [questions, messages] = await Promise.all([
            bookService.getBookQuestions(bookId!),
            chatService.fetchHistory(bookId!),
          ]);
          setInitialQuestions(questions);
          setMessages(messages);
        } catch (error) {
          console.error('Failed to fetch initial questions or history messages', error);
        }
      } else {
        setMessages(JSON.parse(storedMessages));
      }
      setInitialLoad(true);
    };

    if (bookId) {
      fetchInitialData();
    }
  }, [bookId]);

  useEffect(() => {
    if (initialLoad && bookId) {
      localStorage.setItem(`chatHistory-${bookId}`, JSON.stringify(messages));
    }
  }, [messages, bookId, initialLoad]);

  const addMessage = (message: ChatMessage) => {
    setMessages(prevMessages => [...prevMessages, message]);
  };

  const sendMessageFromInput = async (author: Author) => {
    const content = input.trim();
    if (content === '') return;
    setInput('');
    await sendMessage(author, content);
  };

  const sendMessageOverGraph = async (author: Author) => {
    const content = input.trim();
    if (content === '') return;
    setInput('');
    await sendMessage(author, content, true);
  };

  async function preloadVoice(message: ChatMessage) {
    const index = messages.length + 1; // +1 for assistant message just added
    const strippedContent = removeMd(message.content, {
      stripListLeaders: false,
      useImgAltText: false,
    });

    try {
      const audioUrl = await chatService.getAudio(strippedContent, authorId!);
      audioCache.current[index] = audioUrl;

      const audio = new Audio(audioUrl);

      audio.oncanplaythrough = () => {
        console.log(`Audio preloaded and buffered at index ${index}`);
      };

      audio.onerror = err => {
        console.error('Error preloading audio:', err);
      };

      audio.load(); // Trigger browser to buffer the audio
      audioElements.current[index] = audio;
    } catch (error) {
      console.error('Failed to preload audio for Cartesia voice:', error);
    }
  }

  const sendMessage = async (author: Author, content: string, isOverGraph: boolean = false) => {
    if (!bookId) return;

    const userMessage: ChatMessage = { role: 'user', content };
    addMessage(userMessage);
    setLoading(true);

    const loaderMessage: ChatMessage = {
      role: 'assistant',
      content: 'Loading...',
      isLoading: true,
    };
    addMessage(loaderMessage);

    try {
      const token = localStorage.getItem('token');
      if (!token) throw new Error('No token found');

      const assistantMessage = await chatService.sendMessage(bookId, content, isOverGraph);
      if (author.voiceProvider === 'cartesia') {
        await preloadVoice(assistantMessage);
      }
      assistantMessage.isLoading = false;
      setMessages(prevMessages =>
        prevMessages.map(msg => (msg.content === 'Loading...' ? assistantMessage : msg))
      );
    } catch (error) {
      console.error('Failed to send message', error);
      setMessages(prevMessages => prevMessages.filter(msg => msg.content !== 'Loading...'));
    } finally {
      setLoading(false);
    }
  };

  async function clearHistory(): Promise<boolean> {
    try {
      if (!bookId) return false;
      const cleared = await chatService.clearHistory(bookId);
      return cleared != null;
    } catch (error) {
      console.error('Failed to clear chat history', error);
      return false;
    }
  }

  const clearChat = async () => {
    if (!bookId) return;

    setMessages([]);
    localStorage.removeItem(`chatHistory-${bookId}`);
    await clearHistory();
    cleanupAudioResources();
    // fetchInitialQuestions();
  };

  // Cleanup all cached URLs and media sources
  const cleanupAudioResources = () => {
    Object.entries(audioCache.current).forEach(([key, url]) => {
      if (url.startsWith('blob:')) {
        URL.revokeObjectURL(url);
        delete audioCache.current[Number(key)];
        delete mediaSourceRef.current[Number(key)];
        delete audioElements.current[Number(key)];
      }
    });
  };

  const fetchInitialQuestions = async () => {
    if (!bookId) return;

    try {
      const questions = await bookService.getBookQuestions(bookId);
      setInitialQuestions(questions);
    } catch (error) {
      console.error('Failed to fetch initial questions', error);
    }
  };

  // Cleanup resources when component unmounts
  useEffect(() => {
    return () => {
      cleanupAudioResources();
    };
  }, []);

  const handleVoiceClick = async (message: ChatMessage, index: number) => {
    // If clicking the currently playing message, just pause it
    console.log('handleVoiceClick', message, index);
    if (playingAudio === index && audioElements.current[index]) {
      audioElements.current[index].pause();
      setPlayingAudio(null);
      return;
    }

    // If another audio is playing, just pause it
    if (playingAudio !== null && audioElements.current[playingAudio]) {
      audioElements.current[playingAudio].pause();
      setPlayingAudio(null);
    }

    setLoadingAudio(index);

    try {
      // Check if we have a cached URL
      let audioUrl = audioCache.current[index];

      // If no cached URL, fetch new audio
      if (!audioUrl) {
        const strippedContent = removeMd(message.content, {
          stripListLeaders: false,
          useImgAltText: false,
        });
        audioUrl = await chatService.getAudio(strippedContent, authorId!);
        audioCache.current[index] = audioUrl;
      }

      // Create new audio element if it doesn't exist
      if (!audioElements.current[index]) {
        const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);

        // Create new audio element
        const audio = new Audio();

        // Set attributes for iOS compatibility
        if (isIOS) {
          audio.preload = 'auto';
          audio.setAttribute('playsinline', 'true');
          audio.setAttribute('webkit-playsinline', 'true');
        }

        // Add event handlers
        audio.onended = () => {
          setPlayingAudio(null);
          setLoadingAudio(null);
          setAudioProgress(prev => ({ ...prev, [index]: 0 }));
        };

        audio.onerror = error => {
          console.error('Audio playback error:', error);
          if (audioCache.current[index]) {
            URL.revokeObjectURL(audioCache.current[index]);
            delete audioCache.current[index];
            delete mediaSourceRef.current[index];
            delete audioElements.current[index];
          }
          setPlayingAudio(null);
          setLoadingAudio(null);
          setAudioProgress(prev => ({ ...prev, [index]: 0 }));
        };

        audio.ontimeupdate = () => {
          const progress = (audio.currentTime / audio.duration) * 100;
          setAudioProgress(prev => ({ ...prev, [index]: progress }));
        };

        // Set source after event handlers are attached
        audio.src = audioUrl;
        audioElements.current[index] = audio;

        // Start loading the audio
        audio.load();
      }

      try {
        await audioElements.current[index].play();
        setPlayingAudio(index);
        setLoadingAudio(null);
      } catch (playError) {
        console.error('Failed to start playback:', playError);
        setPlayingAudio(null);
        setLoadingAudio(null);
        setAudioProgress(prev => ({ ...prev, [index]: 0 }));
      }
    } catch (error) {
      console.error('Failed to play audio', error);
      setPlayingAudio(null);
      setLoadingAudio(null);
      setAudioProgress(prev => ({ ...prev, [index]: 0 }));
    }
  };

  const handleQuestionClick = (author: Author, question: string) => {
    sendMessage(author, question);
    setInitialQuestions([]); // Remove the questions after one is selected
  };

  return (
    <ChatContext.Provider
      value={{
        messages,
        input,
        loading,
        setInput,
        sendMessage: sendMessageFromInput,
        sendMessageOverGraph,
        clearChat,
        loadingAudio,
        playingAudio,
        audioProgress,
        handleVoiceClick,
        initialQuestions,
        handleQuestionClick,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (context === undefined) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
};
