import { useQuery } from '@tanstack/react-query';
import chatService from 'src/services/chat/chatService';

export const getConversationsQueryKey = (authorId: string) => ['conversations', 'list', authorId];

export const useGetConversations = (authorId: string) => {
  return useQuery({
    queryKey: getConversationsQueryKey(authorId),
    queryFn: () => chatService.getConversations(authorId),
  });
};
