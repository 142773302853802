// src/services/authorService.ts
import axios from 'axios';
import { Author, Book, Practice, VoiceSample } from 'src/interfaces/interfaces';
import authTokenService from 'src/services/authTokenService';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('REACT_APP_API_URL is not defined in .env file');
}

const config = () => ({
  headers: {
    Authorization: `Bearer ${authTokenService.getToken()}`,
  },
});

const getAuthors = async () => {
  const response = await axios.get(`${API_URL}/authors/`, config());
  return response.data;
};

const getAuthorProfile = async (authorId: string): Promise<Author> => {
  const response = await axios.get(`${API_URL}/authors/${authorId}`, config());
  return response.data;
};

const updateProfile = async (authorId: string, data: FormData) => {
  const response = await axios.post(`${API_URL}/authors/${authorId}/update-profile`, data, {
    ...config(),
    headers: {
      ...config().headers,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

const getDocuments = async (authorId: string, page: number = 1, pageSize: number = 20) => {
  const response = await axios.get(
    `${API_URL}/authors/${authorId}/documents?page=${page}&pageSize=${pageSize}`,
    config()
  );

  return response.data;
};

const createDocument = async (authorId: string, formData: FormData) => {
  const response = await axios.post(`${API_URL}/authors/${authorId}/documents`, formData, {
    ...config(),
    headers: {
      ...config().headers,
      'Content-Type': 'multipart/form-data',
    },
  });

  return response.data;
};

const updateDocument = async (authorId: string, documentId: string, formData: FormData) => {
  const response = await axios.put(
    `${API_URL}/authors/${authorId}/documents/${documentId}`,
    formData,
    {
      ...config(),
      headers: {
        ...config().headers,
        'Content-Type': 'multipart/form-data',
      },
    }
  );

  return response.data;
};

const deleteDocument = async (authorId: string, documentId: string) => {
  const response = await axios.delete(
    `${API_URL}/authors/${authorId}/documents/${documentId}`,
    config()
  );

  return response.data;
};

const uploadBook = async (authorId: string, data: FormData) => {
  const response = await axios.post(`${API_URL}/authors/${authorId}/upload-book`, data, config());
  return response.data;
};

const getQnA = async (authorId: string) => {
  const response = await axios.get(`${API_URL}/authors/${authorId}/qna`, config());
  return response.data;
};

const submitQnA = async (
  authorId: string,
  data: { qna: { question: string; answer: string }[] }
) => {
  const response = await axios.post(`${API_URL}/authors/${authorId}/qna`, data, config());
  return response.data;
};

const getEvents = async (authorId: string) => {
  const response = await axios.get(`${API_URL}/authors/${authorId}/events`, config());
  return response.data;
};

const submitEvents = async (
  authorId: string,
  data: { event: { eventName: string; eventDate: string; place: string }[] }
) => {
  const response = await axios.post(`${API_URL}/authors/${authorId}/events`, data, config());
  return response.data;
};

const getOfferings = async (authorId: string) => {
  const response = await axios.get(`${API_URL}/authors/${authorId}/offerings`, config());
  return response.data;
};

const submitOfferings = async (
  authorId: string,
  data: { offering: { name: string; action: string }[] }
) => {
  const response = await axios.post(`${API_URL}/authors/${authorId}/offerings`, data, config());
  return response.data;
};

const getPractices = async (authorId: string): Promise<Practice[]> => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/authors/${authorId}/practices`,
    config()
  );

  return response.data;
};

const getGuidelines = async (authorId: string) => {
  const response = await axios.get(`${API_URL}/authors/${authorId}/guidelines`, config());
  return response.data;
};

const saveGuidelines = async (authorId: string, data: any) => {
  const response = await axios.post(
    `${API_URL}/authors/${authorId}/save-guidelines`,
    data,
    config()
  );
  return response.data;
};

const getBooks = async (authorId: string): Promise<Book[]> => {
  const response = await axios.get(`${API_URL}/authors/${authorId}/books`, config());
  return response.data;
};

const getVoiceStatus = async (authorId: string) => {
  const response = await axios.get(`${API_URL}/authors/${authorId}/voice-status`, config());
  return response.data;
};

// Method to get voice samples
const getVoiceSamples = async (authorId: string): Promise<VoiceSample[]> => {
  const response = await axios.get(`${API_URL}/authors/${authorId}/voice-samples`, config());
  return response.data;
};

// Updated uploadVoice method to handle multiple files
const uploadVoice = async (authorId: string, data: FormData) => {
  const requestConfig = config();
  const response = await axios.post(`${API_URL}/authors/${authorId}/upload-voice`, data, {
    ...requestConfig,
    headers: {
      ...requestConfig.headers,
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

const deleteVoiceSample = async (authorId: string, sampleId: string) => {
  const response = await axios.delete(
    `${API_URL}/authors/${authorId}/voice-samples/${sampleId}`,
    config()
  );
  return response.data;
};

// Author Style Management
const getAuthorStyle = async (authorId: string) => {
  const response = await axios.get(`${API_URL}/authors/${authorId}/style`, config());
  return response.data;
};

const deleteAuthorStyle = async (authorId: string, styleId: string) => {
  const response = await axios.delete(`${API_URL}/authors/${authorId}/style/${styleId}`, config());
  return response.data;
};

const updateAuthorStyleById = async (authorId: string, styleId: string, styleData: any) => {
  const response = await axios.put(
    `${API_URL}/authors/${authorId}/style/${styleId}`,
    styleData,
    config()
  );
  return response.data;
};

const saveVoiceConfig = async (
  authorId: string,
  data: { voiceProvider: string; voiceId: string; voiceSettings: any }
) => {
  const response = await axios.put(`${API_URL}/authors/${authorId}/voice-config`, data, config());
  return response.data;
};

const authorService = {
  getAuthorProfile,
  updateProfile,
  getDocuments,
  createDocument,
  updateDocument,
  deleteDocument,
  uploadBook,
  getQnA,
  submitQnA,
  getEvents,
  submitEvents,
  getOfferings,
  submitOfferings,
  getPractices,
  getGuidelines,
  saveGuidelines,
  getBooks,
  getAuthors,
  getVoiceStatus,
  getVoiceSamples,
  uploadVoice,
  deleteVoiceSample,
  getAuthorStyle,
  deleteAuthorStyle,
  updateAuthorStyleById,
  saveVoiceConfig,
};

export default authorService;
