import { Prompt, PromptState } from '../../interfaces/interfaces';
import apiClient from '../api/apiClient';

const getPrompts = async (
  page: number = 1,
  pageSize: number = 10,
  type?: string,
  state?: PromptState,
  search?: string
) => {
  let query = `?page=${page}&pageSize=${pageSize}`;
  if (type) query += `&type=${type}`;
  if (state) query += `&state=${state}`;
  if (search) query += `&search=${search}`;

  const response = await apiClient.get(`/prompts${query}`);
  return response.data;
};

const getPrompt = async (id: string) => {
  const response = await apiClient.get(`/prompts/${id}`);
  return response.data;
};

const getPromptByName = async (name: string, publishedOnly: boolean = false) => {
  const state = publishedOnly ? '?state=published' : '';
  const response = await apiClient.get(`/prompts/name/${name}${state}`);
  return response.data;
};

const getPromptVersions = async (name: string) => {
  const response = await apiClient.get(`/prompts/versions/${name}`);
  return response.data;
};

const createPrompt = async (prompt: Partial<Prompt>) => {
  const response = await apiClient.post(`/prompts`, prompt);
  return response.data;
};

const updatePrompt = async (id: string, prompt: Partial<Prompt>) => {
  const response = await apiClient.put(`/prompts/${id}`, prompt);
  return response.data;
};

const updatePromptState = async (id: string, state: PromptState) => {
  const response = await apiClient.patch(`/prompts/${id}/state`, { state });
  return response.data;
};

const deletePrompt = async (id: string) => {
  const response = await apiClient.delete(`/prompts/${id}`);
  return response.data;
};

/**
 * Restores an archived prompt back to draft state
 * @param id The ID of the prompt to restore
 */
const restorePrompt = async (id: string) => {
  const response = await apiClient.post(`/prompts/${id}/restore`);
  return response.data;
};

export default {
  getPrompts,
  getPrompt,
  getPromptByName,
  getPromptVersions,
  createPrompt,
  updatePrompt,
  updatePromptState,
  deletePrompt,
  restorePrompt,
};
