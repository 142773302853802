import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import promptService from './promptService';
import { Prompt, PromptState } from '../../interfaces/interfaces';

export const useCreatePrompt = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (promptData: Partial<Prompt>) => promptService.createPrompt(promptData),
    onSuccess: () => {
      // Invalidate prompts queries to refetch the list
      queryClient.invalidateQueries({ queryKey: ['prompts'] });
    },
  });
};

export const useGetPrompt = (id: string) => {
  return useQuery({
    queryKey: ['prompt', id],
    queryFn: () => promptService.getPrompt(id),
    enabled: !!id, // Only run the query if id is provided
  });
};

export const useGetPrompts = (
  page: number = 1,
  pageSize: number = 10,
  type?: string,
  state?: PromptState,
  search?: string
) => {
  return useQuery({
    queryKey: ['prompts', page, pageSize, type, state, search],
    queryFn: () => promptService.getPrompts(page, pageSize, type, state, search),
  });
};

export const useGetPromptVersions = (promptName: string) => {
  return useQuery({
    queryKey: ['prompt-versions', promptName],
    queryFn: () => promptService.getPromptVersions(promptName),
    enabled: !!promptName, // Only run query if promptName is provided
  });
};

export const useRestorePrompt = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: { id: string; promptName: string }) => {
      const { id } = payload;
      // Fetch the prompt details first to get the name
      const result = await promptService.restorePrompt(id);
      return { ...result, promptName: payload.promptName };
    },
    onSuccess: async (data, variables) => {
      const { promptName } = variables;

      // Invalidate all related queries
      queryClient.invalidateQueries({ queryKey: ['prompts'] });
      queryClient.invalidateQueries({ queryKey: ['prompt', variables.id] });

      // Also invalidate the version history for this prompt name
      if (promptName) {
        queryClient.invalidateQueries({ queryKey: ['prompt-versions', promptName] });
      }

      // Force refetch after a short delay to ensure backend changes are complete
      setTimeout(async () => {
        await queryClient.refetchQueries({ queryKey: ['prompts'] });

        // Also refetch the version history if we have a prompt name
        if (promptName) {
          await queryClient.refetchQueries({ queryKey: ['prompt-versions', promptName] });
        }
      }, 500);
    },
  });
};

interface UpdatePromptParams {
  id: string;
  promptData: Partial<Prompt>;
}

export const useUpdatePrompt = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, promptData }: UpdatePromptParams) => {
      return promptService.updatePrompt(id, promptData);
    },
    onSuccess: async data => {
      // Invalidate the general prompts list
      queryClient.invalidateQueries({ queryKey: ['prompts'] });

      // Invalidate the specific prompt by ID
      queryClient.invalidateQueries({ queryKey: ['prompt', data.id] });

      // Important: Invalidate all versions of this prompt by name
      queryClient.invalidateQueries({ queryKey: ['promptVersions', data.name] });

      // Force refetch prompts to ensure both published and draft versions show up
      await queryClient.refetchQueries({ queryKey: ['prompts'] });

      // If editing a published/active prompt creates a new draft,
      // we need to manually add it to the cache until the next fetch
      const existingPrompts = queryClient.getQueryData<{ prompts: Prompt[] }>(['prompts']);
      if (existingPrompts?.prompts) {
        // Update the cache with the new version
        queryClient.setQueryData(['prompts'], {
          ...existingPrompts,
          prompts: [...existingPrompts.prompts, data],
        });
      }
    },
    onError: (error: any) => {
      // Get the specific error message if available
      const errorMessage =
        error.response?.data?.message ||
        (error.message?.includes('ARCHIVED') ? error.message : 'Failed to update prompt');

      // Show error notification
      console.error('Error updating prompt:', errorMessage);
      // If using a notification library, you could call it here
      // toast.error(errorMessage);

      throw error;
    },
  });
};

interface UpdatePromptStateParams {
  id: string;
  state: PromptState;
}

export const useUpdatePromptState = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ id, state }: UpdatePromptStateParams) => {
      return promptService.updatePromptState(id, state);
    },
    onSuccess: async data => {
      // Invalidate all related queries
      queryClient.invalidateQueries({ queryKey: ['prompts'] });
      queryClient.invalidateQueries({ queryKey: ['prompt', data.id] });
      queryClient.invalidateQueries({ queryKey: ['promptVersions', data.name] });

      // Force refetch to ensure UI is updated
      setTimeout(async () => {
        await queryClient.refetchQueries({ queryKey: ['prompts'] });
      }, 1000);
    },
  });
};
