import React, { useEffect, useState } from 'react';
import adminService from '../../services/adminService';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import styles from './Graph.module.css';
import { ChartOptions } from 'chart.js';

const UserGraph: React.FC = () => {
  const [totalUsers, setTotalUsers] = useState(0);
  const [usersPerDay, setUsersPerDay] = useState<{ date: string; count: number }[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      const { totalUsers, usersPerDay } = await adminService.getUserStats();
      setTotalUsers(totalUsers);
      setUsersPerDay(usersPerDay);
    };

    fetchData();
  }, []);

  const maxYValue = Math.max(...usersPerDay.map(item => item.count)) * 1.2;

  const data = {
    labels: usersPerDay.map(item => item.date),
    datasets: [
      {
        label: 'Users per Day',
        data: usersPerDay.map(item => item.count),
        fill: false,
        backgroundColor: 'rgb(75, 192, 192)',
        borderColor: 'rgba(75, 192, 192, 0.2)',
        tension: 0.4, // Smooth curve
      },
    ],
  };

  const options: ChartOptions<'line'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          color: '#1E3742',
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        suggestedMax: maxYValue,
        ticks: {
          color: '#1E3742',
        },
        grid: {
          color: '#E3E5DC',
        },
      },
      x: {
        ticks: {
          color: '#1E3742',
        },
        grid: {
          color: '#E3E5DC',
        },
      },
    },
  };

  return (
    <div className={styles.graphContainer}>
      <h3 className={styles.graphTitle}>Total Users: {totalUsers}</h3>
      <div className={styles.chartWrapper}>
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

export default UserGraph;
