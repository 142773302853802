import React, { useState, useEffect } from 'react';
import promptOverrideService from '../../../services/promptOverrideService';
import { AlertCircle, X } from 'lucide-react';

interface PromptOverrideIndicatorProps {
  className?: string;
}

const PromptOverrideIndicator: React.FC<PromptOverrideIndicatorProps> = ({ className = '' }) => {
  const [overrides, setOverrides] = useState<any[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  // Update the overrides whenever localStorage changes
  useEffect(() => {
    const updateOverrides = () => {
      setOverrides(promptOverrideService.getOverrides());
    };

    // Initial load
    updateOverrides();

    // Add event listener for storage changes
    window.addEventListener('storage', updateOverrides);

    // Custom event for when we modify overrides programmatically
    window.addEventListener('prompt-overrides-changed', updateOverrides);

    return () => {
      window.removeEventListener('storage', updateOverrides);
      window.removeEventListener('prompt-overrides-changed', updateOverrides);
    };
  }, []);

  const handleClearAll = () => {
    promptOverrideService.clearAllOverrides();
    setOverrides([]);
    // Dispatch custom event to notify other components
    window.dispatchEvent(new Event('prompt-overrides-changed'));
  };

  const handleRemoveOverride = (promptName: string) => {
    promptOverrideService.removeOverride(promptName);
    setOverrides(promptOverrideService.getOverrides());
    // Dispatch custom event to notify other components
    window.dispatchEvent(new Event('prompt-overrides-changed'));
  };

  if (overrides.length === 0) {
    return null;
  }

  return (
    <div className={`fixed bottom-4 right-4 z-50 ${className}`}>
      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 rounded shadow-lg">
        <div className="flex justify-between items-start">
          <div className="flex items-center">
            <AlertCircle className="h-5 w-5 mr-2" />
            <span className="font-bold">
              {overrides.length} Prompt{overrides.length !== 1 ? 's' : ''} Overridden
            </span>
          </div>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="ml-4 text-xs underline hover:text-yellow-800"
          >
            {isExpanded ? 'Hide details' : 'Show details'}
          </button>
        </div>

        {isExpanded && (
          <div className="mt-3">
            <ul className="space-y-2">
              {overrides.map(override => (
                <li key={override.promptName} className="flex justify-between items-center">
                  <div className="text-sm">
                    <span className="font-medium">{override.promptName}</span>:{' '}
                    <span>
                      v{override.majorVersion}.{override.minorVersion}
                    </span>
                  </div>
                  <button
                    onClick={() => handleRemoveOverride(override.promptName)}
                    className="text-yellow-700 hover:text-yellow-900"
                  >
                    <X className="h-4 w-4" />
                  </button>
                </li>
              ))}
            </ul>
            <div className="mt-3 pt-2 border-t border-yellow-300">
              <button
                onClick={handleClearAll}
                className="text-xs font-medium text-yellow-800 hover:text-yellow-900"
              >
                Clear all overrides
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PromptOverrideIndicator;
