//client/src/utils/analytics.ts
import ReactGA from 'react-ga4';
import { useCallback } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { getCachedUser, getUserID } from 'src/utils/userIdentifier';
import { Identify } from '@amplitude/analytics-browser';

const isProduction = process.env.REACT_APP_ENV === 'production';

export const initializeGA = (measurementId: string) => {
  if (isProduction && measurementId) {
    ReactGA.initialize(measurementId);
    ReactGA.send('pageview');
  }
};

export const initializeAmplitude = () => {
  if (!isProduction) {
    console.log('Non-production mode. Amplitude will not be initialized.');
    return;
  }

  const APLTITUDE_API_KEY = process.env.REACT_APP_AMPLITUDE_CLIENT_KEY;
  if (!APLTITUDE_API_KEY) {
    throw new Error('APLTITUDE_API_KEY is not initialized!');
  }

  const sessionReplayTracking = sessionReplayPlugin({ sampleRate: 1 });
  amplitude.add(sessionReplayTracking);

  const user = getCachedUser();

  const userId = user?.id || getUserID();
  amplitude.init(APLTITUDE_API_KEY, userId, { autocapture: true });
  if (user) {
    const identify = new amplitude.Identify();
    identify.set('email', user.email);
    identify.set('name', user.name);
    identify.set('lastName', user.lastName);
    amplitude.identify(identify);
  }
};

export const trackPageView = (path: string) => {
  if (isProduction) {
    ReactGA.send({ hitType: 'pageview', page: path });
  }
};

export const trackEvent = (category: string, action: string, label?: string, value?: number) => {
  if (isProduction) {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  }
};

export const useLogEvent = () => {
  return (eventName: string, value: string, metadata: Record<string, any>) => {
    const eventProperties = {
      value,
      ...metadata,
    };

    if (isProduction) {
      amplitude.track(eventName, eventProperties);
    } else {
      console.log(`Analytics Event: ${eventName}`, eventProperties);
    }
  };
};

export const useSetUserProperties = () => {
  return useCallback((userId: string, properties: Record<string, any>) => {
    if (!isProduction) {
      console.log('Non-production mode. User properties will not be set.');
      return;
    }

    console.log('Setting user ID and properties with Amplitude:', { userId, properties });

    amplitude.setUserId(userId);

    const identifyObj = new Identify();
    Object.entries(properties).forEach(([key, value]) => {
      identifyObj.set(key, value);
    });

    amplitude.identify(identifyObj);
  }, []);
};
