//client/src/components/common/EntityDropdown.tsx
import React, { useCallback, useState } from 'react';
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';
import adminService from '../../services/adminService';
import { Author, User } from '../../interfaces/interfaces';

interface EntityDropdownProps {
  entityType: 'users' | 'authors';
  selectedEntityId?: string | null;
  selectedEntityLabel?: string | null;
  onChange: (entityId: string | null) => void;
}

interface SelectOption {
  value: string;
  label: string;
}

const PAGE_SIZE = 10;

/**
 * Helper to build label for Users vs. Authors.
 */
function buildLabel(entity: Author | User, entityType: 'users' | 'authors'): string {
  if (entityType === 'users') {
    const user = entity as User;
    return `${user.name} ${user.lastName} ${user.email}`;
  } else {
    const author = entity as Author;
    return author.name;
  }
}

const EntityDropdown: React.FC<EntityDropdownProps> = ({
  entityType,
  selectedEntityId,
  selectedEntityLabel,
  onChange,
}) => {
  // If you want to show a pre-selected label, you could track that in local state,
  // but react-select-async-paginate can handle it by receiving the correct "value" prop below.

  // Transform the "selectedEntityId" into the shape that AsyncPaginate expects
  const [selectedOption, setSelectedOption] = useState<SelectOption | null>(() =>
    selectedEntityId && selectedEntityLabel
      ? { value: selectedEntityId, label: selectedEntityLabel }
      : null
  );

  const loadOptions = useCallback(
    async (inputValue: any, prevOptions: any, additional: any) => {
      // "page" is stored in "additional" so we know which page to fetch next
      const page = additional?.page || 1;

      // Decide which endpoint to call (users vs authors)
      let data: { users?: User[]; authors?: Author[]; totalCount: number };
      if (entityType === 'users') {
        // { users: User[], totalCount: number }
        data = await adminService.getUsers(page, PAGE_SIZE, inputValue);
      } else {
        // { authors: Author[], totalCount: number }
        data = await adminService.getAuthors(page, PAGE_SIZE, inputValue);
      }

      // The returned array of entities
      const entities = entityType === 'users' ? (data.users ?? []) : (data.authors ?? []);

      // Convert to the format expected by react-select
      const newOptions = entities.map(ent => ({
        value: ent.id,
        label: buildLabel(ent, entityType),
      }));

      const hasMore = page * PAGE_SIZE < data.totalCount;

      return {
        options: newOptions,
        hasMore,
        additional: {
          page: page + 1,
        },
      };
    },
    [entityType]
  );

  /**
   * When user selects or clears an option, update local and parent state.
   */
  const handleChange = (option: SelectOption | null) => {
    setSelectedOption(option);
    onChange(option?.value || null);
  };

  return (
    <AsyncPaginate
      value={selectedOption}
      loadOptions={loadOptions}
      onChange={handleChange}
      additional={{ page: 1 }}
      defaultAdditional={{ page: 1 }}
      placeholder={`Search for ${entityType === 'users' ? 'Users' : 'Authors'}...`}
      isClearable
    />
  );
};

export default EntityDropdown;
