import { Dialog, DialogContent, DialogHeader, DialogTrigger } from 'src/components/ui/dialog';
import { Button } from 'src/components/ui/button';
import { FC } from 'react';
import { useGetAuthorProfile } from 'src/services/author/useGetAuthorProfile';
import AuthorProfileContent from 'src/components/authorChat/AuthorProfileContent';

interface Props {
  authorId: string;
}

const AuthorProfileDialog: FC<Props> = ({ authorId }) => {
  const { data } = useGetAuthorProfile(authorId);

  return (
    <Dialog>
      <DialogTrigger>
        <Button className="font-[600] hidden md:inline-flex" variant="secondary">
          About {data?.name}
        </Button>
      </DialogTrigger>
      <DialogContent
        className="h-[calc(100%_-_72px)] overflow-auto top-6 m-auto right-6 bg-background-light shadow-xl"
        withOverlay={false}
        variant="info"
      >
        <DialogHeader className="mt-6">
          <AuthorProfileContent authorId={authorId} />
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
};

export default AuthorProfileDialog;
