import React, { useEffect, useState } from 'react';
import { Author, User } from '../../interfaces/interfaces';
import adminService from '../../services/adminService';
import styles from './AuthorModal.module.css';
import EntityDropdown from '../common/EntityDropdown';

interface AuthorModalProps {
  author: Author | null;
  onClose: () => void;
  onSave: (author: Partial<Author>) => void;
}

const ELEVENLABS_DEFAULT_SETTINGS = {
  stability: 0.3,
  similarity_boost: 1,
  style: 0.3,
  use_speaker_boost: true,
  speed: 1.0,
};

const AuthorModal: React.FC<AuthorModalProps> = ({ author, onClose, onSave }) => {
  const [formState, setFormState] = useState<Partial<Author>>({
    name: author?.name || '',
    biography: author?.biography || '',
    title: author?.title || '',
    location: author?.location || '',
    userId: author?.userId || null,
    voiceId: author?.voiceId || null,
    user: author?.user || undefined,
    voiceProvider: author?.voiceProvider || 'eleven',
  });

  const defaultVoiceSettings = JSON.stringify(ELEVENLABS_DEFAULT_SETTINGS, null, 2);

  const [voiceSettingsJson, setVoiceSettingsJson] = useState(() => {
    return author?.voiceSettings
      ? JSON.stringify(author.voiceSettings, null, 2)
      : defaultVoiceSettings;
  });

  const [users, setUsers] = useState<User[]>([]);
  const [userSearch, setUserSearch] = useState('');
  const [userPage, setUserPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);

  const fetchUsers = async () => {
    const { users, totalCount } = await adminService.getUsers(userPage, 10, userSearch);
    setUsers(users);
    setTotalUsers(totalCount);
  };

  useEffect(() => {
    fetchUsers();
  }, [userPage, userSearch]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormState(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let parsedVoiceSettings = undefined;

    if (voiceSettingsJson.trim()) {
      try {
        parsedVoiceSettings = JSON.parse(voiceSettingsJson);
      } catch (err) {
        alert('Invalid JSON in voice settings');
        return;
      }
    }

    // Only save if settings differ from default
    const shouldSaveVoiceSettings =
      JSON.stringify(parsedVoiceSettings) !== JSON.stringify(ELEVENLABS_DEFAULT_SETTINGS);

    onSave({
      ...formState,
      voiceSettings: shouldSaveVoiceSettings ? parsedVoiceSettings : undefined,
    });
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modal}>
        <h2>{author ? 'Edit Author' : 'Create Author'}</h2>
        {author && (
          <div className={styles.authorId}>
            <strong>Author ID:</strong> {author?.id}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <label>
            Name
            <input
              type="text"
              name="name"
              value={formState.name}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Title
            <input type="text" name="title" value={formState.title || ''} onChange={handleChange} />
          </label>
          <label>
            Biography
            <textarea name="biography" value={formState.biography || ''} onChange={handleChange} />
          </label>
          <label>
            Associated User
            <EntityDropdown
              entityType="users"
              selectedEntityId={formState.userId || null}
              selectedEntityLabel={
                `${formState.user?.name} ${formState.user?.lastName} ${formState.user?.email}` || ''
              }
              onChange={userId => setFormState(prev => ({ ...prev, userId }))}
            />
          </label>
          <label>
            Voice Provider
            <select
              name="voiceProvider"
              value={formState.voiceProvider || 'eleven'}
              onChange={handleChange}
            >
              <option value="eleven">Eleven Labs</option>
              <option value="cartesia">Cartesia</option>
            </select>
          </label>
          <label>
            Voice ID
            <input
              type="text"
              name="voiceId"
              value={formState.voiceId || ''}
              onChange={handleChange}
            />
          </label>
          <label>
            Voice Settings (JSON)
            <textarea
              rows={6}
              name="voiceSettings"
              value={voiceSettingsJson}
              onChange={e => setVoiceSettingsJson(e.target.value)}
              placeholder='{"stability": 0.3, "similarity_boost": 1, "style": 0.3}'
            />
          </label>
          {author && (
            <div>
              <p>Created At: {author.createdAt}</p>
              <p>Updated At: {author.updatedAt}</p>
            </div>
          )}
          <div className={styles.actions}>
            <button type="submit" className={styles.saveButton}>
              Save
            </button>
            <button type="button" onClick={onClose} className={styles.cancelButton}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AuthorModal;
