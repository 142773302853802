import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import chatService from './chatService';

export const useGetAudio = (authorId: string) => {
  return useMutation<string, Error, string>({
    mutationFn: (text: string) => chatService.getAudio(text, authorId),
    onError: () => {
      toast.error('Failed to generate audio');
    },
  });
};
