import React, { useEffect, useRef, useState } from 'react';
import styles from './LandingAuthors.module.css';

import { ReactComponent as EchoLogo } from '../../assets/author-landing/logo.svg';
import echoIcon from '../../assets/author-landing/echo-icon.svg';
import LandingAuthorSteps from './LandingSteps';
import LandingForm from './LandingForm';

interface CardRefType extends HTMLDivElement {}

const LandingAuthors: React.FC = () => {
  const formRef = useRef<HTMLDivElement>(null);
  const cardRefs = useRef<Array<CardRefType | null>>([]);
  const [allCardsVisible, setAllCardsVisible] = useState<boolean>(false);

  useEffect(() => {
    const observerOptions: IntersectionObserverInit = { threshold: 0.5 };

    const observerCallback: IntersectionObserverCallback = entries => {
      const allVisible = entries.every(entry => entry.isIntersecting);
      if (allVisible) {
        setAllCardsVisible(true);
        observer.disconnect(); // Stop observing once all cards are visible
      }
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    // Attach observer to each card
    cardRefs.current.forEach(card => {
      if (card) observer.observe(card);
    });

    // Cleanup the observer on component unmount
    return () => observer.disconnect();
  }, []);

  const handleScrollToForm = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.noiseOverlay}></div>

      <div className={styles.headerSection}>
        <div className={styles.treeLeafsContainer}>
          <img
            src={require('../../assets/author-landing/tree-leafs-top.webp')}
            className={styles.treeTopImage}
            alt="Tree"
          />
        </div>
        <div className={styles.header}>
          <EchoLogo className={styles.logo} />
          <div className={styles.contactContainer}>
            <a href="mailto:support@echoyourwisdom.com" className={styles.contact}>
              Contact
            </a>
            <div className={styles.reserveSpot} onClick={handleScrollToForm}>
              Reserve Your Spot
            </div>
          </div>
        </div>

        <div className={styles.topTitleContainer}>
          <h1 className={styles.topTitle}>
            Bring your <i>wisdom</i> to life
          </h1>
          <div className={styles.topSubtitle}>
            Transform your expertise into an interactive experience, amplify your impact, and unlock
            new revenue streams with your Author Echo
          </div>
          <div className={styles.topReserveYourSpot} onClick={handleScrollToForm}>
            Reserve Your Spot
          </div>
        </div>
      </div>

      <div className={styles.heroImageSection}>
        <div className={styles.treeImageContainer}>
          <img
            src={require('../../assets/author-landing/tree-image.webp')}
            className={styles.treeImage}
            alt="Tree"
          />
        </div>
        <div className={styles.phoneContainer}>
          <img
            src={require('../../assets/author-landing/phone.png')}
            className={styles.phoneImage}
            alt="Phone"
          />
        </div>
      </div>

      <div
        ref={el => {
          if (el) cardRefs.current[0] = el;
        }}
        className={`${styles.readerCardTopContainer} ${allCardsVisible ? styles.cardAnimation1 : ''} ${styles.delay1}`}
      >
        <div className={styles.cardHeader}>
          <div className={styles.readerCardImage}></div>
          <div className={styles.readerCardChip}>Reader</div>
        </div>
        <div className={styles.readerCardText}>
          I want to understand how your ‘work smarter’ framework applies to me
        </div>
      </div>

      <div
        ref={el => {
          if (el) cardRefs.current[1] = el;
        }}
        className={`${styles.readerCardBottomContainer} ${allCardsVisible ? styles.cardAnimation3 : ''} ${styles.delay3}`}
      >
        <div className={styles.cardHeader}>
          <div className={styles.readerCardImage}></div>
          <div className={styles.readerCardChip}>Reader</div>
        </div>
        <div className={styles.readerCardText}>Specifically...</div>
      </div>

      <div
        ref={el => {
          if (el) cardRefs.current[2] = el;
        }}
        className={`${styles.authorCardContainer} ${allCardsVisible ? styles.cardAnimation2 : ''} ${styles.delay2}`}
      >
        <div className={styles.cardHeader}>
          <div className={styles.authorCardImage}></div>
          <div className={styles.authorCardChip}>
            <img src={echoIcon} alt="Echo Icon" />
            Mary’s Echo
          </div>
        </div>
        <div className={styles.authorCardText}>What part of the framework did you like?...</div>
      </div>

      <div className={styles.middleSectionContainer}>
        <div className={styles.midSectionTitle}>
          Create your <span className={styles.midSectionEcho}>Echo</span> in three simple steps:
        </div>
        <LandingAuthorSteps />
        <div
          className={styles.topReserveYourSpot}
          style={{ marginTop: 0 }}
          onClick={handleScrollToForm}
        >
          Reserve Your Spot
        </div>
      </div>

      <div className={styles.leafsAndTextContainer}>
        <div className={styles.leftLeafs} />
        <div className={styles.textInTheMiddle}>
          We are now partnering with a select group of authors to join us on this journey. As part
          of this exclusive community, you’ll gain free access and help shape the future of how
          authors connect with their readers
        </div>
        <div className={styles.rightLeafs} />
      </div>

      <div className={styles.bottomPageContainer}>
        <img
          src={require('../../assets/author-landing/bottomTreeImage.webp')}
          className={styles.treeImage}
          data-screen="large"
          alt="Tree"
        />
        <img
          src={require('../../assets/author-landing/tree-image.webp')}
          className={styles.treeImage}
          data-screen="small"
          alt="Tree"
        />
        <div ref={formRef} className={styles.formWrapper}>
          <LandingForm />
        </div>

        <div className={styles.footer}>
          <EchoLogo className={styles.logo} style={{ color: '#FCFCF9' }} />
          <div className={styles.contactContainer}>
            <a
              href="mailto:support@echoyourwisdom.com"
              className={styles.contact}
              style={{ color: '#FCFCF9' }}
            >
              Contact Us
            </a>
            <div className={styles.contact} style={{ color: '#FCFCF9' }}>
              Made Wisely
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingAuthors;
