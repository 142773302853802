import { FC } from 'react';
import { Typography } from '../ui/typography';
import { Pause } from 'lucide-react';
import { Button } from '../ui/button';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { cn } from 'src/lib/utils';
import SoundWave from '../common/icons/SoundWave';
import { useAnimatedText } from 'src/hooks/useAnimatedText';
import { Skeleton } from '../ui/skeleton';

interface ChatEchoMessageProps {
  message: string;
  animate?: boolean;
  onVoiceClick?: () => void;
  isPlaying?: boolean;
  isLoading?: boolean;
  isContentLoading?: boolean;
  hideExtras?: boolean;
  audioProgress?: number;
  authorPhotoEcho?: string;
  authorInitials?: string;
}

export const ChatEchoMessage: FC<ChatEchoMessageProps> = ({
  message,
  animate = false,
  onVoiceClick,
  isPlaying,
  isLoading,
  isContentLoading,
  hideExtras = false,
  audioProgress,
  authorPhotoEcho,
  authorInitials,
}) => {
  const animatedText = useAnimatedText(message);
  const displayText = animate ? animatedText : message;

  return (
    <div className="group flex flex-col gap-6 relative overflow-hidden">
      {isContentLoading ? (
        <Skeleton className="h-4 w-full rounded-md" />
      ) : (
        <Typography variant="crimson" className="text-lg leading-normal">
          {displayText}
        </Typography>
      )}
      {hideExtras ? (
        <div className="absolute left-0 right-0 bottom-0 h-24 opacity-80 bg-gradient-to-t from-background-light to-transparent pointer-events-none z-10"></div>
      ) : (
        <div className="flex flex-row gap-6 items-center">
          {authorPhotoEcho && (
            <Avatar className="size-[42px] border-4 border-background-light box-content">
              <AvatarImage src={authorPhotoEcho} className="object-cover" />
              <AvatarFallback>{authorInitials || 'A'}</AvatarFallback>
            </Avatar>
          )}
          <Button
            className={cn(
              'w-fit transition-opacity duration-300',
              isPlaying ? 'opacity-100' : 'opacity-0 group-hover:opacity-100'
            )}
            variant="outline"
            size="sm"
            onClick={onVoiceClick}
            disabled={isLoading}
            loading={isLoading}
          >
            {isLoading ? (
              <>Read Aloud</>
            ) : isPlaying ? (
              <>
                <Pause />
                <div className="w-16 h-2 bg-gray-300 ml-2">
                  <div className="h-full bg-blue-500" style={{ width: `${audioProgress}%` }}></div>
                </div>
              </>
            ) : (
              <>
                <SoundWave /> Read Aloud
              </>
            )}
          </Button>
        </div>
      )}
    </div>
  );
};
