import type { BlockquoteHTMLAttributes, HTMLAttributes, ReactNode } from 'react';
import { cva, VariantProps } from 'class-variance-authority';
import { cn } from 'src/lib/utils';

interface TypographyProps<T extends HTMLElement> extends HTMLAttributes<T> {
  children?: ReactNode;
}

export function H1({ children, className, ...props }: HTMLAttributes<HTMLHeadingElement>) {
  return (
    <h1
      className={cn(
        'font-crimson font-extrabold text-foreground-light inline-block align-top tracking-[-0.4px] text-4xl leading-[40px] md:text-5xl md:leading-[48px]',
        className
      )}
      {...props}
    >
      {children}
    </h1>
  );
}

export function H2({ children, className, ...props }: TypographyProps<HTMLHeadingElement>) {
  return (
    <h2
      className={cn(
        'font-crimson font-semibold text-3xl text-foreground-light leading-[36px] inline-block align-top tracking-[-0.4px]',
        className
      )}
      {...props}
    >
      {children}
    </h2>
  );
}

export function H3({ children, className, ...props }: TypographyProps<HTMLHeadingElement>) {
  return (
    <h3
      className={cn(
        'font-crimson font-semibold text-2xl text-foreground-light leading-[32px] inline-block align-top tracking-[-0.4px]',
        className
      )}
      {...props}
    >
      {children}
    </h3>
  );
}

export function H4({ children, className, ...props }: TypographyProps<HTMLHeadingElement>) {
  return (
    <h4
      className={cn(
        'font-crimson font-semibold text-xl text-foreground-light leading-[28px] inline-block align-top tracking-[-0.4px]',
        className
      )}
      {...props}
    >
      {children}
    </h4>
  );
}

const paragraphVariants = cva('font-grotesk align-top', {
  variants: {
    variant: {
      default: 'font-normal text-foreground-light',
      lead: 'text-muted-foreground',
      muted: 'font-medium text-muted-foreground',
      'muted-light': 'text-muted-foreground-light',
      crimson: 'font-crimson',
    },
    size: {
      default: 'text-base leading-[28px]',
      xs: 'font-medium text-xs',
      sm: 'text-sm leading-[14px]',
      lg: 'font-semibold text-lg',
      xl: 'text-xl leading-[28px]',
    },
  },
  defaultVariants: {
    variant: 'default',
    size: 'default',
  },
});

export interface ParagraphProps
  extends HTMLAttributes<HTMLParagraphElement>,
    VariantProps<typeof paragraphVariants> {}

export function Typography({ children, variant, size, className, ...props }: ParagraphProps) {
  return (
    <p className={cn(paragraphVariants({ variant, size }), className)} {...props}>
      {children}
    </p>
  );
}

export function Blockquote({
  children,
  className,
  ...props
}: BlockquoteHTMLAttributes<HTMLQuoteElement>) {
  return (
    <blockquote
      className={cn(
        'pl-6 border-l-2 text-base font-grotesk italic font-normal leading-[24px]',
        className
      )}
      {...props}
    >
      {children}
    </blockquote>
  );
}

export function List({ children, className, ...props }: HTMLAttributes<HTMLUListElement>) {
  return (
    <ul className={cn('ml-6 list-[square] [&>li]:mt-2', className)} {...props}>
      {children}
    </ul>
  );
}

export function InlineCode({ children, className, ...props }: TypographyProps<HTMLSpanElement>) {
  return (
    <code
      className={cn(
        'relative rounded bg-muted-light px-1 py-1 font-[Menlo] text-base font-semibold w-fit',
        className
      )}
      {...props}
    >
      {children}
    </code>
  );
}
