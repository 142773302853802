import { useRef } from 'react';
import WelcomeContent from 'src/components/authorChat/WelcomeContent';
import PracticesList from 'src/components/authorChat/PracticesList';
import { ChatInput } from 'src/components/authorChat/ChatInput';
import { useState } from 'react';
import { useGetAuthorProfile } from 'src/services/author/useGetAuthorProfile';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';
import { useCreateConversation } from 'src/services/chat/useCreateConversation';

const AuthorContent = () => {
  const { authorId } = useParams();
  const navigate = useNavigate();
  if (!authorId) throw new Error('Author ID is missing.');

  const [input, setInput] = useState('');
  const pendingMessageRef = useRef('');
  const { user } = useAuth();

  const { data: author, isLoading } = useGetAuthorProfile(authorId);

  const { mutate: createConversation } = useCreateConversation(authorId, {
    onSuccess: createdConversation => {
      navigate(`/author/${authorId}/chat/v2/${createdConversation.id}`, {
        state: { pendingMessage: pendingMessageRef.current },
      });
    },
  });

  const handleSend = () => {
    if (!input.trim()) return;

    pendingMessageRef.current = input;
    setInput('');

    createConversation({
      message: pendingMessageRef.current,
      authorId,
    });
  };

  return (
    <>
      {isLoading && (
        <div className="flex justify-center items-center py-10">
          <div className="w-10 h-10 border-4 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
        </div>
      )}
      {!isLoading && author && (
        <div className="flex flex-col h-full">
          <div className="flex-1 overflow-y-auto">
            <div className="max-w-[750px] mx-auto px-5 md:px-3">
              <WelcomeContent
                authorId={authorId}
                authorName={author.name}
                authorEchoPhoto={author.photoEcho}
                authorWelcomeMessage={author.welcomeMessage}
                userName={user?.name}
              />
              <PracticesList authorId={author.id} setMessage={setInput} />
            </div>
          </div>
          <ChatInput
            authorName={author.name.split(' ')[0]}
            message={input}
            setMessage={setInput}
            onSend={handleSend}
          />
        </div>
      )}
    </>
  );
};

export default AuthorContent;
