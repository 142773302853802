import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from 'src/lib/utils';
import { Loader } from 'lucide-react';

const buttonVariants = cva(
  "inline-flex items-center justify-center py-2 gap-2 whitespace-nowrap rounded-full text-md font-medium font-crimson transition-[color,box-shadow] disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg:not([class*='size-'])]:size-4 shrink-0 [&_svg]:shrink-0 outline-none focus-visible:border-ring focus-visible:ring-ring/50 focus-visible:ring-[3px] aria-invalid:ring-destructive/20 dark:aria-invalid:ring-destructive/40 aria-invalid:border-destructive",
  {
    variants: {
      variant: {
        default: 'bg-primary-light text-primary-foreground-light hover:bg-primary-light/80',
        secondary: 'bg-secondary-light text-secondary-foreground-light hover:bg-border-light',
        destructive:
          'bg-destructive-light text-destructive-foreground-light hover:bg-destructive-light/90',
        outline:
          'border border-input-light bg-background-light hover:bg-accent-light hover:text-accent-foreground-light',
        ghost: 'text-foreground-light hover:bg-accent-light hover:text-accent-foreground-light',
        link: 'text-primary-light',
      },
      size: {
        default: 'h-10 px-4',
        icon: 'size-10 px-4',
        sm: 'h-9 px-3',
        lg: 'h-11 px-8',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

function Button({
  className,
  variant,
  size,
  asChild = false,
  loading = false,
  disabled,
  children,
  ...props
}: React.ComponentProps<'button'> &
  VariantProps<typeof buttonVariants> & {
    asChild?: boolean;
    loading?: boolean;
  }) {
  const Comp = asChild ? Slot : 'button';

  return (
    <Comp
      data-slot="button"
      disabled={disabled || loading}
      className={cn(buttonVariants({ variant, size, className }))}
      {...props}
    >
      {loading && <Loader className="animate-spin" />}
      {children}
    </Comp>
  );
}

export { Button, buttonVariants };
