import React from 'react';
import { Prompt, PromptState } from '../../../interfaces/interfaces';
import { buttonStyles } from './promptUtils';

interface PromptStateActionsProps {
  prompt: Prompt;
  onUpdateState: (promptId: string, state: PromptState) => Promise<void>;
  showStateButtons?: boolean;
  className?: string;
}

const PromptStateActions: React.FC<PromptStateActionsProps> = ({
  prompt,
  onUpdateState,
  showStateButtons = true,
  className = '',
}) => {
  return (
    <div className={`flex gap-2 ${className}`}>
      {showStateButtons && prompt.state === PromptState.DRAFT && (
        <button
          onClick={() => onUpdateState(prompt.id, PromptState.RC)}
          className={`${buttonStyles.primary} ${buttonStyles.rc}`}
        >
          Mark as Released Candidate
        </button>
      )}
      {showStateButtons && prompt.state === PromptState.RC && (
        <button
          onClick={() => onUpdateState(prompt.id, PromptState.PUBLISHED)}
          className={`${buttonStyles.primary} ${buttonStyles.publish}`}
        >
          Publish
        </button>
      )}
    </div>
  );
};

export default PromptStateActions;
