import {
  DropdownMenuContent,
  DropdownMenu,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/ui/dropdown-menu';
import { ChevronDown, CircleUser, Trash2, UserPlus } from 'lucide-react';
import { FC, useState } from 'react';
import { useGetAuthorProfile } from 'src/services/author/useGetAuthorProfile';
import DeleteConversationDialog from './DeleteConversationDialog';

interface Props {
  authorId: string;
  conversationId: string;
  onAuthorDetails: () => void;
}

const ChatDropdownMenu: FC<Props> = ({ authorId, conversationId, onAuthorDetails }) => {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { data } = useGetAuthorProfile(authorId);

  return (
    <div className="relative md:hidden block w-auto">
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger asChild>
          <div className="font-semibold items-center text-secondary-foreground gap-3 flex md:inline-flex">
            <span>{data?.name}</span>
            <ChevronDown className={isOpen ? '-rotate-180' : ''} width={16} height={16} />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="border-border w-[183px]">
          <DropdownMenuItem>
            <span className="text-sm font-normal leading-normal">Invite</span>
            <UserPlus width={16} height={16} />
          </DropdownMenuItem>
          <DropdownMenuItem onClick={onAuthorDetails}>
            <span className="text-sm font-normal leading-normal">About {data?.name}</span>
            <CircleUser width={16} height={16} />
          </DropdownMenuItem>
          {!!conversationId && (
            <DropdownMenuItem
              onClick={() => setIsConfirmOpen(true)}
              className="text-destructive w-full flex items-center justify-between"
            >
              <span className="text-sm font-normal leading-normal">Delete</span>
              <Trash2 width={16} height={16} />
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>

      {!!conversationId && (
        <DeleteConversationDialog
          authorId={authorId}
          conversationId={conversationId}
          isOpen={isConfirmOpen}
          setIsOpen={setIsConfirmOpen}
        />
      )}
    </div>
  );
};

export default ChatDropdownMenu;
