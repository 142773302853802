// client/components/AboutMeModal/AboutMeModal.tsx
import React, { useEffect, useState, useRef } from 'react';
import Modal from '../common/Modal';
import styles from './AboutMeModal.module.css';
import userFactsService from '../../services/userFactsService';
import { UserFact } from '../../interfaces/interfaces';

interface AboutMeModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AboutMeModal: React.FC<AboutMeModalProps> = ({ isOpen, onClose }) => {
  const [facts, setFacts] = useState<UserFact[]>([]);
  const [newFact, setNewFact] = useState('');
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isOpen) {
      fetchFacts();
      setNewFact(''); // Clear the input when the modal opens
    }
  }, [isOpen]);

  const fetchFacts = async () => {
    setLoading(true);
    try {
      const data = await userFactsService.getFacts();
      setFacts(data);
    } catch (error) {
      console.error('Failed to fetch facts', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    if (newFact.trim() === '') return;
    setSaving(true);
    try {
      const savedFact = await userFactsService.saveFact(newFact);
      setFacts(prevFacts => [...prevFacts, savedFact]);
      setNewFact('');
      if (textareaRef.current) {
        textareaRef.current.style.height = 'inherit'; // Reset textarea height
      }
    } catch (error) {
      console.error('Failed to save fact', error);
    } finally {
      setSaving(false);
    }
  };

  const handleDelete = async (factId: string) => {
    try {
      await userFactsService.deleteFact(factId);
      setFacts(facts.filter(fact => fact.id !== factId));
    } catch (error) {
      console.error('Failed to delete fact', error);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSave();
    }
    // Adjust textarea height automatically
    if (textareaRef.current) {
      textareaRef.current.style.height = 'inherit';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.aboutMeModal}>
        <h2>About Me</h2>
        {loading ? (
          <p>Loading...</p>
        ) : (
          <>
            {facts.length === 0 ? (
              <p className={styles.noFactsMessage}>
                Adding facts about yourself will improve your experience.
                <br />
                For example: "I have an 8-year-old daughter."
              </p>
            ) : (
              <ul className={styles.factList}>
                {facts.map(fact => (
                  <li key={fact.id} className={styles.factItem}>
                    <span>{fact.fact}</span>
                    <div className={styles.buttonContainer}>
                      <button className={styles.deleteButton} onClick={() => handleDelete(fact.id)}>
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </>
        )}
        <div className={styles.inputContainer}>
          <textarea
            ref={textareaRef}
            value={newFact}
            onChange={e => setNewFact(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Add a new fact about yourself..."
            className={styles.inputField}
            rows={1} // Start with one row
          />
          <button
            className={styles.saveButton}
            onClick={handleSave}
            disabled={saving || newFact.trim() === ''}
          >
            {saving ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AboutMeModal;
