import React, { useState, useEffect, useCallback, createContext, useContext } from 'react';
import { Prompt, PromptState } from '../../../interfaces/interfaces';
import promptOverrideService, { PromptOverride } from '../../../services/promptOverrideService';
import { AlertTriangle, X, ChevronDown, ChevronRight, RefreshCw } from 'lucide-react';
import { useGetPrompts, useGetPromptVersions } from '../../../services/prompts/usePrompts';

// Create context for prompt overrides
interface PromptOverrideContextType {
  overrides: PromptOverride[];
  addOverride: (prompt: Prompt) => void;
  removeOverride: (promptName: string) => void;
  clearAllOverrides: () => void;
  hasOverride: (promptName: string) => boolean;
}

const PromptOverrideContext = createContext<PromptOverrideContextType | undefined>(undefined);

// Custom hook to use prompt overrides
export const usePromptOverrides = () => {
  const context = useContext(PromptOverrideContext);
  if (!context) {
    throw new Error('usePromptOverrides must be used within a PromptOverrideProvider');
  }
  return context;
};

// Provider component for prompt overrides
export const PromptOverrideProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [overrides, setOverrides] = useState<PromptOverride[]>([]);

  // Load overrides from localStorage on mount
  useEffect(() => {
    setOverrides(promptOverrideService.getOverrides());

    const handleStorageChange = () => {
      setOverrides(promptOverrideService.getOverrides());
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('prompt-overrides-changed', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('prompt-overrides-changed', handleStorageChange);
    };
  }, []);

  const addOverride = useCallback((prompt: Prompt) => {
    promptOverrideService.setOverride(prompt);
    window.dispatchEvent(new Event('prompt-overrides-changed'));
  }, []);

  const removeOverride = useCallback((promptName: string) => {
    promptOverrideService.removeOverride(promptName);
    window.dispatchEvent(new Event('prompt-overrides-changed'));
  }, []);

  const clearAllOverrides = useCallback(() => {
    promptOverrideService.clearAllOverrides();
    window.dispatchEvent(new Event('prompt-overrides-changed'));
  }, []);

  const hasOverride = useCallback(
    (promptName: string) => {
      return overrides.some(o => o.promptName === promptName);
    },
    [overrides]
  );

  // Create context value
  const value = {
    overrides,
    addOverride,
    removeOverride,
    clearAllOverrides,
    hasOverride,
  };

  return <PromptOverrideContext.Provider value={value}>{children}</PromptOverrideContext.Provider>;
};

// Custom hook for RC prompts
const useRCPrompts = () => {
  const { data, isLoading, refetch } = useGetPrompts(1, 100, undefined, PromptState.RC);
  const { hasOverride } = usePromptOverrides();

  // Group prompts by name
  const prompts = React.useMemo(() => {
    if (!data?.prompts) return {};

    const groupedPrompts: Record<string, Prompt[]> = {};
    data.prompts.forEach((prompt: Prompt) => {
      if (!groupedPrompts[prompt.name]) {
        groupedPrompts[prompt.name] = [];
      }
      groupedPrompts[prompt.name].push(prompt);
    });
    return groupedPrompts;
  }, [data?.prompts]);

  // Filter for prompts that have RC versions and aren't already overridden
  const availablePromptNames = Object.keys(prompts).filter(
    name => prompts[name].some(p => p.state === PromptState.RC) && !hasOverride(name)
  );

  // Function to get RC versions for a specific prompt
  const getVersionOptions = (promptName: string) => {
    return promptName ? prompts[promptName]?.filter(p => p.state === PromptState.RC) || [] : [];
  };

  // Function to get a specific prompt by name and id
  const getPrompt = (promptName: string, versionId: string) => {
    return prompts[promptName]?.find(p => p.id === versionId);
  };

  // Function to handle refresh button click
  const refreshPrompts = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    prompts,
    isLoading,
    availablePromptNames,
    getVersionOptions,
    getPrompt,
    refreshPrompts,
  };
};

// Separate component for the active overrides list
const ActiveOverrides: React.FC = () => {
  const { overrides, removeOverride, clearAllOverrides } = usePromptOverrides();

  if (overrides.length === 0) return null;

  return (
    <div className="mb-4">
      <div className="bg-yellow-50 p-3 rounded-md border border-yellow-200">
        <div className="flex justify-between items-center mb-2">
          <h4 className="font-medium text-yellow-800">Active Overrides</h4>
          <button
            onClick={clearAllOverrides}
            className="text-xs text-yellow-700 hover:text-yellow-900 underline"
          >
            Clear all
          </button>
        </div>
        <ul className="space-y-2">
          {overrides.map(override => (
            <li key={override.promptName} className="flex justify-between items-center text-sm">
              <span className="font-medium">{override.promptName}</span>
              <div className="flex items-center">
                <span className="text-gray-600 mr-2">
                  v{override.majorVersion}.{override.minorVersion}
                </span>
                <button
                  onClick={() => removeOverride(override.promptName)}
                  className="text-gray-500 hover:text-gray-700"
                  title="Remove override"
                >
                  <X className="h-4 w-4" />
                </button>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

// Separate component for the prompt selection form
const PromptSelectionForm: React.FC = () => {
  const [selectedPromptName, setSelectedPromptName] = useState<string>('');
  const [selectedVersionId, setSelectedVersionId] = useState<string>('');
  const { availablePromptNames } = useRCPrompts();
  const { addOverride } = usePromptOverrides();
  const { data: promptVersions, isLoading: isLoadingVersions } =
    useGetPromptVersions(selectedPromptName);

  // Filter RC versions from the fetched versions
  const versionOptions = React.useMemo(() => {
    if (!promptVersions) return [];
    return promptVersions.filter((p: Prompt) => p.state === PromptState.RC);
  }, [promptVersions]);

  // Reset version selection when prompt name changes
  useEffect(() => {
    setSelectedVersionId('');
  }, [selectedPromptName]);

  const handleAddOverride = () => {
    if (!selectedPromptName || !selectedVersionId) return;

    const selectedPrompt = versionOptions.find((p: Prompt) => p.id === selectedVersionId);
    if (selectedPrompt) {
      addOverride(selectedPrompt);

      // Reset selections
      setSelectedPromptName('');
      setSelectedVersionId('');
    }
  };

  return (
    <div className="flex flex-col md:flex-row gap-3">
      <div className="flex-1">
        <label htmlFor="promptName" className="block text-sm font-medium text-gray-700 mb-1">
          Select Prompt
        </label>
        <select
          id="promptName"
          value={selectedPromptName}
          onChange={e => setSelectedPromptName(e.target.value)}
          className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          disabled={availablePromptNames.length === 0}
        >
          <option value="">Select a prompt to override</option>
          {availablePromptNames.map(name => (
            <option key={name} value={name}>
              {name}
            </option>
          ))}
        </select>
      </div>

      <div className="flex-1">
        <label htmlFor="versionId" className="block text-sm font-medium text-gray-700 mb-1">
          Select Version
        </label>
        <select
          id="versionId"
          value={selectedVersionId}
          onChange={e => setSelectedVersionId(e.target.value)}
          className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500"
          disabled={!selectedPromptName || isLoadingVersions || versionOptions.length === 0}
        >
          <option value="">Select a version</option>
          {isLoadingVersions ? (
            <option value="" disabled>
              Loading versions...
            </option>
          ) : (
            versionOptions.map((version: Prompt) => (
              <option key={version.id} value={version.id}>
                v{version.majorVersion}.{version.minorVersion} ({version.state})
              </option>
            ))
          )}
        </select>
      </div>

      <div className="flex items-end">
        <button
          onClick={handleAddOverride}
          disabled={!selectedPromptName || !selectedVersionId || isLoadingVersions}
          className="w-full md:w-auto h-[38px] bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          Add Override
        </button>
      </div>
    </div>
  );
};

// Main component that uses context and hooks
const PromptOverridePanel: React.FC = () => {
  const [isExpanded, setIsExpanded] = useState(true);
  const { isLoading, availablePromptNames, refreshPrompts } = useRCPrompts();
  const { overrides } = usePromptOverrides();

  const togglePanel = () => {
    setIsExpanded(!isExpanded);
  };

  if (isLoading && !availablePromptNames.length) {
    return <div className="text-center p-4">Loading available prompts...</div>;
  }

  return (
    <div className="bg-white shadow rounded-md p-4 mb-6">
      <div className="flex justify-between items-center mb-4">
        <div className="flex items-center">
          <button
            onClick={togglePanel}
            className="mr-2 focus:outline-none"
            aria-label={isExpanded ? 'Collapse panel' : 'Expand panel'}
          >
            {isExpanded ? <ChevronDown size={18} /> : <ChevronRight size={18} />}
          </button>
          <h3 className="text-lg font-medium text-gray-900">Prompt Override Panel</h3>
        </div>
        <div className="flex items-center space-x-3">
          {overrides.length > 0 && (
            <div className="flex items-center text-yellow-600">
              <AlertTriangle className="h-5 w-5 mr-1" />
              <span className="text-sm font-medium">
                {overrides.length} active override{overrides.length !== 1 ? 's' : ''}
              </span>
            </div>
          )}
          <button
            onClick={refreshPrompts}
            className="text-gray-500 hover:text-gray-700 p-1 rounded-full hover:bg-gray-100"
            title="Refresh prompts"
          >
            <RefreshCw size={16} />
          </button>
        </div>
      </div>

      {isExpanded && (
        <>
          <ActiveOverrides />
          <PromptSelectionForm />

          {availablePromptNames.length === 0 && !isLoading && (
            <p className="text-sm text-gray-500 mt-2">
              No RC prompts available for override or all prompts are already overridden.
            </p>
          )}
        </>
      )}
    </div>
  );
};

// Export a wrapped version with the provider
const PromptOverridePanelWithProvider: React.FC = () => (
  <PromptOverrideProvider>
    <PromptOverridePanel />
  </PromptOverrideProvider>
);

export default PromptOverridePanelWithProvider;
