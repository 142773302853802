//client/src/services/chatService.ts

// Use this api client sice it adds interceptors which adds headers for auth and prompt overrides.
import apiClient from '../api/apiClient';
import { Conversation, Message } from 'src/interfaces/interfaces';

const API_URL = process.env.REACT_APP_API_URL;

if (!API_URL) {
  throw new Error('REACT_APP_API_URL is not defined in .env file');
}

const getToken = () => {
  const token = localStorage.getItem('token');
  if (!token) throw new Error('No token found');
  return token;
};

const config = () => ({
  headers: {
    Authorization: `Bearer ${getToken()}`,
  },
});

export interface ChatMessage {
  role: 'user' | 'assistant' | 'system';
  content: string;
  audio?: string;
  isLoading?: boolean;
}

const sendMessage = async (
  bookId: string,
  message: string,
  isOverGraph: boolean
): Promise<ChatMessage> => {
  const response = await apiClient.post(`/chat`, { message, bookId, isOverGraph }, config());
  return response.data;
};

const getAudio = async (text: string, authorId: string): Promise<string> => {
  try {
    // Detect iOS devices or browsers without MediaSource support
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const hasMediaSource = typeof MediaSource !== 'undefined';

    // For iOS devices, use a direct approach rather than MediaSource
    if (isIOS || !hasMediaSource) {
      try {
        // Use a direct POST request with authenticated headers
        const response = await apiClient.post(
          `/tts`,
          { text, authorId },
          {
            ...config(),
            responseType: 'arraybuffer', // Use arraybuffer for binary data
          }
        );

        // Create a blob URL with explicit MP3 MIME type
        const blob = new Blob([response.data], { type: 'audio/mpeg' });
        return URL.createObjectURL(blob);
      } catch (error) {
        console.error('Error fetching audio for iOS:', error);
        throw error;
      }
    }

    // For browsers with MediaSource support, use MediaSource streaming
    const mediaSource = new MediaSource();
    const audioUrl = URL.createObjectURL(mediaSource);

    mediaSource.addEventListener('sourceopen', async () => {
      let sourceBuffer: SourceBuffer | null = null;
      try {
        sourceBuffer = mediaSource.addSourceBuffer('audio/mpeg');
        const bufferQueue: Uint8Array[] = [];
        let isProcessing = false;
        let isStreamComplete = false;

        const processBufferQueue = async () => {
          if (isProcessing || bufferQueue.length === 0 || sourceBuffer!.updating) {
            // If we're done receiving data and not processing, try to end the stream
            if (isStreamComplete && !isProcessing && bufferQueue.length === 0) {
              const checkAndEndStream = () => {
                if (!sourceBuffer!.updating) {
                  mediaSource.endOfStream();
                } else {
                  sourceBuffer!.addEventListener('updateend', checkAndEndStream, { once: true });
                }
              };
              checkAndEndStream();
            }
            return;
          }

          isProcessing = true;
          try {
            const chunk = bufferQueue.shift()!;
            sourceBuffer!.appendBuffer(chunk);
            await new Promise(resolve => {
              sourceBuffer!.addEventListener('updateend', resolve, { once: true });
            });
          } finally {
            isProcessing = false;
            // Process next chunk if available
            if (bufferQueue.length > 0) {
              processBufferQueue();
            } else if (isStreamComplete) {
              // Try to end stream if this was the last chunk
              processBufferQueue();
            }
          }
        };

        const response = await fetch(`${API_URL}/tts`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ text, authorId }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch audio');
        }

        const reader = response.body!.getReader();

        // Read the stream
        const processStream = async () => {
          try {
            let result;
            do {
              result = await reader.read();
              if (result.done) {
                isStreamComplete = true;
                processBufferQueue(); // Try to process any remaining chunks
                break;
              }

              bufferQueue.push(result.value);
              processBufferQueue();
            } while (!result.done);
          } catch (error) {
            console.error('Error reading stream:', error);
            isStreamComplete = true;
            processBufferQueue();
          }
        };

        // Start processing the stream
        processStream();

        return audioUrl;
      } catch (error) {
        console.error('Error in MediaSource processing:', error);
        if (mediaSource.readyState === 'open' && sourceBuffer) {
          // Only try to end stream if not updating
          const endStreamOnError = () => {
            if (!sourceBuffer!.updating) {
              mediaSource.endOfStream('network');
            } else {
              sourceBuffer!.addEventListener('updateend', endStreamOnError, { once: true });
            }
          };
          endStreamOnError();
        }
      }
    });

    return audioUrl;
  } catch (error) {
    console.error('Error getting audio URL:', error);
    throw error; // Rethrow so the ChatContext can handle it
  }
};

const fetchHistory = async (bookId: string) => {
  try {
    const response = await apiClient.get(`/chat/${bookId}/history`, config());
    return response.data;
  } catch (error) {
    console.error('Error fetching chat history:', error);
    throw error;
  }
};

const clearHistory = async (bookId: string) => {
  try {
    const response = await apiClient.delete(`/chat/${bookId}/history`, config());
    return response.data;
  } catch (error) {
    console.error('Error fetching chat history:', error);
    throw error;
  }
};

const sendMessageStream = async (
  conversationId: string,
  message: string,
  isOverGraph: boolean,
  onChunk: (chunk: string, isFinal: boolean) => void
): Promise<void> => {
  const payload = {
    messages: [{ role: 'user', content: message }],
    config: {
      conversationId,
      isOverGraph,
    },
  };

  const response = await fetch(`${API_URL}/chat/${conversationId}`, {
    method: 'POST',
    headers: {
      ...config().headers,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error(`Server responded with ${response.status}`);
  }

  const reader = response.body?.getReader();
  if (!reader) throw new Error('Readable stream not supported');

  const decoder = new TextDecoder('utf-8');
  let buffer = '';
  let done = false;

  while (!done) {
    const { value, done: doneReading } = await reader.read();
    done = doneReading;
    if (!value) continue;

    buffer += decoder.decode(value, { stream: true });
    const lines = buffer.split('\n');
    buffer = lines.pop() || '';

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      if (line.startsWith('data: ')) {
        const data = line.substring(6);
        if (data === '[DONE]') {
          onChunk('', true);
          return;
        } else {
          onChunk(data, false);
        }
      } else if (line.startsWith('event: error')) {
        const dataLine = lines[i + 1];
        const errorMessage = dataLine?.startsWith('data: ')
          ? dataLine.substring(6)
          : 'Unknown error';
        throw new Error(errorMessage);
      }
    }
  }
};

const getConversations = async (authorId: string): Promise<Conversation[]> => {
  const response = await apiClient.get(`${API_URL}/conversations?authorId=${authorId}`, config());
  return response.data;
};

const getConversationHistory = async (conversationId: string): Promise<Message[]> => {
  const response = await apiClient.get(
    `${API_URL}/conversations/${conversationId}/history`,
    config()
  );
  return response.data;
};

export interface ICreateConversation {
  message: string;
  authorId: string;
}

const createConversation = async (conversation: ICreateConversation): Promise<Conversation> => {
  const response = await apiClient.post(`${API_URL}/conversations`, conversation, config());
  return response.data;
};

const deleteConversation = async (id: string) => {
  try {
    await apiClient.delete(`${API_URL}/conversations/${id}`, config());
  } catch (error) {
    console.error('Error while deleting conversation:', error);
    throw error;
  }
};

const chatService = {
  fetchHistory,
  sendMessage,
  getAudio,
  getConversations,
  clearHistory,
  sendMessageStream,
  createConversation,
  getConversationHistory,
  deleteConversation,
};

export default chatService;
