// src/components/authorPage/Tools.tsx

import React from 'react';
import styles from './Tools.module.css';

// Importing icons as React components
import { ReactComponent as HoursTrackerIcon } from '../../assets/icons/hours-tracker.svg';
import { ReactComponent as CulturalLegacyIcon } from '../../assets/icons/cultural-legacy.svg';
import { ReactComponent as IntelligenceIcon } from '../../assets/icons/intelligence-quests.svg';
import { ReactComponent as InteractiveDecision } from '../../assets/icons/interactive-decision.svg';
import { ReactComponent as MsgAssesment } from '../../assets/icons/msg-assesment.svg';
import { ReactComponent as V2Mom } from '../../assets/icons/v2mom.svg';
import { useLogEvent } from '../../utils/analytics';
import { EventAction } from '../../constants/analytics';

const icons = [
  { icon: InteractiveDecision, color: '#21808D' },
  { icon: MsgAssesment, color: '#C87156' },
  { icon: V2Mom, color: '#6F499D' },
];

interface Tool {
  id: string;
  name: string;
}

const Tools: React.FC<{ tools: { id: string; name: string }[] }> = ({ tools }) => {
  const logEvent = useLogEvent();

  return (
    <div className={styles.toolsContainer}>
      {tools.map((tool, index) => {
        // Cycle through the icons array using the modulo operator for now. Remove it later.
        const IconComponent = icons[index % icons.length].icon;
        const iconColor = icons[index % icons.length].color;
        return (
          <div key={tool.id} className={styles.toolItem}>
            <button
              className={styles.toolButton}
              onClick={event => {
                logEvent(EventAction.PRACTICES_CLICKED, tool.name, { toolId: tool.id });
              }}
            >
              <IconComponent className={styles.icon} style={{ color: iconColor }} />
              <span className={styles.toolText}>{tool.name}</span>
            </button>
          </div>
        );
      })}
    </div>
  );
};

export default Tools;
