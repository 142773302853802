import { FC, useState } from 'react';
import { Ellipsis, Trash2 } from 'lucide-react';
import { Typography } from '../ui/typography';
import {
  SidebarGroup,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuItem,
  SidebarMenuButton,
  useSidebar,
} from 'src/components/ui/sidebar';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuItem,
} from 'src/components/ui/dropdown-menu';
import { getDateLabel } from 'src/utils/date';
import { Link } from 'react-router-dom';
import { useGetConversations } from 'src/services/chat/useGetConversations';
import { Conversation } from 'src/interfaces/interfaces';
import DeleteConversationDialog from './DeleteConversationDialog';

interface Props {
  authorId: string;
}

export const ChatList: FC<Props> = ({ authorId }) => {
  const [conversationId, setConversationId] = useState('');
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const { data } = useGetConversations(authorId);

  const { setOpenMobile, isMobile } = useSidebar();

  const groupedConversations = Object.entries(
    data?.reduce<Record<string, Conversation[]>>((acc, conversation) => {
      const dateKey = new Date(conversation.createdAt).toDateString();

      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(conversation);

      return acc;
    }, {}) ?? []
  );

  const handleNavigate = () => {
    if (isMobile) setOpenMobile(false);
  };

  const onDeleteClick = (conversationId: string) => {
    setConversationId(conversationId);
    setIsConfirmOpen(true);
  };

  return (
    <div className="overflow-y-auto [scrollbar-width:none] space-y-2">
      {groupedConversations.map(([dateString, conversations]) => {
        const dateObj = new Date(dateString);
        return (
          <SidebarGroup key={dateString} className="py-2 px-0">
            <SidebarGroupLabel>
              <Typography variant="muted-light" size="xs">
                {getDateLabel(dateObj)}
              </Typography>
            </SidebarGroupLabel>
            <SidebarMenu>
              {conversations.map(conversation => (
                <SidebarMenuItem key={conversation.id}>
                  <SidebarMenuButton className="p-2 bg-transparent text-sidebar-foreground hover:bg-border-light hover:text-sidebar-accent-foreground">
                    <Link
                      className="truncate flex-1 min-w-0 "
                      to={conversation.id}
                      onClick={handleNavigate}
                    >
                      <Typography size="sm" className="leading-normal">
                        {conversation.title}
                      </Typography>
                    </Link>
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Ellipsis className="text-muted-foreground-light size-5 flex-none" />
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end" className="border-border w-[183px]">
                        <DropdownMenuItem
                          onClick={() => onDeleteClick(conversation.id)}
                          className="text-destructive flex items-center justify-between"
                        >
                          <span className="text-sm font-normal leading-normal">Delete</span>
                          <Trash2 width={16} height={16} />
                        </DropdownMenuItem>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </SidebarMenuButton>
                </SidebarMenuItem>
              ))}
            </SidebarMenu>
          </SidebarGroup>
        );
      })}

      <DeleteConversationDialog
        authorId={authorId}
        conversationId={conversationId}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      />
    </div>
  );
};
