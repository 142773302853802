import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Header from '../common/v2/Header';
import { SidebarProvider } from '../ui/sidebar';
import { ChatSidebar } from './ChatSidebar';

const AuthorChatLayout: FC = () => {
  const { authorId } = useParams();

  if (!authorId) {
    throw new Error('Missing authorId in route params');
  }

  return (
    <SidebarProvider>
      <ChatSidebar authorId={authorId} />
      <div className="md:bg-card-light h-screen md:p-3 flex-1">
        <div className="flex h-full flex-col md:rounded-md bg-background-light shadow-main">
          <Header authorId={authorId} />
          <div className="flex flex-1 grow basis-auto flex-col overflow-hidden">
            <div className="flex h-full flex-col overflow-y-auto [scrollbar-gutter:stable] pb-10">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </SidebarProvider>
  );
};

export default AuthorChatLayout;
