// src/components/authorPage/ProfileSectionFull.tsx

import React, { useEffect, useState } from 'react';
import styles from './ProfileSectionFull.module.css';

import locationIcon from '../../assets/icons/location.svg';
import bioIcon from '../../assets/icons/bio.svg';
import twitterIcon from '../../assets/icons/twitter.svg';
import calendarIcon from '../../assets/icons/calendar.svg';
import { useNavigate } from 'react-router-dom';
import { useAuthor } from '../../context/AuthorContext';
import { useAuth } from '../../context/AuthContext';

const ProfileSectionFull: React.FC = () => {
  const { author, loading, error } = useAuthor();
  const { user } = useAuth();
  const navigate = useNavigate();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error || !author) {
    return <div>Error loading profile.</div>;
  }

  const handleEditClick = (authorId: string | undefined) => {
    navigate(`/author/${authorId}/settings`);
  };

  const extractTwitterUsername = (url: string): string => {
    const match = url.match(/(?:twitter\.com|x\.com)\/([^\/?]+)/);
    return match ? match[1] : 'No username';
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const formatEventDate = (dateString: string, locale: string = navigator.language): string => {
    const date = new Date(dateString);
    const is12HourFormat = locale === 'en-US'; // Check if locale should use 12-hour format

    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      hour: '2-digit',
      minute: '2-digit',
      hour12: is12HourFormat, // Use 12-hour format for US locale
    };

    return date.toLocaleDateString(locale, options).replace(',', '');
  };

  return (
    <div className={styles.profileSection}>
      {user?.userType === 'admin' ? (
        <div className={styles.editBtn} key={author.id} onClick={() => handleEditClick(author.id)}>
          Edit
        </div>
      ) : (
        <div></div>
      )}
      <div className={styles.authorInfo}>
        <div className={styles.authotar}>
          <div className={styles.profileImageContainer}>
            <img src={author.photo} alt="Author" className={styles.profileImage} />
            <div className={styles.imageOverlay} />
          </div>
          <div className={styles.name}>{author.name}</div>
          <div className={styles.title}>{author.title || ''}</div>
        </div>
        <div className={styles.links}>
          <div className={styles.infoRow}>
            <img src={locationIcon} alt="Location" className={styles.icon} />
            <span>{author.location || 'Earth'}</span>
          </div>
          <div className={styles.infoRow}>
            <img src={bioIcon} alt="Bio Link" className={styles.icon} />
            {author.socialLinks?.blog ? (
              <a
                href={author.socialLinks.blog}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                Website
              </a>
            ) : (
              <span>No blog link</span>
            )}
          </div>
          <div className={styles.infoRow}>
            <img src={twitterIcon} alt="Twitter" className={styles.icon} />
            {author.socialLinks?.twitter ? (
              <a
                href={author.socialLinks.twitter}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.link}
              >
                {extractTwitterUsername(author.socialLinks.twitter)}
              </a>
            ) : (
              <span>No Twitter handle</span>
            )}
          </div>
        </div>
        <div className={styles.nameSub}>{author.name}</div>
        <div className={styles.stats}>
          <div className={styles.readers}>{author.numberOfReaders.toLocaleString()} Readers</div>
          <div className={styles.subscribeButton}>Subscribe</div>
        </div>
        <p className={styles.bio} title={author.biography}>
          {truncateText(author.biography, 250)}
        </p>
      </div>

      <div className={styles.sectionDivider}></div>

      <div className={styles.lectureList}>
        <div className={styles.sectionTitle}>Book Lectures</div>
        {author.offerings.length > 0 ? (
          author.offerings.map(offering => (
            <div className={styles.lectureItem} key={offering.id}>
              <div className={styles.iconContainer}>
                <img src={calendarIcon} alt="Calendar" className={styles.icon} />
              </div>
              <div className={styles.lectureDetails}>
                <div className={styles.lectureName}>{offering.name}</div>
                <div className={styles.subtext}>{offering.action || 'Details coming soon'}</div>
              </div>
            </div>
          ))
        ) : (
          <div className={styles.noDetails}>Details coming soon...</div>
        )}
      </div>

      <div className={styles.sectionDivider}></div>

      <div className={styles.onTour}>
        <h3 className={styles.sectionTitle}>On Tour</h3>
        {author.events.length > 0 ? (
          author.events.map(event => (
            <div className={styles.tourItem} key={event.id}>
              <div className={styles.iconContainer}>
                <img src={calendarIcon} alt="Calendar" className={styles.icon} />
              </div>
              <div className={styles.tourDetails}>
                <div className={styles.lectureName}>{event.eventName}</div>
                <div className={styles.subtext}>
                  {formatEventDate(event.eventDate)} • {event.place}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className={styles.noDetails}>Details coming soon...</div>
        )}
      </div>
    </div>
  );
};

export default ProfileSectionFull;
