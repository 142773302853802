import { Prompt } from '../interfaces/interfaces';

const PROMPT_OVERRIDES_KEY = 'prompt_overrides';

/**
 * Interface for storing prompt override information
 */
export interface PromptOverride {
  promptName: string;
  id: string;
  majorVersion: number;
  minorVersion: number;
}

/**
 * Gets all active prompt overrides from localStorage
 */
const getOverrides = (): PromptOverride[] => {
  const overridesJson = localStorage.getItem(PROMPT_OVERRIDES_KEY);
  if (!overridesJson) {
    return [];
  }

  try {
    return JSON.parse(overridesJson);
  } catch (error) {
    console.error('Error parsing prompt overrides:', error);
    return [];
  }
};

/**
 * Adds or updates a prompt override
 * @param prompt The prompt to override with
 */
const setOverride = (prompt: Prompt): void => {
  const overrides = getOverrides();

  // Check if this prompt name already has an override
  const existingIndex = overrides.findIndex(o => o.promptName === prompt.name);

  const override: PromptOverride = {
    promptName: prompt.name,
    id: prompt.id,
    majorVersion: prompt.majorVersion,
    minorVersion: prompt.minorVersion,
  };

  if (existingIndex >= 0) {
    // Update existing override
    overrides[existingIndex] = override;
  } else {
    // Add new override
    overrides.push(override);
  }

  localStorage.setItem(PROMPT_OVERRIDES_KEY, JSON.stringify(overrides));
};

/**
 * Removes a prompt override
 * @param promptName The name of the prompt to remove override for
 */
const removeOverride = (promptName: string): void => {
  const overrides = getOverrides();
  const filteredOverrides = overrides.filter(o => o.promptName !== promptName);
  localStorage.setItem(PROMPT_OVERRIDES_KEY, JSON.stringify(filteredOverrides));
};

/**
 * Checks if a prompt has an active override
 * @param promptName The name of the prompt to check
 */
const hasOverride = (promptName: string): boolean => {
  const overrides = getOverrides();
  return overrides.some(o => o.promptName === promptName);
};

/**
 * Gets a specific prompt override
 * @param promptName The name of the prompt to get override for
 */
const getOverride = (promptName: string): PromptOverride | null => {
  const overrides = getOverrides();
  return overrides.find(o => o.promptName === promptName) || null;
};

/**
 * Clears all prompt overrides
 */
const clearAllOverrides = (): void => {
  localStorage.removeItem(PROMPT_OVERRIDES_KEY);
};

/**
 * Converts overrides to header format
 */
const getOverridesHeader = (): string => {
  const overrides = getOverrides();
  return JSON.stringify(overrides);
};

/**
 * Checks if any overrides are active
 */
const hasAnyOverrides = (): boolean => {
  return getOverrides().length > 0;
};

export default {
  getOverrides,
  setOverride,
  removeOverride,
  hasOverride,
  getOverride,
  clearAllOverrides,
  getOverridesHeader,
  hasAnyOverrides,
};
