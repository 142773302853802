// client/src/components/auth/RegisterPage.tsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';
import { useAuth } from '../../context/AuthContext';
import styles from './RegisterPage.module.css';
import Loading from '../loader/Loading';
import { sleep } from '../../utils/debugLogger';

const RegisterPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false); // State for loader
  const navigate = useNavigate();
  const { setUser } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true); // Start the loader
    try {
      const user = await authService.register(email, password, name, lastName);
      if (!user.jwtToken) {
        throw new Error('User not registered. Token is empty');
      }
      setUser(user);
      navigate('/');
    } catch (err: any) {
      setError(err.message);
    } finally {
      setIsLoading(false); // Stop the loader
    }
  };

  const navigateToLogin = () => {
    navigate('/login');
  };

  return (
    <div className={styles.authPage}>
      <div className={styles.logoContainer}>
        <img
          src={require('../../assets/icons/wisdom-logo.svg').default}
          alt="Wisdom Logo"
          className={styles.logo}
        />
      </div>
      <form onSubmit={handleSubmit} className={styles.authForm}>
        <h2 className={styles.title}>Create Your Account</h2>
        <p className={styles.subtitle}>Sign up to join our community</p>
        <label className={styles.label}>
          First Name
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            required
            className={styles.input}
          />
        </label>
        <label className={styles.label}>
          Last Name
          <input
            type="text"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            required
            className={styles.input}
          />
        </label>
        <label className={styles.label}>
          Email
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
            className={styles.input}
          />
        </label>
        <label className={styles.label}>
          Password
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
            className={styles.input}
          />
        </label>
        <button
          type="submit"
          className={`${styles.button} ${isLoading ? styles.loadingButton : ''}`}
          disabled={isLoading} // Disable button during loading
        >
          {isLoading ? <Loading type="dots" size="small" color="var(--color-8)" /> : 'Register'}
        </button>
        <button type="button" className={styles.secondaryButton} onClick={navigateToLogin}>
          Already have an account? Log In
        </button>
        {error && <p className={styles.errorMessage}>{error}</p>}
      </form>
    </div>
  );
};

export default RegisterPage;
