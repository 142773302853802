import React from 'react';
import { useAuth } from '../../context/AuthContext';
import PromptOverrideIndicator from './prompt/PromptOverrideIndicator';

/**
 * Global component that shows the prompt override indicator
 * only for admin users on all pages
 */
const GlobalPromptOverrideIndicator: React.FC = () => {
  const { user } = useAuth();

  // Only show for admin users
  if (user?.userType !== 'admin') {
    return null;
  }

  return <PromptOverrideIndicator />;
};

export default GlobalPromptOverrideIndicator;
