import axios, { AxiosRequestConfig } from 'axios';
import promptOverrideService from '../promptOverrideService';
import authTokenService from '../authTokenService';

const API_URL = process.env.REACT_APP_API_URL;

// Create axios instance
const apiClient = axios.create({
  baseURL: API_URL,
});

// Add a request interceptor to include authentication and prompt overrides
apiClient.interceptors.request.use(
  config => {
    // Add auth token to all requests
    const token = authTokenService.getToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Add prompt overrides if any exist
    if (promptOverrideService.hasAnyOverrides()) {
      const overridesHeader = promptOverrideService.getOverridesHeader();
      config.headers['X-Prompt-Overrides'] = overridesHeader;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Helper function to get config object with auth
export const getAuthConfig = (): AxiosRequestConfig => {
  return {
    headers: {
      Authorization: `Bearer ${authTokenService.getToken()}`,
    },
  };
};

export default apiClient;
