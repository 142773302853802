import React, { useState, useEffect } from 'react';
import { Prompt, PromptState, Author } from '../../../interfaces/interfaces';
import { useCreatePrompt, useUpdatePrompt } from '../../../services/prompts/usePrompts';
import adminService from '../../../services/adminService';
import nunjucks from 'nunjucks';
import NunjucksHelpModal from './NunjucksHelpModal';
import NunjucksSyntaxHighlighter from './NunjucksSyntaxHighlighter';
import { Maximize2, Minimize2 } from 'lucide-react';

// Template validation function using Nunjucks
const validateNunjucksTemplate = (content: string): { isValid: boolean; error?: string } => {
  try {
    // Try to render the template with empty variables to check for syntax errors
    nunjucks.renderString(content, {});
    return { isValid: true };
  } catch (error) {
    return {
      isValid: false,
      error: error instanceof Error ? error.message : String(error),
    };
  }
};

// Function to extract variables from content
const extractVariables = (content: string): string[] => {
  // This will extract both Nunjucks variables and legacy {{variable}} syntax
  const matches = content.match(/\{\{([^}]+)\}\}|{{\s*([a-zA-Z0-9_\.]+)\s*}}/g) || [];
  const variables = matches.map(match => match.replace(/{{|}}/g, '').trim());
  // Remove duplicates using Array.from
  return Array.from(new Set(variables));
};

interface PromptModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  prompt?: Prompt | null;
}

const PromptModal: React.FC<PromptModalProps> = ({ isOpen, onClose, onSave, prompt }) => {
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [content, setContent] = useState('');
  const [description, setDescription] = useState('');
  const [metadata, setMetadata] = useState('{}');
  const [authorId, setAuthorId] = useState<string>('');
  const [variables, setVariables] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [templateError, setTemplateError] = useState<string | null>(null);
  const [authors, setAuthors] = useState<Author[]>([]);
  const [isLoadingAuthors, setIsLoadingAuthors] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isContentFullscreen, setIsContentFullscreen] = useState(false);

  // Use React Query mutations
  const createPromptMutation = useCreatePrompt();
  const updatePromptMutation = useUpdatePrompt();

  const isSubmitting = createPromptMutation.isPending || updatePromptMutation.isPending;

  // Handle escape key to exit fullscreen
  useEffect(() => {
    const handleEscapeKey = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isContentFullscreen) {
        setIsContentFullscreen(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [isContentFullscreen]);

  // Fetch authors when component mounts
  useEffect(() => {
    const fetchAuthors = async () => {
      setIsLoadingAuthors(true);
      try {
        const response = await adminService.getAuthors(1, 100);
        setAuthors(response.authors);
      } catch (err) {
        console.error('Failed to load authors:', err);
        setError('Failed to load authors. Please try again.');
      } finally {
        setIsLoadingAuthors(false);
      }
    };

    fetchAuthors();
  }, []);

  useEffect(() => {
    if (prompt) {
      setName(prompt.name);
      setType(prompt.type);
      setContent(prompt.content);
      setDescription(prompt.description || '');
      setMetadata(JSON.stringify(prompt.metadata || {}, null, 2));
      setVariables(extractVariables(prompt.content));
      setAuthorId(prompt.authorId || '');
    } else {
      // Default values for new prompt
      setName('');
      setType('');
      setContent('');
      setDescription('');
      setMetadata('{}');
      setVariables([]);
      setAuthorId('');
    }
  }, [prompt]);

  // Update variables when content changes and validate template syntax
  useEffect(() => {
    setVariables(extractVariables(content));

    // Validate template syntax
    if (content.trim()) {
      const validationResult = validateNunjucksTemplate(content);
      if (!validationResult.isValid) {
        setTemplateError(validationResult.error || 'Invalid template syntax');
      } else {
        setTemplateError(null);
      }
    } else {
      setTemplateError(null);
    }
  }, [content]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);

    // Final validation before submitting
    const validationResult = validateNunjucksTemplate(content);
    if (!validationResult.isValid) {
      setTemplateError(validationResult.error || 'Invalid template syntax');
      setError('Please fix the template syntax errors before saving.');
      return;
    }

    try {
      // Parse metadata JSON
      let parsedMetadata = {};
      try {
        parsedMetadata = JSON.parse(metadata);
      } catch (err) {
        throw new Error('Invalid metadata JSON format');
      }

      const promptData: Partial<Prompt> = {
        name,
        type,
        content,
        description,
        metadata: {
          ...parsedMetadata,
        },
        state: PromptState.DRAFT, // New prompts are always created as drafts
        authorId: authorId || null, // Use null if no author selected
      };

      if (prompt) {
        // Update existing prompt
        await updatePromptMutation.mutateAsync({ id: prompt.id, promptData });
      } else {
        // Create new prompt
        await createPromptMutation.mutateAsync(promptData);
      }

      // Dispatch an event to notify the override panel that prompt states have changed
      window.dispatchEvent(new Event('prompt-state-changed'));

      onSave();
    } catch (err) {
      console.error('Error saving prompt:', err);
      setError(err instanceof Error ? err.message : 'Failed to save prompt');
    }
  };

  const toggleFullscreen = () => {
    setIsContentFullscreen(!isContentFullscreen);
  };

  if (!isOpen) return null;

  // Render fullscreen content view
  if (isContentFullscreen) {
    return (
      <div className="fixed inset-0 bg-white z-50 flex flex-col">
        <div className="flex justify-between items-center p-4 bg-blue-50 border-b border-blue-200 text-gray-800">
          <h2 className="text-xl font-semibold">Editing Prompt Content: {name}</h2>
          <button
            onClick={toggleFullscreen}
            className="p-2 rounded-full hover:bg-blue-100 transition-colors"
            title="Exit Fullscreen"
          >
            <Minimize2 size={20} />
          </button>
        </div>
        <div className="flex-grow overflow-hidden p-4">
          <NunjucksSyntaxHighlighter
            code={content}
            onChange={setContent}
            className={`h-full ${templateError ? 'border-red-500' : 'border-gray-300'}`}
            rows={30}
            placeholder="Enter prompt content using Nunjucks syntax"
            disabled={isSubmitting}
          />
          {templateError && (
            <div className="absolute bottom-4 left-4 right-4 bg-red-50 text-red-600 p-2 rounded border border-red-200">
              {templateError}
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-5 rounded-lg shadow-lg w-11/12 max-w-3xl max-h-[90vh] overflow-y-auto">
        <div className="flex justify-between items-center mb-4 pb-2.5 border-b border-gray-200">
          <h2 className="text-xl font-semibold m-0">
            {prompt ? 'Edit Prompt' : 'Create New Prompt'}
          </h2>
          <button
            className="bg-transparent border-none text-2xl cursor-pointer text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            &times;
          </button>
        </div>

        <form onSubmit={handleSubmit} className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <label htmlFor="name" className="font-medium text-sm">
              Name*
            </label>
            <input
              id="name"
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Enter prompt name"
              required
              className="p-2.5 border border-gray-300 rounded text-sm"
            />
            <small className="text-gray-600 text-xs">
              A unique identifier for the prompt (e.g., "author-style-analysis")
            </small>
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="type" className="font-medium text-sm">
              Type*
            </label>
            <input
              id="type"
              type="text"
              value={type}
              onChange={e => setType(e.target.value)}
              placeholder="Enter prompt type"
              required
              className="p-2.5 border border-gray-300 rounded text-sm"
            />
            <small className="text-gray-600 text-xs">
              Category of prompt (e.g., "author-analysis", "book-recommendation")
            </small>
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="author" className="font-medium text-sm">
              Author
            </label>
            <select
              id="author"
              value={authorId}
              onChange={e => setAuthorId(e.target.value)}
              className="p-2.5 border border-gray-300 rounded text-sm"
              disabled={isLoadingAuthors}
            >
              <option value="">None (for all authors)</option>
              {authors.map(author => (
                <option key={author.id} value={author.id}>
                  {author.name}
                </option>
              ))}
            </select>
            <small className="text-gray-600 text-xs">
              Associate this prompt with a specific author (optional)
            </small>
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="description" className="font-medium text-sm">
              Description
            </label>
            <input
              id="description"
              type="text"
              value={description}
              onChange={e => setDescription(e.target.value)}
              placeholder="Enter a short description"
              className="p-2.5 border border-gray-300 rounded text-sm"
            />
          </div>

          <div className="flex flex-col gap-2">
            <div className="flex items-center justify-between">
              <label htmlFor="content" className="font-medium text-sm">
                Content*
              </label>
              <div className="flex items-center gap-2">
                <button
                  type="button"
                  onClick={() => setIsHelpModalOpen(true)}
                  className="text-blue-600 text-sm hover:underline"
                >
                  Nunjucks Syntax Help
                </button>
                <button
                  type="button"
                  onClick={toggleFullscreen}
                  className="flex items-center justify-center p-1 text-blue-600 hover:bg-blue-50 rounded"
                  title="Fullscreen Mode"
                >
                  <Maximize2 size={18} />
                </button>
              </div>
            </div>
            <NunjucksSyntaxHighlighter
              code={content}
              onChange={setContent}
              className={templateError ? 'border-red-500' : 'border-gray-300'}
              rows={10}
              placeholder="Enter prompt content using Nunjucks syntax"
              disabled={isSubmitting}
            />
            <small className="text-gray-600 text-xs">
              The prompt text with Nunjucks syntax for variables, loops, and conditionals (e.g.,{' '}
              {'{{ variable }}'}, {'{% if condition %}'}, {'{% for item in items %}'})
            </small>
            {templateError && <small className="text-red-600 text-xs">{templateError}</small>}
          </div>

          <div className="flex flex-col gap-2">
            <label className="font-medium text-sm">Variables</label>
            <div className="p-2.5 border border-gray-300 rounded text-sm bg-gray-50">
              {variables.length > 0 ? (
                <div className="flex flex-wrap gap-2">
                  {variables.map((variable, index) => (
                    <span
                      key={index}
                      className="px-2 py-1 bg-blue-100 text-blue-700 rounded-md text-sm"
                    >
                      {variable}
                    </span>
                  ))}
                </div>
              ) : (
                <span className="text-gray-500">No variables found in the prompt content</span>
              )}
            </div>
            <small className="text-gray-600 text-xs">
              Variables detected in the prompt content (automatically extracted from placeholders)
            </small>
          </div>

          <div className="flex flex-col gap-2">
            <label htmlFor="metadata" className="font-medium text-sm">
              Metadata (JSON)
            </label>
            <textarea
              id="metadata"
              value={metadata}
              onChange={e => setMetadata(e.target.value)}
              placeholder="Enter metadata as JSON"
              className="p-2.5 border border-gray-300 rounded text-sm font-mono resize-y"
              rows={5}
            />
            <small className="text-gray-600 text-xs">
              Additional information about the prompt in JSON format
            </small>
          </div>

          {error && (
            <div className="text-red-700 text-sm p-2 bg-red-50 rounded mt-2.5">{error}</div>
          )}

          <div className="flex justify-end gap-2.5 mt-4">
            <button
              type="button"
              onClick={onClose}
              className="py-2.5 px-4 border border-gray-300 rounded bg-white cursor-pointer"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="py-2.5 px-4 border-none rounded bg-blue-500 text-white cursor-pointer hover:bg-blue-600 disabled:bg-gray-400 disabled:cursor-not-allowed"
              disabled={isSubmitting || !!templateError}
            >
              {isSubmitting ? 'Saving...' : 'Save Prompt'}
            </button>
          </div>
        </form>
      </div>

      {/* Nunjucks help modal */}
      <NunjucksHelpModal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)} />
    </div>
  );
};

export default PromptModal;
