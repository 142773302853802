import { PromptState } from '../../../interfaces/interfaces';

// Shared state color mapping
export const getStateColor = (state: PromptState) => {
  switch (state) {
    case PromptState.DRAFT:
      return 'bg-orange-100 text-orange-800';
    case PromptState.RC:
      return 'bg-blue-100 text-blue-800';
    case PromptState.PUBLISHED:
      return 'bg-green-100 text-green-800';
    case PromptState.ARCHIVED:
      return 'bg-gray-100 text-gray-800';
    default:
      return '';
  }
};

// Shared button styles
export const buttonStyles = {
  primary: 'py-1 px-2 border-none rounded text-xs font-medium',
  view: 'bg-blue-500 text-white hover:bg-blue-600',
  restore: 'bg-green-500 text-white hover:bg-green-600 flex items-center gap-1',
  rc: 'bg-green-100 text-green-800',
  publish: 'bg-blue-100 text-blue-800',
};

// Shared confirmation messages
export const confirmationMessages = {
  restore: 'Are you sure you want to restore this prompt? It will be restored as a DRAFT.',
  archive:
    'Are you sure you want to archive this prompt? Archived prompts cannot be used in the system.',
};

// Shared error messages
export const errorMessages = {
  restore: 'Failed to restore prompt.',
  archive: 'Failed to archive prompt.',
  updateState: 'Failed to update prompt state.',
};
