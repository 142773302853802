import React from 'react';
import { Link, redirect, useNavigate } from 'react-router-dom';
import styles from './PublicClosed.module.css';
import { useAuth } from '../../context/AuthContext';
import { logComponentLoad } from '../../utils/debugLogger';

const PublicClosed: React.FC = () => {
  logComponentLoad('Stealth');
  const navigate = useNavigate();
  const { user, setUser } = useAuth();

  const handleLoginClick = () => {
    localStorage.removeItem('token');
    setUser(null);
    navigate('/login');
  };

  const handleContactUsClick = () => {
    window.location.href = 'https://authorsecho.com';
  };

  return (
    <div className={styles.publicClosed}>
      <img
        src={require('../../assets/icons/wisdom-logo.svg').default}
        alt="Wisdom Logo"
        className={styles.logo}
      />
      <h1 className={styles.title}>Incredible to see your interest in Wisdom!</h1>
      <p className={styles.message}>
        Currently, the platform is invite-only. Invites are granted by registered authors. Let us
        know which author you’re interested in by emailing us at{' '}
        <a href="mailto:support@echoyourwisdom.com">support@echoyourwisdom.com</a>, and we’ll check
        if they’re already registered—or visit their website to see how to get an invite.
        <br />
        <br />
        Either way, we’ll keep you informed!
      </p>
      <div className={styles.divider} />
      <p className={styles.footer}>Are you an author?</p>
      <div className={styles.buttonsContainer}>
        <button className={styles.loginButton} onClick={handleLoginClick}>
          Login
        </button>
        <button className={styles.loginButton} onClick={handleContactUsClick}>
          Ask to Join
        </button>
      </div>
    </div>
  );
};

export default PublicClosed;
