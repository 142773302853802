import { SidebarTrigger, useSidebar } from 'src/components/ui/sidebar';
import { Menu, PanelRightClose, SquarePen } from 'lucide-react';
import AuthorProfileDrawer from 'src/components/authorChat/AuthorProfileDrawer';
import AuthorProfileDialog from 'src/components/authorChat/AuthorProfileDialog';
import { FC, useState } from 'react';
import { useIsMobile } from 'src/hooks/use-mobile';
import ChatDropdownMenu from 'src/components/authorChat/ChatDropdownMenu';
import { Button } from 'src/components/ui/button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Badge } from 'src/components/ui/badge';
import wisdomLogo from 'src/assets/icons/wisdom-logo.svg';

interface Props {
  authorId: string;
}

const Header: FC<Props> = ({ authorId }) => {
  const navigate = useNavigate();

  const { open } = useSidebar();
  const isMobile = useIsMobile();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { conversationId } = useParams();

  return (
    <header className="flex items-center justify-between gap-4 pt-5 px-5 md:pt-6 md:px-6">
      <div className="flex justify-between items-center w-full gap-4 md:gap-3">
        {!open && (
          <div className="flex gap-3">
            <SidebarTrigger variant="secondary" size="icon" className="hidden md:flex size-10">
              <PanelRightClose className="size-6 stroke-[1.5]" />
            </SidebarTrigger>
            <Button
              onClick={() => navigate(`/author/${authorId}/chat/v2`)}
              variant="secondary"
              className="hidden md:flex size-10"
              size="icon"
            >
              <SquarePen className="size-6 stroke-[1.5]" />
            </Button>
          </div>
        )}
        <SidebarTrigger className="md:hidden size-10" variant="secondary" size="icon">
          <Menu className="size-6" />
        </SidebarTrigger>
        {conversationId ? (
          <>
            <ChatDropdownMenu
              authorId={authorId}
              conversationId={conversationId}
              onAuthorDetails={() => setIsDrawerOpen(true)}
            />
            <Button className="md:hidden size-10" variant="secondary" size="icon">
              <Link to={`/author/${authorId}/chat/v2`}>
                <SquarePen className="size-6 stroke-[1.5]" />
              </Link>
            </Button>
          </>
        ) : (
          isMobile && (
            <div className="w-full flex flex-row gap-3">
              <Link to="/">
                <img src={wisdomLogo} alt="Wisdom Logo" className="h-6" />
              </Link>
              <Badge className="p-1 h-5 bg-orange-accent-50 text-orange-accent-500 font-semibold rounded-md">
                Pre-Launch
              </Badge>
            </div>
          )
        )}
      </div>
      {isMobile ? (
        <AuthorProfileDrawer
          authorId={authorId}
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
        />
      ) : (
        <AuthorProfileDialog authorId={authorId} />
      )}
    </header>
  );
};

export default Header;
