import * as React from 'react';

import { cn } from 'src/lib/utils';
import { Label } from './label';
import { Typography } from './typography';

interface TextareaProps extends React.ComponentProps<'textarea'> {
  label?: string;
  description?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ label, description, className, ...props }, ref) => {
    return (
      <div className="flex flex-col gap-1.5 w-full">
        {label && <Label>{label}</Label>}
        <textarea
          ref={ref}
          data-slot="textarea"
          className={cn(
            'border-border-light placeholder:text-muted-foreground-light focus-visible:ring-ring-light aria-invalid:ring-destructive/20 dark:aria-invalid:ring-destructive/40 aria-invalid:border-destructive dark:bg-input/30 flex field-sizing-content w-full rounded-md border bg-background-light px-3 py-2 text-sm transition-[color,box-shadow] outline-none focus-visible:ring-[2px] disabled:cursor-not-allowed disabled:opacity-50',
            className
          )}
          {...props}
        />
        {description && (
          <Typography variant="muted" size="sm">
            {description}
          </Typography>
        )}
      </div>
    );
  }
);

Textarea.displayName = 'Textarea';

export { Textarea };
