import { DialogDescription } from 'src/components/ui/dialog';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import { ReactComponent as TwitterIcon } from '../../assets/icons/twitter.svg';
import { ExternalLink, MapPin, Calendar, Edit } from 'lucide-react';
import { format } from 'date-fns';
import { Typography } from 'src/components/ui/typography';
import { useGetAuthorProfile } from 'src/services/author/useGetAuthorProfile';
import { FC } from 'react';
import { getInitials } from 'src/utils/initials';
import { Link } from 'react-router-dom';
import { useAuth } from 'src/context/AuthContext';

interface Props {
  authorId: string;
}

const AuthorProfileContent: FC<Props> = ({ authorId }) => {
  const { user } = useAuth();
  const { data } = useGetAuthorProfile(authorId);

  const { photo, name, biography, location, socialLinks, offerings, events, id } = data ?? {};

  return (
    <div>
      <div className="flex items-center justify-center flex-col">
        <Avatar className="size-16 w-[104px] h-[104px] box-content">
          <AvatarImage src={photo} />
          <AvatarFallback>{getInitials(name ?? '')}</AvatarFallback>
        </Avatar>
        <div className="flex mt-3 items-center justify-center text-accent-foreground">
          <Typography size="xl" className="font-crimson font-semibold tracking-[-0.4px]">
            {name}
          </Typography>
        </div>
      </div>
      <DialogDescription>
        <div className="mt-6 space-y-6">
          <div>
            <Typography size="xs" variant="lead" className="tracking-[1.44px]">
              DESCRIPTION
            </Typography>
            <Typography size="sm" className="mt-4 leading-normal text-foreground line-clamp-6">
              {biography}
            </Typography>
          </div>
          <div>
            {user?.userType === 'admin' ? (
              <Link to={`/author/${id}/settings`} className="gap-3 flex items-center min-h-[44px]">
                <Edit width={20} height={20} className="text-foreground shrink-0 stroke-[1.5]" />
                <Typography size="sm" className="text-popover-foreground">
                  Edit Author
                </Typography>
              </Link>
            ) : null}
            {location ? (
              <div className="min-h-[44px] gap-3 flex items-center">
                <MapPin width={20} height={20} className="text-foreground shrink-0 stroke-[1.5]" />
                <Typography size="sm" className="text-popover-foreground">
                  {location}
                </Typography>
              </div>
            ) : null}
            {socialLinks?.blog ? (
              <div className="min-h-[44px] gap-3 flex items-center">
                <ExternalLink
                  width={20}
                  height={20}
                  className="text-foreground shrink-0 stroke-[1.5]"
                />
                <a
                  target="_blank"
                  className="text-popover-foreground"
                  href={socialLinks?.blog}
                  rel="noreferrer"
                >
                  {socialLinks?.blog}
                </a>
              </div>
            ) : null}
            {socialLinks?.twitter ? (
              <div className="min-h-[44px] gap-3 flex items-center">
                <TwitterIcon width={20} height={20} className="text-foreground shrink-0" />
                <a
                  target="_blank"
                  className="text-popover-foreground"
                  href={socialLinks?.twitter}
                  rel="noreferrer"
                >
                  {socialLinks?.twitter}
                </a>
              </div>
            ) : null}
          </div>
          <div>
            <Typography variant="lead" size="xs" className="tracking-[1.28px]">
              BOOK LECTURES
            </Typography>
            {offerings?.length ? (
              <div className="mt-6 gap-4 flex flex-col">
                {offerings.map(item => {
                  return (
                    <div key={item.id} className="flex items-center gap-3">
                      <div className="h-[51px] w-[51px] rounded-lg bg-muted-light flex items-center justify-center">
                        <Calendar width={20} height={20} className="text-foreground stroke-[1.5]" />
                      </div>
                      <div className="flex flex-col gap-[2px]">
                        <Typography
                          size="sm"
                          className="leading-normal text-popover-foreground font-medium"
                        >
                          {item?.name}
                        </Typography>
                        <Typography
                          size="sm"
                          variant="lead"
                          className="leading-[137.5%] font-normal"
                        >
                          {item?.action}
                        </Typography>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="mt-2">
                <Typography>Details coming soon...</Typography>
              </div>
            )}
          </div>
          <div>
            <Typography variant="lead" size="xs" className="tracking-[1.28px]">
              ON TOUR
            </Typography>
            {events?.length ? (
              <div className="mt-6 gap-4 flex flex-col">
                {events.map(item => (
                  <div key={item.id} className="flex items-center gap-3">
                    <div className="min-h-[51px] h-[51px] min-w-[51px] w-[51px] rounded-lg bg-muted-light flex items-center justify-center">
                      <div className="flex flex-col items-center justify-center">
                        <Typography
                          size="sm"
                          className="tracking-[-0.32px] text-accent-foreground font-crimson font-normal"
                        >
                          {format(new Date(item.eventDate), 'MMM')}
                        </Typography>
                        <Typography
                          size="xl"
                          className="leading-[20px] text-accent-foreground font-crimson font-bold tracking-[-0.32px]"
                        >
                          {format(new Date(item.eventDate), 'd')}
                        </Typography>
                      </div>
                    </div>
                    <div className="flex flex-col gap-[4px]">
                      <Typography size="sm" className="text-popover-foreground font-medium">
                        {item?.eventName}
                      </Typography>
                      <Typography variant="lead" size="sm" className="font-normal leading-[137.5%]">
                        Books Avenue
                      </Typography>
                      <Typography size="sm" variant="lead" className="font-normal leading-[137.5%]">
                        {format(new Date(item.eventDate), 'EEE HH:mm')} • {item?.place}
                      </Typography>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="mt-2">
                <Typography>Details coming soon...</Typography>
              </div>
            )}
          </div>
        </div>
      </DialogDescription>
    </div>
  );
};

export default AuthorProfileContent;
