import { FC } from 'react';
import { Avatar, AvatarFallback, AvatarImage } from 'src/components/ui/avatar';
import SoundWave from '../common/icons/SoundWave';
import { Pause } from 'lucide-react';
import { H1, Typography } from '../ui/typography';
import { toPossessive } from 'src/utils/toPossessive';
import { getInitials } from 'src/utils/initials';
import { Button } from '../ui/button';
import { useVoiceMessage } from 'src/hooks/useVoiceMessage';

const VOICE_IDX = -1;

interface WelcomeContentProps {
  authorId: string;
  authorName: string;
  authorEchoPhoto: string;
  authorWelcomeMessage: string | null;
  userName?: string;
}

const WelcomeContent: FC<WelcomeContentProps> = ({
  authorId,
  authorName,
  authorEchoPhoto,
  authorWelcomeMessage,
  userName,
}) => {
  const welcomeMessage = authorWelcomeMessage
    ? `${userName ? `Hello, ${userName}! ` : ''}${authorWelcomeMessage}`
    : `${userName ? `Hello, ${userName}! ` : ''}You're about to have your first chat with my Echo. Please keep in mind this is an alpha experience, but it's designed to help you explore my thoughts, ideas, and more.`;

  const { handleVoiceClick, loadingAudio, playingAudio } = useVoiceMessage(authorId);

  return (
    <section className="flex flex-col gap-5 md:gap-10">
      <H1 className="text-center md:text-[52px] font-light leading-[44px] md:leading-[54px] tracking-[-1.56px]">
        Your Journey with <br /> <span className="italic">{toPossessive(authorName)} echo</span>{' '}
        Starts Here
      </H1>

      <div className="relative mt-10">
        <article className="bg-card-light w-full rounded-2xl pt-10 pb-8 px-6 md:pb-10">
          <Typography className="text-center text-base md:text-lg font-crimson font-normal leading-normal tracking-[-0.32px] md:tracking-normal">
            {welcomeMessage}
          </Typography>
        </article>

        <figure className="absolute left-1/2 -top-9 md:-top-10 transform -translate-x-1/2">
          <Avatar className="size-[60px] md:size-16 border-4 border-background-light box-content">
            <AvatarImage src={authorEchoPhoto} className="object-cover" />
            <AvatarFallback>{getInitials(authorName)}</AvatarFallback>
          </Avatar>
        </figure>

        <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-4">
          <Button
            variant="secondary"
            size="icon"
            className="w-[58px] border-4 border-background-light disabled:opacity-100"
            loading={loadingAudio === VOICE_IDX}
            onClick={() => welcomeMessage && handleVoiceClick(welcomeMessage, VOICE_IDX)}
            disabled={loadingAudio === VOICE_IDX}
          >
            {loadingAudio === VOICE_IDX ? null : playingAudio === VOICE_IDX ? (
              <Pause />
            ) : (
              <SoundWave className="size-6" />
            )}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default WelcomeContent;
